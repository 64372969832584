<div id="page_support">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="row mx-0">
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 px-0">
                <div class="page_title">
                    <div class="row align-items-center mx-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                            <div class="d-flex">
                                <h1>{{'change_language' | translate}}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="banner col-10 col-md-10 col-lg-10 col-xl-7 mx-auto animate__animated animate__zoomIn wow" data-wow-duration=".5s">
                    <img src="assets/images/img_language.png" class="img-fluid">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-7 px-0">
                <div class="card p-4 px-3 border-0 rounded-0">
                    <div class="col-12 col-sm-12 col-md-11 mx-auto px-0">
                        <h2 class="animate__animated animate__fadeInUp wow">{{'choose_preferred_language' | translate}}</h2>
                        <p class="animate__animated animate__fadeInUp wow">
                            {{'you_can_change_it_anytime_you_want' | translate}}
                        </p>


                        <form class="py-5">
                            <div *ngFor="let language of languages" (click)="onLanguageClick(language)" class="form-group  animate__animated animate__fadeInUp wow">
                                <div class="custom-control custom-radio">
                                    <input type="radio" [id]="language.code" [value]="language.code" [(ngModel)]="defaultLanguageCode" name="ChooseLanguage" class="custom-control-input">
                                    <label class="custom-control-label" [for]="language.code"><span class="ml-2">{{language.name}}</span></label>
                                </div>
                            </div>
                        </form>
                        <button type="submit" class="btn rounded-pill py-0 col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4 d-block animate__animated animate__fadeInUp" data-wow-duration="1s" (click)="languageConfirm()">{{'save' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div id="not-found">
    <div id="title">Simple Pure CSS3 &bull; 404 Error Page</div>
    <div class="circles">
        <p>404<br>
            <small>PAGE NOT FOUND</small>
        </p>
        <!-- <button class="btn green-btn-now" (click)="backToHome()">Go To Home</button> -->
        <span class="circle big"></span>
        <span class="circle med"></span>
        <span class="circle small"></span>
    </div>
</div>
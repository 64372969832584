<div id="page_sign_in">
    <div class="container-fluid px-0 px-md-3 px-lg-4">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                <div class="logo_box mx-auto text-center">
                    <img src="assets/images/logo.svg" class="img-fluid">
                </div>
                <div class="banner_img">
                    <img src="assets/images/img_signin.png" class="img-fluid">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                <!-- <form class="col-12 col-lg-10 mx-auto" action="dashboard.html">
                    <div class="form-inner w-100">
                        <div class="col-12 col-md-12 col-lg-9 col-xl-9  m-auto px-4">
                            <h2>{{'login_to_your_account' | translate}}</h2>
                            <div class="form-group">
                                <label for="exampleInputPassword1">{{'select_country' | translate}}</label>
                                <select class="form-control" name="countryCode" [(ngModel)]="countryCode"
                                    (change)="changeHint()">
                                    <option value="" disabled="" selected>{{'select_country' | translate}}</option>
                                    <option *ngFor="let country of countries" [value]="country.callingCodes[0]">
                                        {{country.name}}</option>
                                </select>


                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">{{phoneNumberHint}}</label>
                                <input type="text" class="form-control" name="phoneNumber" [(ngModel)]="phoneNumber"
                                    placeholder="{{'enter_phone_number' | translate}}" required>
                            </div>
                            <div class="form-group">
                                <label for="">Enter the Restuarant ID</label>
                                <input type="text" class="form-control" name="vendor_id" [(ngModel)]="vendorID"
                                    placeholder="{{'restautant_id' | translate}}" required>
                            </div>
                            <button type="submit" class="btn rounded-pill" (click)="alertPhone()">{{'login' |
                                translate}}</button>

                        </div>
                    </div>
                </form> -->
                <form class="col-12 col-lg-10 mx-auto" action="dashboard.html">
                    <div class="form-inner w-100">
                        <div class="col-12 col-md-12 col-lg-9 col-xl-9  m-auto px-4">
                            <h2>{{'login_to_your_account' | translate}}</h2>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Enter Email</label>
                                <input type="text" class="form-control" name="email" [(ngModel)]="email"
                                    placeholder="Enter your email" required>


                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Enter Password</label>
                                <input type="text" class="form-control" name="password" [(ngModel)]="password"
                                    placeholder="Enter your password" required>
                            </div>
                            <div class="form-group">
                                <label for="">Enter the Restuarant ID</label>
                                <input type="text" class="form-control" name="vendor_id" [(ngModel)]="vendorID"
                                    placeholder="{{'restautant_id' | translate}}" required>
                            </div>
                            <button type="submit" class="btn rounded-pill" (click)="alertEmailPassword()">{{'login' |
                                translate}}</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

export class OrderTypeInsight {
    summary: Array<{ total: number; order_type: string; percent: string; }>;
    constructor() {
        this.summary = [{ total: 0, order_type: "NORMAL", percent: "0" }, { total: 0, order_type: "TAKEAWAY", percent: "0" }, { total: 0, order_type: "DINEIN", percent: "0" }];
    }

    static fillRest(summaryIn: Array<{ total: number; order_type: string; percent: string; }>): Array<{ total: number; order_type: string; percent: string; }> {
        let toReturn = summaryIn;
        let defaultSummary = new OrderTypeInsight().summary;
        if (!toReturn) {
            toReturn = defaultSummary;
        } else if (toReturn.length != 3) {
            for (let summaryItemDefault of defaultSummary) {
                let exists = false;
                for (let summaryItem of summaryIn) {
                    if (summaryItem.order_type == summaryItemDefault.order_type) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) toReturn.push(summaryItemDefault);
            }
        }
        return toReturn;
    }
}
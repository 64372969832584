<div id="page_sign_in">
    <div class="container-fluid px-0 px-md-3 px-lg-4">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                <div class="logo_box mx-auto text-center">
                    <img src="assets/images/logo.svg" class="img-fluid">
                </div>
                <div class="banner_img">
                    <img src="assets/images/img_signin.png" class="img-fluid">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-7 col-lg-7">
                <div id="recaptcha-container" style="display: none;"></div>
                <form class="col-12 col-lg-10 mx-auto">
                    <div class="form-inner w-100">
                        <div class="col-12 col-md-12 col-lg-9 col-xl-9  m-auto px-4">
                            <h2>{{'login_to_your_account' | translate}}</h2>
                            <!-- <h2>{{'verification_text' | translate}} {{phoneNumberFull}}</h2> -->
                            <!-- <div class="form-group">
                                <label for="exampleInputEmail1">{{'phone_number' | translate}}</label>
                                <input type="text" class="form-control" placeholder="{{'enter_phone_number' | translate}}" required>
                            </div> -->
                            <div class="form-group">
                                <label for="exampleInputPassword1">{{'verification_text' | translate}} {{phoneNumberFull}}</label>
                                <input type="text" name="otp" [(ngModel)]="otp" class="form-control" placeholder="{{'enter_verification_code' | translate}}" required>
                            </div>

                            <button type="submit" class="btn rounded-pill" (click)="verify()">{{'verify' | translate}}</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

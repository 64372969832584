import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiElementsService } from '../services/common/ui-elements.service';
import { MyEventsService } from '../services/events/my-events.service';
import { ApiService } from '../services/network/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/models/constants.models';
import * as firebase from 'firebase/app';
import { AppConfig, APP_CONFIG } from '../app.config';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  otp: string = "";
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  private captchanotvarified: boolean = true;
  private result: any;
  private buttonDisabled: any = true;
  private component: any;
  private captchaVerified: boolean = false;
  private verfificationId: any;
  private timer: any;
  private minutes: number = 0;
  private seconds: number = 0;
  private totalSeconds: number = 0;
  private intervalCalled: boolean = false;
  private dialCode: string;
  private resendCode: boolean = false;
  private otpNotSent: boolean = true;
  private credential: any;

  phoneNumberFull: string;
  isDemoNumber: string;

  constructor(@Inject(APP_CONFIG) public config: AppConfig, private route: ActivatedRoute, private uiElementService: UiElementsService, private myEvent: MyEventsService,
    private router: Router, private apiService: ApiService, private translate: TranslateService) {
    // this.route.queryParams.subscribe(params => this.phoneNumberFull = params["phoneNumberFull"]);
    this.route.queryParams.subscribe(params => {
      this.phoneNumberFull = params["phoneNumberFull"];
      this.isDemoNumber = params["isDemoNumber"];
    });
  }

  ngOnInit() {
    this.makeCaptcha();
    this.sendOTP();
  }

  loginUser() {
    this.translate.get('just_moment').subscribe(value => {
      this.uiElementService.presentLoading(value);

      this.apiService.autheticationDelegations({ identifier: this.phoneNumberFull, type: Constants.ROLE_DESK }).subscribe(res => {
        this.uiElementService.dismissLoading();
        // this.router.navigate(['login']);
        this.myEvent.setLoginData(res);
      }, err => {
        console.log(err);
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr((err && err.error && err.error.message && String(err.error.message).toLowerCase().includes("role")) ? "User exists with different role" : "Something went wrong");
      });
    });
  }

  // getUserToken(user) {
  //   user.getIdToken(false).then(res => {
  //     console.log('user_token_success', res);
  //     this.loginUser(res);
  //   }).catch(err => {
  //     console.log('user_token_failure', err);
  //   });
  // }

  sendOTP() {
    this.resendCode = false;
    this.otpNotSent = true;
    this.sendOtpBrowser(this.phoneNumberFull);
    // }
    if (this.intervalCalled) {
      clearInterval(this.timer);
    }
  }

  createTimer() {
    this.intervalCalled = true;
    this.totalSeconds--;
    if (this.totalSeconds == 0) {
      this.otpNotSent = true;
      this.resendCode = true;
      clearInterval(this.timer);
    } else {
      this.seconds = (this.totalSeconds % 60);
      if (this.totalSeconds >= this.seconds) {
        this.minutes = (this.totalSeconds - this.seconds) / 60
      } else {
        this.minutes = 0;
      }
    }
  }

  createInterval() {
    this.totalSeconds = 120;
    this.createTimer();
    this.timer = setInterval(() => {
      this.createTimer();
    }, 1000);
  }

  sendOtpBrowser(phone) {
    this.translate.get('sending_otp').subscribe(value => {
      const component = this;
      this.uiElementService.presentLoading(value);
      firebase.auth().signInWithPhoneNumber(phone, this.recaptchaVerifier).then((confirmationResult) => {
        component.otpNotSent = false;
        component.result = confirmationResult;
        component.uiElementService.dismissLoading();
        component.translate.get("sending_otp_success").subscribe(value => component.uiElementService.showSuccessToastr(value));
        if (component.isDemoNumber == "true") { component.otp = component.config.demoLoginCredentials.otp; component.verify(); };
        if (component.intervalCalled) {
          clearInterval(component.timer);
        }
        component.createInterval();
      }).catch(function (error) {
        console.log("otp_send_fail", error);
        component.resendCode = true;
        component.uiElementService.dismissLoading();
        if (error.message) {
          component.uiElementService.showErrorToastr(error.message);
        } else {
          component.translate.get('sending_otp_fail').subscribe(text => component.uiElementService.showErrorToastr(text));
        }
      });
    })
  }

  verify() {
    if (this.otp && this.otp.length) {
      this.otpNotSent = true;
      this.verifyOtpBrowser();
    } else {
      this.translate.get('enter_verification_code').subscribe(value => this.uiElementService.showErrorToastr(value));
    }
  }

  retryOld(credential) {
    //const credential = firebase.auth.PhoneAuthProvider.credential(this.verfificationId, this.otp);
    this.translate.get('verifying_otp').subscribe(value => {
      this.uiElementService.presentLoading(value);

      firebase.auth().signInAndRetrieveDataWithCredential(credential).then((info) => {
        this.uiElementService.dismissLoading();
        this.translate.get('verifying_otp_success').subscribe(value => this.uiElementService.showSuccessToastr(value));
        // this.getUserToken(info.user);
        this.loginUser()
      }, (error) => {
        console.log('otp_verify_fail', error);
        this.translate.get('verifying_otp_fail').subscribe(value => this.uiElementService.showErrorToastr(value));
        this.uiElementService.dismissLoading();
      })
    });
  }

  verifyOtpBrowser() {
    const component = this;
    this.uiElementService.presentLoading(this.translate.instant('verifying_otp'));
    this.result.confirm(String(this.otp)).then(function (response) {
      component.uiElementService.dismissLoading();
      component.translate.get('otp_verified').subscribe(text => component.uiElementService.showSuccessToastr(text));
      // component.getUserToken(response.user);
      component.loginUser()
    }).catch(function (error) {
      console.log('otp_verify_fail', error);
      if (error.message) {
        component.uiElementService.showErrorToastr(error.message);
      } else {
        component.translate.get('verify_otp_err').subscribe(text => component.uiElementService.showErrorToastr(text));
      }
      component.uiElementService.dismissLoading();
    });
  }

  makeCaptcha() {
    const component = this;
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      // 'size': 'normal',
      'size': 'invisible',
      'callback': function (response) {
        component.captchanotvarified = true;
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    });
    this.recaptchaVerifier.render();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseListResponse } from 'src/models/base-list.models';
import { Customer, CustomerRequest } from 'src/models/customer.models';
import { Helper } from 'src/models/helper.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { ApiService } from '../services/network/api.service';

declare var $: any;
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  // private nextUrl: string;
  isLoading = true;
  customers = new Array<any>();
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;
  readAllText: boolean = false;

  // add customer
  customerRequest = new CustomerRequest();
  countries: any;
  phoneNumberHint: string;
  customerId: number;

  constructor(private router: Router, private translate: TranslateService, private uiElementService: UiElementsService, private apiService: ApiService,
    private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadCustomers();
      // this.subscriptions.push(this.apiService.getRatingSummaryProduct(this.vendor.id).subscribe(res => this.rating = res, err => console.log("getRatingSummaryProduct", err)));
    });
  }

  loadCustomers() {
    this.subscriptions.push(this.apiService.gteUsers(Helper.getProfile().id).subscribe(res => this.customersRes(res), err => this.customersErr(err)));
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
    this.dismissModel();
  }

  onChangePage(event) {
    this.pageNo = event;
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadCustomers();
    });
  }

  search() {
    this.subscriptions.push(this.apiService.getCustomers(Helper.getProfile().id, this.pageNo, this.searchText).subscribe(res => this.customersRes(res), err => this.customersErr(err)));
  }

  read() {
    this.readAllText = !this.readAllText;
  }

  customersRes(res: any) {
    this.customers = res.users;
    this.isLoading = false;
    // this.pageNo = res.meta.current_page;
    // this.pageSize = res.meta.per_page;
    this.pageTotal = res.users.length;
    this.uiElementService.dismissLoading();
  }

  customersErr(err) {
    console.log("productsErr", err);
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }

  addNewCustomer() {
    $('#add_people').modal({ backdrop: 'static', keyboard: false })
    this.customerRequest = new CustomerRequest();
    this.customerRequest.meta.countryCode = "";
    this.customerId = null;
    if (!this.countries) this.apiService.getCountries().subscribe(res => this.countries = res);
    this.changeHint(false);
  }

  dismissModel() {
    $('#add_people').modal('hide');
  }

  editCustomer(customer: Customer) {
    if (customer) {
      $('#add_people').modal({ backdrop: 'static', keyboard: false })
      if (!this.countries) this.apiService.getCountries().subscribe(res => this.countries = res);
      if (customer.id) this.customerId = customer.id;
      if (customer.customer_name) this.customerRequest.customer_name = customer.customer_name;
      if (customer.customer_mobile) this.customerRequest.customer_mobile = customer.customer_mobile;
      if (customer.customer_email) this.customerRequest.customer_email = customer.customer_email;
      if (customer.vendor_id) this.customerRequest.vendor_id = customer.vendor_id;
      if (customer.meta && customer.meta.countryCode) this.customerRequest.meta.countryCode = customer.meta.countryCode;
      if (customer.meta && customer.meta.phoneNumber) this.customerRequest.meta.phoneNumber = customer.meta.phoneNumber;
      // this.apiService.getCountries().subscribe(res => { this.countries = res; this.customerRequest.meta.countryCode = (customer.meta && customer.meta.countryCode) ? customer.meta.countryCode : ""; this.changeHint(true); });
      this.changeHint(true);
    }
  }

  changeHint(clearPhoneSkip?: boolean) {
    this.customerRequest.meta.phoneNumber = clearPhoneSkip ? this.customerRequest.meta.phoneNumber : null;
    if (this.customerRequest.meta.countryCode && this.customerRequest.meta.countryCode.length) {
      this.translate.get('enter_phone_number_exluding').subscribe(value => this.phoneNumberHint = (value + " (+" + this.customerRequest.meta.countryCode + ")"));
    } else {
      this.translate.get('enter_phone_number').subscribe(value => this.phoneNumberHint = value);
    }
  }

  onAddCustomerRequest() {
    if (!this.customerRequest.customer_name || !this.customerRequest.customer_name.length) {
      this.uiElementService.showErrorToastr(this.translate.instant('full_name'))
    } else if (!this.customerRequest.meta.phoneNumber == null) {
      this.uiElementService.showErrorToastr(this.phoneNumberHint)
    } else if (!this.customerRequest.customer_email || !this.customerRequest.customer_email.length) {
      this.uiElementService.showErrorToastr(this.translate.instant('email_address'))
    } else {
      // this.customerRequest.customer_mobile = "+" + this.customerRequest.meta.countryCode + Helper.formatPhone(this.customerRequest.meta.phoneNumber.toString());
      // this.customerRequest.vendor_id = Helper.getProfile().id;
      this.customerRequest.meta.phoneNumber = Helper.formatPhone(this.customerRequest.meta.phoneNumber.toString());
      let addCustomerReq = {
        customer_mobile: "+" + this.customerRequest.meta.countryCode + Helper.formatPhone(this.customerRequest.meta.phoneNumber.toString()),
        customer_name: this.customerRequest.customer_name,
        customer_email: this.customerRequest.customer_email,
        meta: JSON.stringify(this.customerRequest.meta),
        vendor_id: Helper.getProfile().id
      }
      this.translate.get("create_customer").subscribe(value => {
        this.uiElementService.presentLoading(value);
        this.customerId ? this.subscriptions.push(this.apiService.updateCustomers(this.customerId, addCustomerReq).subscribe(res => this.updateCustomerRes(), err => this.createCustomerErr(err))) : this.subscriptions.push(this.apiService.addCustomers(addCustomerReq).subscribe(res => this.createCustomerRes(), err => this.createCustomerErr(err)))
      });
    }
  }

  createCustomerRes() {
    this.uiElementService.showSuccessToastr(this.translate.instant("add_customer"));
    this.dismissModel();
    this.pageNo = 1;
    this.loadCustomers();
  }

  updateCustomerRes() {
    this.uiElementService.showSuccessToastr(this.translate.instant("updated"));
    this.dismissModel();
    this.pageNo = 1;
    this.loadCustomers();
  }

  createCustomerErr(err) {
    console.log('err', err);
    this.uiElementService.dismissLoading();
    this.uiElementService.showErrorToastr(this.translate.instant("something_wrong"));
  }

  alertDeleteCustomer(customer: Customer) {
    this.translate.get(['delete_title', 'delete_message_customer', 'no', 'yes']).subscribe(text => {
      this.confirmationDialogService.confirm(text['delete_title'], text['delete_message_customer'] + "'" + customer.customer_name + "'?", text['yes'], text['no']).then((confirmed) => { if (confirmed) this.deleteCustomer(customer) }).catch(() => { console.log('err') });
    });
  }

  deleteCustomer(customer) {
    this.translate.get("deleting").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.deleteProduct(customer.id).subscribe(res => {
        this.uiElementService.showSuccessToastr(this.translate.instant('delete_title'));
        this.loadCustomers();
      }, err => {
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr(this.translate.instant('something_wrong'));
      }));
    });
  }

}

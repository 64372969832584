import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { ApiService } from '../network/api.service';
import * as jsrsasign from 'jsrsasign';
import * as qz from 'qz-tray';

@Injectable({
  providedIn: 'root'
})
export class QzPrinterService {
  private connected = false;

  constructor(private apiService: ApiService) {
    this.setupQzCertificateAndSignature();
  }

  // Print data to chosen printer
  printData(printer: { name: string; host: string; port: string; }, data: Array<string>): Observable<any> {

    return from(new Promise((resolve, reject) => {
      if (this.connected) {
        this.doPrint(printer, data).then(res => resolve(res), err => reject(err));
      } else {
        qz.websocket.connect().then(resSocket => {
          this.connected = true;
          this.doPrint(printer, data).then(res => resolve(res), err => reject(err));
        }, err => {
          console.log("websocket: ", err);
          this.doPrint(printer, data).then(res => resolve(res), err => reject(err));
        });
      }
    })).pipe(map((anything: any) => anything));
  }

  // Get list of printers connected
  getPrinters(): Observable<string[]> {
    return from(new Promise((resolve, reject) => {
      if (this.connected) {
        qz.printers.find().then(res => resolve(res), err => reject(err));
      } else {
        qz.websocket.connect().then(resSocket => {
          this.connected = true;
          qz.printers.find().then(res => resolve(res), err => reject(err));
        }, err => {
          console.log("websocket: ", err);
          qz.printers.find().then(res => resolve(res), err => reject(err));
        });
      }
    })).pipe(map((printers: string[]) => printers));
  }

  private doPrint(printer: { name: string; host: string; port: string; }, data: Array<string>) {
    let qzConfig = qz.configs.create(printer);
    if (printer.name && printer.name.length || (printer.host && printer.host.length && printer.port && printer.port.length)) {
      qzConfig.setPrinter(printer.name && printer.name.length ? printer.name : { host: printer.host, port: printer.host });
      console.log("qzConfig.setPrinter: ", printer.name && printer.name.length ? printer.name : { host: printer.host, port: printer.host });
    }
    return qz.print(qzConfig, data);
  }

  private setupQzCertificateAndSignature() {
    const component = this;
    qz.security.setCertificatePromise(function (resolve, reject) {
      component.apiService.getSigningCertificate().subscribe(res => resolve(res), err => reject(err));
    });
    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        component.apiService.getSigningSignature().subscribe(res => {
          try {
            var pk = jsrsasign.KEYUTIL.getKey(res);
            var sig = new jsrsasign.KJUR.crypto.Signature({ "alg": "SHA512withRSA" });  // Use "SHA1withRSA" for QZ Tray 2.0 and older
            sig.init(pk);
            sig.updateString(toSign);
            var hex = sig.sign();
            resolve(jsrsasign.stob64(jsrsasign.hextorstr(hex)));
          } catch (err) {
            console.error(err);
            reject(err);
          }
        }, err => {
          reject(err);
        });
      };
    });
  }

  // // Get the SPECIFIC connected printer
  // getPrinterByName(printerName: string): Observable<string> {
  //   return from(qz.websocket.connect().then(() => qz.printers.find(printerName)))
  //     .pipe(map((printer: string) => printer));
  // }

  // // Disconnect QZ Tray from the browser
  // removePrinter(): void {
  //   qz.websocket.disconnect();
  // }

}

export class OrderRequest {
    address_id: number;
    payment_method_id: number;
    payment_method_slug: string;
    coupon_code: string;
    products: Array<{ id: number; quantity: number; addons: Array<{ choice_id: number }>; }>;
    meta: string;
    notes: string;
    customer_name: string;
    customer_mobile: string;
    is_guest: boolean;
    order_type: string;

    constructor() {
        this.products = new Array<{ id: number; quantity: number; addons: Array<{ choice_id: number }>; }>();
    }
}
<div id="page_booking">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="page_title">
            <div class="row align-items-center mx-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
                    <div class="title_inner d-flex">
                        <h1 class="d-flex align-items-center">{{'table_bookoing' | translate}}
                            <!--                            <span class="ml-4">$987.50</span>-->
                        </h1>
                        <button type="button" class="btn" (click)="navBookingTable()"><a>{{'book_new' |
                                translate}}</a></button>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
                    <form class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-3 mt-lg-0 pb-3 pb-md-0 ml-auto">
                        <div class="form-group d-flex">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText" (ngModelChange)="search()">
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
        <!-- Right Sidebar Start -->
        <div class="right_sidebar">
            <!-- Tab Content Start -->
            <div class="tab-content">
                <!-- Sales Tab Content Start -->
                <div class="order_list_container">
                    <!-- Order List Start -->
                    <div class="order_list">
                        <div class="list_header d-flex">
                            <h2 class="order_num Code">{{'date' | translate}}</h2>
                            <h2 class="text-left Payment ">{{'time' | translate}}</h2>
                            <h2 class="text-left Name">{{'customer' | translate}}</h2>
                            <h2 class="text-left  Note">{{'note' | translate}}</h2>
                            <h2 class="Table Price Date">{{'bookd_on' | translate}}</h2>
                            <h2 class="Status">{{'status' | translate}}</h2>
                            <h2 class="text-right Action">{{'action' | translate}}</h2>
                        </div>

                        <ul>
                            <div class="empty-view animate__animated animate__fadeInUp" *ngIf="!isLoading && (!appointments || !appointments.length)">
                                <div style="text-align:center;">
                                    <img src="assets/images/plc_no_item.png" alt="no offers" />
                                    <span style="color:#9E9E9E; font-weight:bold;">
                                        {{'empty_appointments' | translate}}
                                    </span>
                                </div>
                            </div>
                            <li *ngFor="let appoint of appointments | paginate : { itemsPerPage: pageSize, currentPage: pageNo, totalItems: pageTotal};let i = index" class="d-flex animate__animated animate__fadeInUp wow">
                                <h3 class="order_num Code">{{appoint.date}}</h3>
                                <h3 class="text-left Payment"><strong>{{appoint.time_from}}</strong></h3>
                                <h3 class="text-left Name" *ngIf="appoint.is_guest">
                                    <strong>{{appoint.customer_name}}</strong>
                                </h3>
                                <h3 class="text-left Name" *ngIf="!appoint.is_guest">
                                    <strong>{{appoint.user.name}}</strong>
                                </h3>
                                <h3 class="text-left Note">{{appoint.meta.note}}</h3>
                                <h3 class="Table Price Date">{{appoint.updated_at}}</h3>
                                <h3 class="Status">{{ ('appointment_status_'+appoint.status) | translate}}</h3>

                                <div *ngIf="appoint.status == 'new' || appoint.status == 'pending'" class="btn_container Action d-flex mr-0 ml-auto">
                                    <!-- <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-check"></i></a>
                                    </button> -->

                                    <button type="button" class="btn accept" (click)="confirmAccept(appoint)">
                                       {{'accept' | translate}} 
                                    </button>
                                    <button type="button" class="btn reject" (click)="confirmReject(appoint)">
                                       {{'reject' | translate}} 
                                    </button>
                                    <!-- 
                                    <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-delete"></i></a>
                                    </button> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Order List End -->
                </div>

                <!-- Tab Footer start -->
                <div *ngIf="pageTotal > pageSize" class="tab_footer">
                    <div class="row no-gutter align-items-center">
                        <div class="col-12 col-md-12 col-lg-4 pb-3">
                            <h2>{{'showing' | translate}} {{pageNo}} {{'to' | translate}} {{pageSize}} {{'of' | translate}} {{pageTotal}} {{'item' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-12 col-lg-8 pb-3">
                            <div class="row align-items-center">
                                <nav class="navigation col-12" aria-label="Page navigation example">
                                    <pagination-controls responsive="true" [previousLabel]="'prev' | translate" [nextLabel]="'next' | translate" (pageChange)="onChangePage($event)">
                                    </pagination-controls>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Footer End -->

                <!-- Sales Tab Content End -->
                <!-- Tab Content End -->
            </div>
        </div>
        <!-- Right Sidebar End -->
    </div>
    <!-- Body Wrapper End -->


    <!-- Add Expenses  Modal Start  -->
    <div class="modal fade add_expenses receipt_model px-0" id="add_expenses" tabindex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">

                <div class="modal-header px-0">
                    <h2 class="col-11 mx-auto">{{'add_booking' | translate}}</h2>
                </div>
                <div class="modal-body p-0">
                    <form class="pt-2 pt-sm-2 pt-md-4 ">
                        <div class="col-11 mx-auto form_container">
                            <div class="row">
                                <div class="col-12 col-lg-12 col-md-12 col-sm-12 my-0">
                                    <div class="form-group">
                                        <label>{{'select_date_time' | translate}}</label>
                                        <div class="select_box select d-flex">
                                            <input mb-0 class="form-control" type="datetime-local" name="date" [(ngModel)]="appointmentRequest.date" [min]="minDate | date:'yyyy-MM-ddThh:mm'" [max]="maxDate" placeholder="Select Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-12 col-md-12 col-sm-12 my-0">
                                    <div class="form-group">
                                        <label>{{'select_person' | translate}}</label>
                                        <div class="select_box select d-flex">
                                            <select name="select_person" [(ngModel)]="appointmentRequest.meta.person">
                                                <option value="" disabled="true" selected>{{'select_person' |
                                                    translate}}</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                            <i class="zmdi zmdi-caret-down"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-12 col-md-12 col-sm-12 my-0">
                                    <div class="form-group">
                                        <label>{{'customer_name' | translate}}</label>
                                        <input type="text" name="customer_name" [(ngModel)]="appointmentRequest.customer_name" placeholder="{{'name' | translate}}" class="form-control">
                                    </div>
                                </div>

                                <div class="col-12 col-lg-12 col-md-12 col-sm-12 my-0">
                                    <div class="form-group">
                                        <label>{{'customer_phone_number' | translate}}</label>
                                        <input type="tel" class="form-control" name="customer_mobile" [(ngModel)]="appointmentRequest.customer_mobile" placeholder="{{'phone_number' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>{{'notes' | translate}}</label>
                                <!--                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>-->
                                <input class="form-control" id="exampleFormControlTextarea1" name="note" [(ngModel)]="appointmentRequest.meta.note" placeholder="i.g For Anniversary Surprise">
                            </div>

                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row no-gutters w-100">
                        <div class="col-6"> <button type="file" class="btn Cencel" data-dismiss="modal"><a>{{'cencel' |
                                    translate}}</a></button></div>
                        <div class="col-6"> <button type="file" class="btn" (click)="onSubmitRequest()"><a>
                                    {{'create_booking' | translate}}
                                </a></button></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
import { Constants } from './constants.models';
import { AuthResponse } from './auth-response.models';
import { User } from './user.models';
import { MyAddress } from './address.models';
import { MyMeta } from './meta.models';
import { MyNotification } from './notification.models';
import { Profile } from './profile.models';
import { BankDetail } from './bank-detail.models';
import { Order } from './order.models';
import * as moment from 'moment';

export class Helper {
    static getZplReceiptFormat(order: Order): any {
        let toReturn = "^XA";
        toReturn += `^CF0,60^FO5,40^FB650,1,0,C^FD${order.vendor.name}^FS`;
        toReturn += `^CFF,30^FO100,110^FB450,1,0,C^FD${order.vendor.address}^FS`;
        toReturn += `^FO100,145^FB450,1,0,C^FD${"tel: " + order.vendor.user.mobile_number}^FS`;
        for (let i = 0; i < 18; i++) toReturn += `^FO${((i * 35) + 5)},190^GB30,1,1^FS`;
        toReturn += `^CF1,50,15^FO10,220^FB650,1,0,C^FD${"Due " + order.created_at}^FS`;
        toReturn += `^FO50,270^FB550,1,0,C^FD${"Order number: " + order.id}^FS`;
        for (let i = 0; i < 18; i++) toReturn += `^FO${((i * 35) + 5)},340^GB30,1,1^FS`;
        toReturn += `^CFA,30^FO550,380^FD${Helper.getSetting("currency_code")}^FS`;
        let prevItemStart = 380;
        let prevItemLoad = 0;
        for (let i = 0; i < order.products.length; i++) {
            prevItemStart += i == 0 ? 40 : 50;
            prevItemStart += prevItemLoad;
            prevItemLoad *= 0;
            let toAdd = `^CFA,30^FO20,${prevItemStart}^FD${order.products[i].quantity}^FS`;
            toAdd += `^FO90,${prevItemStart}^FD${order.products[i].vendor_product.product.title.trim().substring(0, 21)}^FS`;
            let nextSubStringHasLength = ((order.products[i].vendor_product.product.title.length / 21) > 1);
            let whileCount = 0;
            while (nextSubStringHasLength) {
                whileCount += 1;
                let nextSubString = order.products[i].vendor_product.product.title.trim().substring((0 + (21 * whileCount)), (21 + (21 * whileCount)));
                if (nextSubString.trim().length > 0) toAdd += `^FO90,${(prevItemStart + (whileCount * 30))}^FD${nextSubString.trim()}^FS`; else whileCount -= 1;
                nextSubStringHasLength = nextSubString.trim().length > 0;
            }
            let newStart = prevItemStart + prevItemLoad + 50;
            toReturn += `^CFA,30^FO20,${newStart}^FDSubtotal^FS`;
            toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.subtotal_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.subtotal_toshow)}^FS`;
            if (order.discount_toshow && order.discount_toshow.length) {
                newStart += 30;
                toReturn += `^CFA,30^FO20,${newStart}^FDDiscount^FS`;
                toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.discount_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.discount_toshow)}^FS`;
            }
            toAdd += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.products[i].total_toshow))},${prevItemStart}^FD${Helper.getCurrencyIconRemoved(order.products[i].total_toshow)}^FS`;
            toReturn += toAdd;
        }
        let newStart = prevItemStart + prevItemLoad + 50;
        toReturn += `^CFA,30^FO20,${newStart}^FDSubtotal^FS`;
        toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.subtotal_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.subtotal_toshow)}^FS`;
        if (order.discount_toshow && order.discount_toshow.length) {
            newStart += 30;
            toReturn += `^CFA,30^FO20,${newStart}^FDDiscount^FS`;
            toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.discount_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.discount_toshow)}^FS`;
        }
        newStart += 30;
        toReturn += `^CFA,30^FO20,${newStart}^FDService Fee^FS`;
        toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.taxes_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.taxes_toshow)}^FS`;
        for (let i = 0; i < 4; i++) toReturn += `^FO${(500 + (i * 35))},${newStart + 40}^GB30,1,1^FS`;
        newStart += 60;
        toReturn += `^CFA,30^FO20,${newStart}^FDTotal^FS`;
        toReturn += `^CFA,30^FO${Helper.getTextEndPaddingStart(Helper.getCurrencyIconRemoved(order.total_toshow))},${newStart}^FD${Helper.getCurrencyIconRemoved(order.total_toshow)}^FS`;
        newStart += 45;
        for (let i = 0; i < 18; i++) toReturn += `^FO${((i * 35) + 5)},${newStart}^GB30,1,1^FS`;
        newStart += 45;
        toReturn += `^CFF^FO20,${newStart}^FDCustomer ID:^FS`;
        newStart += 30;
        toReturn += `^CFA,30,20^FO40,${newStart}^FD${order.user ? order.user.id : "..."}^FS`;
        newStart += 50;
        toReturn += `^CFF^FO20,${newStart}^FDCustomer Details:^FS`;
        newStart += 30;
        toReturn += `^CFA,30,20^FO40,${newStart}^FD${(order.user ? order.user.name : (order.customer_name && order.customer_name.length ? order.customer_name : "...")) + "."}^FS`;
        newStart += 30;
        toReturn += `^CFA,30,20^FO40,${newStart}^FD${order.user ? order.user.mobile_number : (order.customer_mobile && order.customer_mobile.length ? order.customer_mobile : "...")}^FS`;
        if (order.address && order.address.formatted_address && order.address.formatted_address.length) {
            newStart += 30;
            toReturn += `^CFA,30,20^FO40,${newStart}^FD${(order.user ? order.user.name : (order.customer_name && order.customer_name.length ? order.customer_name : "...")) + "."}^FS`;
            newStart += 30;
            toReturn += `^CFA,30,20^FO40,${newStart}^FD${order.user ? order.user.mobile_number : (order.customer_mobile && order.customer_mobile.length ? order.customer_mobile : "...")}^FS`;
            if (order.address && order.address.formatted_address && order.address.formatted_address.length) {
                newStart += 30;
                toReturn += `^CFA,30,20^FO40,${newStart}^FD${order.address.formatted_address.substring(0, 20)}^FS`;
                let nextSubStringHasLength = ((order.address.formatted_address.length / 20) > 1);
                let whileCount = 0;
                while (nextSubStringHasLength) {
                    whileCount += 1;
                    let nextSubString = order.address.formatted_address.trim().substring((0 + (20 * whileCount)), (20 + (20 * whileCount)));
                    if (nextSubString.trim().length > 0) {
                        newStart += 30;
                        toReturn += `^CFA,30,20^FO40,${newStart}^FD${nextSubString.trim()}^FS`;
                    } else { whileCount -= 1; }
                    nextSubStringHasLength = nextSubString.trim().length > 0;
                }
            }
        }
        toReturn += "^XZ";
        console.log("ZPL: ", toReturn);
        return toReturn;
    }
    static getCurrencyIconRemoved(text: string): string {
        let toReturn = `${text}`;
        if (isNaN(parseInt(toReturn.charAt(0)))) toReturn = toReturn.slice(1);
        toReturn = toReturn.trim();
        return toReturn;
    }
    static getTextEndPaddingStart(text: string): number {
        return text.length < 5 ? 540 : (text.length < 6 ? 520 : (text.length < 7 ? 500 : 480));
    }
    static getSplitWithGroupingOf(toSplit: string, groupingOf: number): Array<string> {
        let newSplit = [];
        let mainSplit = toSplit.split(" ");
        let delta = Math.round(mainSplit.length / groupingOf);
        let counter = 0;
        let tempS = "";
        for (let s of mainSplit) {
            tempS += s;
            tempS += " ";
            counter += 1;
            if (counter == delta) {
                newSplit.push(tempS.trim());
                tempS = "";
                counter = 0;
            }
        }
        if (tempS.trim().length) newSplit.push(tempS.trim());

        let groupingDiff = newSplit.length - groupingOf;
        if (groupingDiff > 0) {
            let lastString = "";
            for (let i = groupingOf; i < newSplit.length; i++) {
                lastString += newSplit[i];
                lastString += " ";
            }
            newSplit.splice(groupingOf);
            newSplit[groupingOf - 1] += (" " + lastString.trim());
        }
        return newSplit;
    }

    static formatDuration(durationInSeconds: number): string {
        if (!durationInSeconds) durationInSeconds = 0;
        return moment.utc(durationInSeconds * 1000).format('mm:ss');
    }
    static formatDistance(distance: number, distanceMetric: string): string {
        if (!distance) distance = 0;
        let divider: number = (distanceMetric == "km") ? 1000 : 1609.34;
        return Number(distance / divider).toFixed(2);
    }
    static formatPhone(phone: string): string {
        let toReturn = phone.replace(/\s/g, '');
        while (toReturn.startsWith("0")) toReturn = toReturn.substring(1);
        return toReturn;
    }
    static getBankDetail(): BankDetail {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_BANK_DETAIL));
    }
    static setBankDetail(bd: BankDetail) {
        window.localStorage.setItem(Constants.KEY_BANK_DETAIL, JSON.stringify(bd));
    }
    static getPrinterNetworkConfig(): { host: string; port: string; name: string } {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_PRINTER_NETWORK_CONFIG));
    }
    static setPrinterNetworkConfig(pnc: { host: string; port: string; name: string }) {
        window.localStorage.setItem(Constants.KEY_PRINTER_NETWORK_CONFIG, JSON.stringify(pnc));
    }
    static setProfile(profile: Profile) {
        window.localStorage.setItem(Constants.KEY_PROFILE, JSON.stringify(profile));
    }

    static getProfile(): Profile {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_PROFILE));
    }

    static setLocationDefault(location: MyAddress) {
        window.localStorage.setItem(Constants.KEY_LOCATION_DEFAULT, JSON.stringify(location));
    }

    static getLocationDefault(): MyAddress {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_LOCATION_DEFAULT));
    }

    static setSettings(settings: Array<MyMeta>) {
        window.localStorage.setItem(Constants.KEY_SETTINGS, JSON.stringify(settings));
    }
    static getSettings(): Array<MyMeta> {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_SETTINGS));
    }
    static setLoggedInUser(user: User) {
        window.localStorage.setItem(Constants.KEY_USER, JSON.stringify(user));
    }
    static setDefaultThemeMode(status) {
        window.localStorage.setItem(Constants.KEY_DARK_MODE, status);
    }
    static getThemeMode() {
        return window.localStorage.getItem(Constants.KEY_DARK_MODE);
    }
    static setLoggedInUserResponse(authRes: AuthResponse) {
        window.localStorage.removeItem(Constants.KEY_USER);
        window.localStorage.removeItem(Constants.KEY_PROFILE);
        window.localStorage.removeItem(Constants.KEY_TOKEN);
        window.localStorage.removeItem(Constants.KEY_ADDRESS);
        window.localStorage.removeItem(Constants.KEY_ADDRESSES);
        window.localStorage.removeItem(Constants.KEY_NOTIFICATIONS);

        if (authRes && authRes.vendor && authRes.token) {
            window.localStorage.setItem(Constants.KEY_USER, JSON.stringify(authRes.vendor));
            window.localStorage.setItem(Constants.KEY_TOKEN, authRes.token);
        }
    }
    static getToken() {
        return window.localStorage.getItem(Constants.KEY_TOKEN);
    }
    static getChatChild(userId: string, myId: string) {
        //example: userId="9" and myId="5" -->> chat child = "5-9"
        let values = [userId, myId];
        values.sort((one, two) => (one > two ? -1 : 1));
        return values[0] + "-" + values[1];
    }
    static getLoggedInUser() {
        return JSON.parse(window.localStorage.getItem(Constants.KEY_USER));
    }

    // static getAddressSelected(): MyAddress {
    //     return JSON.parse(window.localStorage.getItem(Constants.KEY_ADDRESS));
    // }
    static getLocale(): string {
        let sl = window.localStorage.getItem(Constants.KEY_LOCALE);
        return sl && sl.length ? sl : "en";
    }
    static getLanguageDefault(): string {
        return window.localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
    }
    static setLanguageDefault(language: string) {
        window.localStorage.setItem(Constants.KEY_DEFAULT_LANGUAGE, language);
    }
    static setLocale(lc) {
        window.localStorage.setItem(Constants.KEY_LOCALE, lc);
    }
    static setAddressSelected(location: MyAddress) {
        window.localStorage.setItem(Constants.KEY_ADDRESS, JSON.stringify(location));
    }
    static getSetting(settingKey: string) {
        let settings: Array<MyMeta> = this.getSettings();
        let toReturn: string;
        if (settings) {
            for (let s of settings) {
                if (s.key == settingKey) {
                    toReturn = s.value;
                    break;
                }
            }
        }
        if (!toReturn) toReturn = "";
        return toReturn;
    }
    static getSettingsValue(settingKeys: Array<string>) {
        let settings: Array<MyMeta> = this.getSettings();
        let toReturn = new Array<string>();
        if (settings) {
            for (let settingKey of settingKeys) {
                for (let s of settings) {
                    if (s.key == settingKey) {
                        toReturn.push(s.value);
                        break;
                    }
                }
            }
        }
        return toReturn;
    }
    static saveNotification(notiTitle: string, notiBody: string, notiTime: string) {
        let notifications: Array<MyNotification> = JSON.parse(window.localStorage.getItem(Constants.KEY_NOTIFICATIONS));
        if (!notifications) notifications = new Array<MyNotification>();
        notifications.push(new MyNotification(notiTitle, notiBody, notiTime));
        window.localStorage.setItem(Constants.KEY_NOTIFICATIONS, JSON.stringify(notifications));
    }
    static formatMillisDateTime(millis: number, locale: string): string {
        return moment(millis).locale(locale).format("ddd, MMM D, HH:mm");
    }
    static formatTimestampDateTime(timestamp: string, locale: string): string {
        return moment.utc(timestamp).locale(locale).format("ddd, MMM D, HH:mm");
    }
    static formatMillisDate(millis: number, locale: string): string {
        return moment(millis).locale(locale).format("Do MMM YYYY");
    }
    static formatTimestampDate(timestamp: string, locale: string): string {
        return moment.utc(timestamp).locale(locale).format("Do MMM YYYY");
    }
    static formatMillisTime(millis: number, locale: string): string {
        return moment(millis).locale(locale).format("HH:mm");
    }
    static formatTimestampTime(timestamp: string, locale: string): string {
        return moment.utc(timestamp).locale(locale).format("HH:mm");
    }
}
<div id="page_reviews">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="page_title">
            <div class="row align-items-center mx-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
                    <div class="title_inner d-flex">
                        <h1 class="d-flex align-items-center">{{'avg_ratings' | translate}}
                            <span *ngIf="reviews && reviews.length" class="ml-4">
                                {{avg_ratings}} <i class="zmdi zmdi-star"></i>
                            </span>
                        </h1>
                        <!--                        <button type="button" class="btn"><a data-toggle="modal" data-target="#add_expenses">Book New</a></button>-->
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
                    <form class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-3 mt-lg-0 pb-3 pb-md-0 ml-auto">
                        <div class="form-group d-flex">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText" (ngModelChange)="search()">
                        </div>
                    </form>
                </div> -->
            </div>
        </div>

        <!-- Right Sidebar Start -->
        <div class="right_sidebar">
            <!-- Tab Content Start -->
            <div class="tab-content" id="nav-tabContent">

                <!-- Sales Tab Content Start -->
                <div class="order_list_container">
                    <!-- Order List Start -->
                    <div class="order_list">
                        <div class="list_header d-flex">
                            <h2 class="text-center order_num">{{'ratings' | translate}}</h2>
                            <h2 class="text-left Name">{{'name' | translate}}</h2>
                            <h2 class="text-left review">{{'review' | translate}}</h2>
                            <h2 class="text-center Date">{{'reviewed_on' | translate}}</h2>
                            <!-- <h2 class="text-center order ion-text-center">{{'order' | translate}}</h2> -->
                        </div>

                        <ul>
                            <div class="empty-view animate__animated animate__fadeInUp" *ngIf="!isLoading && (!reviews || !reviews.length)">
                                <div style="text-align:center;">
                                    <img src="assets/images/plc_no_review.png" alt="no offers" />
                                    <span style="color:#9E9E9E; font-weight:bold;">
                                        {{'empty_reviews' | translate}}
                                    </span>
                                </div>
                            </div>

                            <li *ngFor="let review of reviews | paginate : { itemsPerPage: pageSize, currentPage: pageNo, totalItems: pageTotal};let i = index" class="d-flex animate__animated animate__fadeInUp wow">
                                <h3 class="text-center order_num">{{review.rating}} <i class="zmdi zmdi-star"></i></h3>
                                <h3 class="text-left Name"><strong>{{review.user.name}}</strong></h3>
                                <h3 class="text-left review"><span>{{review.review}}</span>
                                </h3>
                                <!-- <h3 class="text-left review">
                                    <p class="text" [ngClass]="readAllText ? 'active': ''">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi qui soluta labore! Facere nisi aperiam sequi dolores voluptatum delectus vel vero animi, commodi harum molestias deleniti, quasi nesciunt. Distinctio
                                        veniam minus ut vero rerum debitis placeat veritatis doloremque laborum optio, nemo quibusdam ad, sed cum quas maxime hic enim sint at quos cupiditate qui eius quam tempora. Ab sint in sunt consequuntur assumenda
                                        ratione voluptates dicta dolor aliquid at esse quaerat ea, veritatis reiciendis, labore repellendus rem optio debitis illum! Eos dignissimos, atque possimus, voluptatibus similique error. Perferendis error doloribus
                                        harum enim dolorem, suscipit unde vel, totam in quia mollitia.</p>
                                    <a *ngIf="review.review.length > 64" (click)="read()">{{readAllText ? 'Hide' : 'Read all'}}</a>
                                </h3> -->
                                <h3 class="text-center Date">{{review.created_at}}</h3>
                                <!-- <h3 class="text-center order"><a>View order</a></h3> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Order List End -->

                <!-- Tab Footer start -->
                <div *ngIf="pageTotal > pageSize" class="tab_footer">
                    <div class="row no-gutter align-items-center">
                        <div class="col-12 col-md-12 col-lg-4 pb-3">
                            <h2>{{'showing' | translate}} {{pageNo}} {{'to' | translate}} {{pageSize}} {{'of' | translate}} {{pageTotal}} {{'item' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-12 col-lg-8 pb-3">
                            <div class="row align-items-center">
                                <nav class="navigation col-12" aria-label="Page navigation example">
                                    <pagination-controls responsive="true" [previousLabel]="'prev' | translate" [nextLabel]="'next' | translate" (pageChange)="onChangePage($event)">
                                    </pagination-controls>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Footer End -->
                <!-- Sales Tab Content End -->
                <!-- Tab Content End -->
            </div>
        </div>
        <!-- Right Sidebar End -->
    </div>
    <!-- Body Wrapper End -->
</div>
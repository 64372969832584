<div id="page_items" class="upload_item">
  <!-- Body Wrapper Start -->
  <div class="body_wrapper ">
    <div class="row no-gutters ">
      <!-- Left Sidebar Start -->
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2">
        <div class="upload my-2 pt-md-2 px-1 pr-md-0 ">
          <a class="back-link d-none d-sm-none d-md-block pt-md-3 mb-md-5 px-3 " [routerLink]="['../items']"><i
              class="zmdi zmdi-arrow-left"></i> Back</a>

          <div class="row no-gutters mt-3 px-3">
            <div
              class="text-center  animate__animated animate__fadeInUp wow col-6 col-sm-6 col-md-12 mb-md-4 col-lg-10 px-1 pr-md-0">
              <div class="upload-box mx-auto">
                <label *ngIf="!productRequest.image_urls || !productRequest.image_urls.length" for="img"
                  class="img m-0 active">
                  <i class="zmdi zmdi-image-alt"></i>
                  <input id="img" type="file" name="image" accept="image/*" (change)="onImageChange($event)">
                  <span>{{'upload' | translate}} <br> {{'items_image' | translate}}</span>
                </label>

                <label *ngIf="productRequest.image_urls && productRequest.image_urls.length" for="img"
                  class="img m-0 active file_uploated">
                  <div class="img_box center_img">
                    <img id="img" [src]="productRequest.image_urls[0]" alt="" class="crop_img">
                  </div>
                  <span (click)="confirmRemoveImage(i)">
                    {{'remove' | translate}} <br> {{'items_image' | translate}}
                  </span>
                </label>
              </div>
            </div>


            <div
              class="text-center  animate__animated animate__fadeInUp wow col-6 col-sm-6 col-md-12 mb-md-4  col-lg-10 px-1 pr-md-0">
              <div class="upload-box upload-video">
                <label *ngIf="!productRequest || !productRequest.meta || !productRequest.meta.video_url" for="video"
                  class="m-0 video">
                  <i class="zmdi zmdi-videocam"></i>
                  <input id="video" type="file" accept="video/*" (change)="onVideoChange($event)">
                  <span>{{'upload' | translate}} <br> {{'items_video' | translate}}</span>
                </label>
                <label *ngIf="productRequest && productRequest.meta && productRequest.meta.video_url" for="video"
                  class="m-0 video file_uploated">
                  <div class="video_box">
                    <video controls [autoplay]="true" [loop]="true" #videoPlayer>
                      <source [src]="productRequest.meta.video_url" type="video/mp4" />
                    </video>
                  </div>
                  <span (click)="confirmRemoveVideo()">
                    {{'remove' | translate}} <br> {{'items_video' | translate}}
                  </span>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-9 col-lg-9 col-xl-10  mt-2">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6 col-lg-7">
            <div class="upload_an_items p-4">
              <h3 class="mt-0 mb-5">
                {{pageTitle}}
              </h3>
              <form>
                <div class="form-group  animate__animated animate__fadeInUp wow">
                  <label>{{'item_name' | translate}}</label>
                  <input type="text" class="form-control" name="title" [(ngModel)]="productRequest.title">
                </div>
                <div class="row mx-0  animate__animated animate__fadeInUp wow">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>{{'item_price' | translate}} ({{currency_icon}})</label>
                      <input type="number" class="form-control" name="price" [(ngModel)]="productRequest.price">
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>{{'discount_price' | translate}} ({{currency_icon}})</label>
                      <input type="number" class="form-control" name="sale_price"
                        [(ngModel)]="productRequest.sale_price">
                    </div>
                  </div>
                </div>
                <div class="row mx-0  animate__animated animate__fadeInUp wow">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>Sort Order</label>
                      <input type="number" class="form-control" name="sort_order"
                        [(ngModel)]="productRequest.sort_order">
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>Stock Quantity</label>
                      <input type="number" class="form-control" name="stock_quantity"
                        [(ngModel)]="productRequest.stock_quantity">
                    </div>
                  </div>
                </div>

                <div class="form-group  animate__animated animate__fadeInUp wow">
                  <label>{{'choose_items_category' | translate}}</label>
                  <ng-select multiple="true" [(ngModel)]="categoriesSelectedStrings" name="category"
                    (change)="onCategoriesChange()">
                    <ng-option *ngFor="let cat of categoryStrings" [value]="cat">{{cat}}</ng-option>
                  </ng-select>
                  <!-- <div class="select_box w-100 d-flex">
                                        <select class="form-control" multiple="true" [(ngModel)]="categoriesSelectedStrings" name="category" (change)="onCategoriesChange()">
                                            <option *ngFor="let cat of categoryStrings" [value]="cat">{{cat}}</option>
                                        </select>
                                        <i class="zmdi zmdi-caret-down"></i>
                                    </div> -->
                </div>

                <div class="form-group  animate__animated animate__fadeInUp wow">
                  <label>{{'item_description' | translate}}</label>
                  <textarea rows="5" class="form-control py-1" name="description"
                    [(ngModel)]="productRequest.detail"></textarea>
                </div>

                <div class="form-group  animate__animated animate__fadeInUp wow">
                  <label>{{'add_ingredients_separated_by_commas' | translate}}</label>
                  <textarea rows="5" class="form-control py-1" name="ingredient"
                    [(ngModel)]="productIngredient"></textarea>
                </div>

                <!-- <div class="form-group ingredients animate__animated animate__fadeInUp wow">
                                    <label>{{'add_ingredients_separated_by_commas' | translate}}</label>
                                    <ng-select multiple="true">
                                        <ng-option>Flour</ng-option>
                                        <ng-option selected="selected">Sugar</ng-option>
                                        <ng-option selected="selected">Baking powder</ng-option>
                                        <ng-option selected="selected">Salt</ng-option>
                                        <ng-option selected="selected">Egg</ng-option>
                                        <ng-option selected="selected">Butter Milk</ng-option>
                                        <ng-option selected="selected">Butter</ng-option>
                                        <ng-option selected="selected">Vegetavle Oil</ng-option>
                                        <ng-option selected="selected">Butter</ng-option>
                                    </ng-select>
                                </div> -->

                <div class="row animate__animated animate__fadeInUp wow">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="form-group">
                      <label>{{'serve_for' | translate}}</label>
                      <input type="text" class="form-control" name="serving" [(ngModel)]="productRequest.meta.serving">
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="form-group">
                      <label>{{'cook_time' | translate}}</label>
                      <input type="text" class="form-control" name="cooking_time"
                        [(ngModel)]="productRequest.meta.cooking_time">
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="form-group">
                      <label>{{'energy' | translate}}</label>
                      <input type="text" class="form-control" name="energy" [(ngModel)]="productRequest.meta.energy">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-5 second-box">

            <div class="upload_an_items p-4">
              <!-- <div class="row  align-items-center" style="padding-bottom: 29px;">
                <h3 class="m-0 col-12 col-md-7">{{'add_add_ons' | translate}} <small>(Optional)</small>
                </h3> -->
                <!-- <button
                  class="col-12 col-md-5 animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                  style="float: right;" type="button" (click)="addOnsEnabled ? addAddonGroup() : showAddonGroup()">
                  <span><i class="zmdi zmdi-plus"></i> {{'add_more_group' | translate}}</span>
                </button> -->
              <!-- </div> -->

              <!-- <form *ngIf="addOnsEnabled" [formGroup]="addonForm">
                <div formArrayName="addon_groups">
                  <div class="row pb-4" *ngFor="let x of addonForm.controls.addon_groups['controls']; let i = index"
                    formGroupName="{{i}}">
                    <div class="col-12">
                      <div class="form-group  animate__animated animate__fadeInUp wow w-100">
                        <label class="d-flex">{{'addon_group_name' | translate}}
                          <span class="mr-0 ml-auto delete_group_button"
                            *ngIf="addonForm.controls.addon_groups['controls'].length > 1"
                            (click)="deleteAddonGroupForm(i)">
                            <i class="zmdi zmdi-delete"></i>
                            {{'delete_group' | translate}}
                          </span>
                          <span class="mr-0 ml-auto delete_group_button"
                            *ngIf="addonForm.controls.addon_groups['controls'].length == 1" (click)="showAddonGroup()">
                            <i class="zmdi zmdi-delete"></i>
                            {{'delete_group' | translate}}
                          </span>
                        </label>
                        <input type="text" class="form-control" placeholder="{{'title' | translate}}" required=""
                          formControlName="title">
                      </div>
                    </div>
                    <div formArrayName="choices">
                      <div class="row" style="align-items: center;"
                        *ngFor="let y of x['controls'].choices['controls']; let iy=index" formGroupName="{{iy}}">
                        <div class="col-12 col-sm-12 col-md-12 col-lg col-xl">
                          <div class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'option_title' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'option_title' | translate}}"
                              required="" formControlName="title">
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg col-xl">
                          <div class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'price' | translate}} ({{currency_icon}})</label>
                            <input type="text" class="form-control" placeholder="{{'enter_price' | translate}}"
                              required="" formControlName="price">
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 animate__animated animate__zoomIn wow">
                          <h6 class="mt-2 pt-1" (click)="deleteAddonChoiceForm(iy,x['controls'].choices)"
                            *ngIf="x['controls'].choices['controls'].length > 1">
                            <i class="zmdi zmdi-close"></i>
                          </h6>

                           <div
                                                        class="form-group delete_addon animate__animated animate__fadeInUp wow">
                                                        <label></label>
                                                        <i (click)="deleteAddonChoiceForm(iy,x['controls'].choices)"
                                                            *ngIf="x['controls'].choices['controls'].length > 1"
                                                            class="zmdi zmdi-tag-close ion-text-end"></i>
                                                    </div>

                        </div>
                      </div>
                    </div>
                    <div style="display: flex;justify-content: space-between; width:100%;">
                      <button
                        class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                        type="button" (click)="addAddonChoice(i)">
                        <span><i class="zmdi zmdi-plus"></i> {{'add_more' | translate}}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form> -->
            </div>

            <div class="upload_an_items item_in_stock pt-4 mt-2  animate__animated animate__fadeInUp wow"
              data-wow-duration="1s">
              <div class="d-flex align-items-center px-4 pb-4">
                <h3 class="mb-0">{{'food_type' | translate}}</h3>

                <div class="toggle_box align-items-center d-flex ml-auto">
                  <h4>{{ (productTypeStatus ? 'veg' : 'non_veg') | translate}}</h4>
                  <label class="switch mb-0">
                    <input type="checkbox" [checked]="productTypeStatus" [(ngModel)]="productTypeStatus">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="d-flex align-items-center px-4 pb-4">
                <h3 class="mb-0">{{'item_in_stock' | translate}}</h3>

                <div class="toggle_box align-items-center d-flex ml-auto">
                  <h4>{{ (productQuantityStatus ? 'yes' : 'no') | translate}}</h4>
                  <label class="switch mb-0">
                    <input type="checkbox" [checked]="productQuantityStatus" [(ngModel)]="productQuantityStatus">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <button type="file" class="btn py-2" (click)="saveProduct()">
                {{pageTitle}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

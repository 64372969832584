import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/models/helper.models';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  privacy_policy: string;

  constructor() { }

  ngOnInit(): void {
    this.privacy_policy = Helper.getSetting("terms");
  }

}

<div id="page_authentication">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="page_title">
            <div class="row align-items-center mx-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                    <div class="d-flex">
                        <h1>{{'authentication' | translate}}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isLoading" class="row" [formGroup]="authenticationForm">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <div class="card p-4 border-0 rounded-0 animate__animated animate__zoomIn wow" data-wow-duration=".3s">
                    <form formArrayName="kitchenArray">
                        <div *ngIf="!authenticationForm.controls.kitchenArray['controls'].length" class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'kitchen_app' | translate}}</label>
                        </div>
                        <div *ngFor="let item of authenticationForm.controls.kitchenArray['controls']; let i = index;" [formGroupName]="i" class="form-group  animate__animated animate__fadeInUp wow">
                            <label *ngIf="i == 0">{{'kitchen_app' | translate}}</label>
                            <div class="input_number_class">
                                <input type="tel" [readOnly]="(i < isDemoModeArray[0]  && config.demoMode)" class="form-control number_class" placeholder="{{'enter_phone_number' | translate}}" formControlName="identifier">
                                <a class="icon" *ngIf="authenticationForm['controls'].kitchenArray['controls'].length > 1" (click)="deleteForm(i,authenticationForm['controls'].kitchenArray)"><i class="zmdi zmdi-delete"></i></a>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('required') && item.controls['identifier'].touched ">
                                <span style="color: red">{{'enter_phone_number' | translate}}</span>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('pattern')">
                                <span style="color: red">{{'enterValidNumber' | translate}}</span>
                            </div>
                        </div>
                        <button (click)="addKitchenForm()" class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" type="button">
                            <span>+ {{'add_more' | translate}}</span>
                        </button>
                    </form>
                </div>

                <div class="card p-4 border-0 rounded-0 animate__animated animate__zoomIn wow" data-wow-duration=".3s">
                    <form formArrayName="emenuArray">
                        <div *ngIf="!authenticationForm.controls.emenuArray['controls'].length" class="form-group  animate__animated animate__fadeInUp wow">
                            <label style="text-transform: initial;">{{'emenu_app' | translate}}</label>
                        </div>
                        <div *ngFor="let item of authenticationForm.controls.emenuArray['controls']; let i = index;" [formGroupName]="i" class="form-group  animate__animated animate__fadeInUp wow">
                            <label style="text-transform: initial;" *ngIf="i == 0">{{'emenu_app' | translate}}</label>
                            <div class="input_number_class">
                                <input type="tel" [readOnly]="(i < isDemoModeArray[1]  && config.demoMode)" class="form-control number_class" placeholder="{{'enter_phone_number' | translate}}" formControlName="identifier">
                                <a class="icon" *ngIf="authenticationForm['controls'].emenuArray['controls'].length > 1" (click)="deleteForm(i,authenticationForm['controls'].emenuArray)"><i class="zmdi zmdi-delete"></i></a>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('required') && item.controls['identifier'].touched ">
                                <span style="color: red">{{'enter_phone_number' | translate}}</span>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('pattern')">
                                <span style="color: red">{{'enterValidNumber' | translate}}</span>
                            </div>
                        </div>
                        <button (click)="addEmenuForm()" class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" type="button">
                            <span>+ {{'add_more' | translate}}</span>
                        </button>
                    </form>
                </div>

                <div class="card p-4 border-0 rounded-0 animate__animated animate__zoomIn wow" data-wow-duration=".3s">
                    <form formArrayName="deskArray">
                        <div *ngIf="!authenticationForm.controls.deskArray['controls'].length" class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'desk_app' | translate}}</label>
                        </div>
                        <div *ngFor="let item of authenticationForm.controls.deskArray['controls']; let i = index;" [formGroupName]="i" class="form-group  animate__animated animate__fadeInUp wow">
                            <label *ngIf="i == 0">{{'desk_app' | translate}}</label>
                            <div class="input_number_class">
                                <input type="tel" [readOnly]="(i < isDemoModeArray[2]  && config.demoMode)" class="form-control number_class" placeholder="{{'enter_phone_number' | translate}}" formControlName="identifier">
                                <a class="icon" *ngIf="authenticationForm['controls'].deskArray['controls'].length > 1" (click)="deleteForm(i,authenticationForm['controls'].deskArray)"><i class="zmdi zmdi-delete"></i></a>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('required') && item.controls['identifier'].touched ">
                                <span style="color: red">{{'enter_phone_number' | translate}}</span>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('pattern')">
                                <span style="color: red">{{'enterValidNumber' | translate}}</span>
                            </div>
                        </div>
                        <button (click)="addDeskForm()" class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" type="button">
                            <span>+ {{'add_more' | translate}}</span>
                        </button>
                    </form>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <div class="card p-4 border-0 rounded-0 animate__animated animate__zoomIn wow" data-wow-duration=".3s">
                    <form formArrayName="kioskArray">
                        <div *ngIf="!authenticationForm.controls.kioskArray['controls'].length" class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'kiosk_app' | translate}}</label>
                        </div>
                        <div *ngFor="let item of authenticationForm.controls.kioskArray['controls']; let i = index;" [formGroupName]="i" class="form-group  animate__animated animate__fadeInUp wow">
                            <label *ngIf="i == 0">{{'kiosk_app' | translate}}</label>
                            <div class="input_number_class">
                                <input type="tel" [readOnly]="(i < isDemoModeArray[3]  && config.demoMode)" class="form-control number_class" placeholder="{{'enter_phone_number' | translate}}" formControlName="identifier">
                                <a class="icon" *ngIf="authenticationForm['controls'].kioskArray['controls'].length > 1" (click)="deleteForm(i,authenticationForm['controls'].kioskArray)"><i class="zmdi zmdi-delete"></i></a>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('required') && item.controls['identifier'].touched ">
                                <span style="color: red">{{'enter_phone_number' | translate}}</span>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('pattern')">
                                <span style="color: red">{{'enterValidNumber' | translate}}</span>
                            </div>
                        </div>
                        <button (click)="addKioskForm()" class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" type="button">
                            <span>+ {{'add_more' | translate}}</span>
                        </button>
                    </form>
                </div>

                <div class="card p-4 border-0 rounded-0 animate__animated animate__zoomIn wow" data-wow-duration=".3s">
                    <form formArrayName="reviewArray">
                        <div *ngIf="!authenticationForm.controls.reviewArray['controls'].length" class="form-group  animate__animated animate__fadeInUp wow">
                            <label>{{'review_app' | translate}}</label>
                        </div>
                        <div *ngFor="let item of authenticationForm.controls.reviewArray['controls']; let i = index;" [formGroupName]="i" class="form-group  animate__animated animate__fadeInUp wow">
                            <label *ngIf="i == 0">{{'review_app' | translate}}</label>
                            <div class="input_number_class">
                                <input type="tel" [readOnly]="(i < isDemoModeArray[4]  && config.demoMode)" class="form-control number_class" placeholder="{{'enter_phone_number' | translate}}" formControlName="identifier">
                                <a class="icon" *ngIf="authenticationForm['controls'].reviewArray['controls'].length > 1" (click)="deleteForm(i,authenticationForm['controls'].reviewArray)"><i class="zmdi zmdi-delete"></i></a>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('required') && item.controls['identifier'].touched ">
                                <span style="color: red">{{'enter_phone_number' | translate}}</span>
                            </div>
                            <div *ngIf="item.controls['identifier'].hasError('pattern')">
                                <span style="color: red">{{'enterValidNumber' | translate}}</span>
                            </div>
                        </div>
                        <button (click)="addReviewForm()" class=" animate__animated animate__fadeInUp wow btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" type="button">
                            <span>+ {{'add_more' | translate}}</span>
                        </button>
                    </form>
                </div>

                <button type="submit" (click)="onSubmitAuthentication()" class="btn rounded-pill py-3 col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4 ml-auto d-block animate__animated animate__zoomIn wow" data-wow-duration=".3s">Save</button>

            </div>
        </div>
    </div>
</div>
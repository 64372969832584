import { Component, Inject, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthResponse } from 'src/models/auth-response.models';
import { Constants } from 'src/models/constants.models';
import { Helper } from 'src/models/helper.models';
import { SocialLoginRequest } from 'src/models/sociallogin-request.models';
import { AppConfig, APP_CONFIG } from '../app.config';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { MyEventsService } from '../services/events/my-events.service';
import { ApiService } from '../services/network/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  countries: any;
  phoneNumber: string;
  countryCode: string = "";
  phoneNumberFull: string;
  phoneNumberHint: string;
  vendorID: number;
  email: string;
  password: string; 

  constructor(@Inject(APP_CONFIG) public config: AppConfig, private myEvent: MyEventsService, private router: Router,
    private uiElementService: UiElementsService, private apiService: ApiService, private translate: TranslateService,
    private confirmationDialogService: ConfirmationDialogService) { }

  ngOnDestroy() {

  }

  ngOnInit() {
    localStorage.removeItem("orders")
    this.apiService.getCountries().subscribe(res => this.countries = res);
    this.changeHint();
    if (this.config.demoMode && !Helper.getLoggedInUser()) this.openDemoLogin();
  }

  openDemoLogin() {
    this.countryCode = this.config.demoLoginCredentials.country;
    setTimeout(() => this.phoneNumber = this.config.demoLoginCredentials.phoneNumber, 500);
    this.translate.get(['demo_login_title', 'demo_login_message', 'okay']).subscribe(text => {
      this.confirmationDialogService.confirm(text['demo_login_title'], text['demo_login_message'], text['yes']).then((confirmed) => { }).catch(() => { console.log('err') });
    });
  }


  changeHint() {
    this.phoneNumber = "";
    if (this.countryCode && this.countryCode.length) {
      this.translate.get('enter_phone_number_exluding').subscribe(value => this.phoneNumberHint = (value + " (+" + this.countryCode + ")"));
    } else {
      this.translate.get('enter_phone_number').subscribe(value => this.phoneNumberHint = value);
    }
  }

  alertPhone() {
    if (!this.countryCode || !this.countryCode.length) {
      this.translate.get("select_country").subscribe(value => this.uiElementService.showErrorToastr(value));
      return;
    }
    if (!this.phoneNumber || !this.phoneNumber.length) {
      this.uiElementService.showErrorToastr(this.phoneNumberHint);
      return;
    }
    this.translate.get(['alert_phone', 'no', 'yes']).subscribe(text => {
      this.phoneNumberFull = "+" + this.countryCode + Helper.formatPhone(this.phoneNumber);
      this.confirmationDialogService.confirm(this.phoneNumberFull, text['alert_phone'], text['yes'], text['no']).then((confirmed) => { if (confirmed) this.checkIfExists() }).catch(() => { console.log('err') });
    });
    // this.checkIfExists();
  }
  alertEmailPassword() {
    this.translate.get(['alert_email', 'no', 'yes']).subscribe(text => {
      this.phoneNumberFull = "+" + this.countryCode + Helper.formatPhone(this.phoneNumber);
      this.confirmationDialogService.confirm(this.email, text['alert_email'], text['yes'], text['no']).then((confirmed) => { if (confirmed) this.checkIfExists() }).catch(() => { console.log('err') });
    });
    // this.checkIfExists();
  }
  checkIfExists() {
    this.translate.get('just_moment').subscribe(value => {
      // this.phoneNumberFull = "+" + this.countryCode + Helper.formatPhone(this.phoneNumber);
      this.uiElementService.presentLoading(value);
//      this.apiService.autheticationDelegations({ identifier: this.phoneNumberFull, type: Constants.ROLE_DESK, vendor_id:this.vendorID }).subscribe(res => {
        this.apiService.autheticationDelegationsWithEmailPassword({ email: this.email, password: this.password, vendor_id:this.vendorID }).subscribe(res => {

        this.uiElementService.dismissLoading();

        // let navigationExtras: NavigationExtras = { queryParams: { phoneNumberFull: this.phoneNumberFull } };
        // let navigationExtras: NavigationExtras = { queryParams: { phoneNumberFull: this.phoneNumberFull, isDemoNumber: (this.config.demoMode && this.phoneNumber == this.config.demoLoginCredentials.phoneNumber) } };
      //  this.router.navigate(['./verification'], navigationExtras);

       // Save user data to session storage
       sessionStorage.setItem('userData', JSON.stringify(res));

       // Save user data to local storage
       localStorage.setItem('userData', JSON.stringify(res));

      this.myEvent.setLoginData(res);


      }, err => {
        console.log(err);
        this.uiElementService.dismissLoading();
        this.translate.get("invalid_credentials").subscribe(value => this.uiElementService.showErrorToastr(value));
        // let navigationExtras: NavigationExtras = { queryParams: { code: this.countryCode, phone: this.phoneNumber } };
        // this.router.navigate(['./sign-up'], navigationExtras);
      });
    });
  }
}

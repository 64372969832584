export class Constants {
    static KEY_DEFAULT_LANGUAGE = 'saleemsdesk_dl';
    static KEY_USER = 'saleemsdesk_user';
    static KEY_NOTIFICATIONS = 'saleemsdesk_notis';
    static KEY_TOKEN = 'saleemsdesk_token';
    static KEY_SETTINGS = 'saleemsdesk_settings';
    static KEY_ADDRESS = 'saleemsdesk_address';
    static KEY_ADDRESSES = 'saleemsdesk_addresses';
    static KEY_CATEGORIES_PARENT: 'saleemsdesk_cat_parents';
    static KEY_APPOINT_REQ = 'saleemsdesk_appoint_req';
    static KEY_APPOINT_MYRATES = 'saleemsdesk_appoint_myrates';
    static KEY_LOCALE = "saleemsdesk_locale";
    static KEY_LOCATION_DEFAULT = "saleemsdesk_location_def";
    static KEY_PROFILE = "saleemsdesk_profile";
    static KEY_BANK_DETAIL = "saleemsdesk_bank_detail";
    static KEY_DARK_MODE = 'saleemsdesk_dark_mode'
    static KEY_CART_CHANGED = 'saleemsdesk_cart_changed';
    static KEY_PRINTER_NETWORK_CONFIG = 'saleemsdesk_network_config';

    static REF_USERS = "fire_app/users";
    static REF_CHAT = "fire_app/chats";
    static REF_INBOX = "fire_app/inbox";
    static REF_USERS_FCM_IDS = "fire_app/user_fcm_ids";

    static SCOPE_ECOMMERCE = "ecommerce";
    static SCOPE_SPECIALIZATION = "specializations";

    static ROLE_USER = "customer";
    static ROLE_VENDOR = "vendor";
    static ROLE_DESK = "desk";
    static ROLE_DELIVERY = "delivery";
}

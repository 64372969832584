import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MyAddress } from 'src/models/address.models';
import { BaseListResponse } from 'src/models/base-list.models';
import { Chat } from 'src/models/chat.models';
import { Constants } from 'src/models/constants.models';
import { Helper } from 'src/models/helper.models';
import { Message } from 'src/models/message.models';
import { Order } from 'src/models/order.models';
import { Profile } from 'src/models/profile.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';
import { GoogleMapsService } from '../services/network/google-maps.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import createHTMLMapMarker from '../../assets/scripts/html-map-marker.js';
import { QzPrinterService } from '../services/common/qz-printer.service';
import { AppConfig, APP_CONFIG, PrinterFormat } from '../app.config';
import * as firebase from 'firebase';
import { CategoryInsight } from 'src/models/insight-category.models';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
})
export class TransactionsComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  private subscriptions = new Array<Subscription>();
  isLoading = true;
  orders = new Array<Order>();
  profile: Profile;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;
  order: Order;
  private myOrdersRef: firebase.database.Reference;
  private myMenuItemsRef: firebase.database.Reference;
  private myTableStatusRef: firebase.database.Reference;
  insightsCategory: CategoryInsight;
  private profileMe: Profile;

  // tracking key
  @ViewChild('pleaseConnect', { static: true }) pleaseConnect: ElementRef;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  canUpdate = true;

  private initialized = false;
  private markerDeliveryGuy;
  private posDeliveryGuy;
  private numDeltas = 100;
  private delay = 10; //milliseconds
  private i = 0;
  private deltaLat;
  private deltaLng;
  private lastToPos = [0, 0];
  viewType: boolean = false;
  viewTypeA: boolean = false;
  viewTypeB: boolean = false;
  duration = 'today';
  alertModule = false;

  // chat key
  // @ViewChild("myContent", { static: true }) myContent: any;
  chatChild: string;
  userPlayerId: string;
  newMessageText: string;
  chatRef: firebase.database.Reference;
  inboxRef: firebase.database.Reference;
  messages = new Array<Message>();
  chatObj = new Chat();
  currency_icon: string;
  audio = new Audio();

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private printerServide: QzPrinterService,
    private translate: TranslateService,
    private uiElementService: UiElementsService,
    private apiService: ApiService,
    private maps: GoogleMapsService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) {
    this.audio.src = '../../../assets/sound/livechat.mp3';
  }

  ngOnInit(): void {
    this.profileMe = Helper.getProfile();
    this.currency_icon = Helper.getSetting('currency_icon');
    this.profile = Helper.getProfile();
    this.myMenuItemsRef = firebase
      .database()
      .ref('vendors')
      .child(String(this.profile.id))
      .child('order_products');
    this.myTableStatusRef = firebase
      .database()
      .ref('vendors')
      .child(String(this.profile.id))
      .child('table_status');
    this.translate.get('loading').subscribe((value) => {
      this.uiElementService.presentLoading(value);
      this.getOrders();
      //setTimeout(() => this.getOrders(), 1000);
    });
    this.loadInsightCategory();
    setInterval(() => {
      if (this.router.url.includes('transactions')) {
        this.loadInsightCategory();
        this.spinnerService.hide();
      }
    }, 60000);
  }

  private getInsightRequest() {
    //  'duration' => 'required|in:hours,days,months,years',
    //  'limit' => 'required|numeric'
    let toReturn = { duration: '', limit: 0 };
    switch (this.duration) {
      case 'today':
        toReturn.duration = 'hours';
        toReturn.limit = 24;
        break;
      case 'week':
        toReturn.duration = 'days';
        toReturn.limit = 7;
        break;
      case 'month':
        toReturn.duration = 'months';
        toReturn.limit = 12;
        break;
      case 'year':
        toReturn.duration = 'years';
        toReturn.limit = 12;
        break;
    }
    return toReturn;
  }

  dismiss() {
    this.audio.pause();
   window.location.reload();
  }

  loadInsightCategory() {
    let insightRequest = this.getInsightRequest();
    this.subscriptions.push(
      this.apiService
        .getInsightOrder(this.profileMe.id, insightRequest)
        .subscribe(
          (res: any) => {
            this.insightsCategory = res;
            let order = localStorage.getItem('orders');
            if (order && res?.orders && res?.orders > +order) {
              //this.alertModule = true;
              $('#exampleModal').modal('show');

              this.audio.load();
              this.audio.play();

              //this.insightsCategory.summary = new CategoryInsight().summary;
            }
            localStorage.setItem('orders', res?.orders);
          },
          (err) => console.log('getInsightCategory', err)
        )
    );
  }

  getOrders() {
    this.subscriptions.push(
      this.apiService.getOrders(this.profile.id, this.pageNo).subscribe(
        (res) => this.ordersRes(res),
        (err) => this.ordersErr(err)
      )
    );
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    // this.unRegisterUpdates();
    this.uiElementService.dismissLoading();
    $('#receipt_model').modal('hide');
  }

  onChangePage(event) {
    this.pageNo = event;
    this.translate.get('loading').subscribe((value) => {
      this.uiElementService.presentLoading(value);
      this.getOrders();
    });
  }

  search() {
    this.subscriptions.push(
      this.apiService
        .getOrders(this.profile.id, this.pageNo, this.searchText)
        .subscribe(
          (res) => this.ordersRes(res),
          (err) => this.ordersErr(err)
        )
    );
  }

  ordersRes(res: BaseListResponse) {
    this.orders = res.data;
    this.isLoading = false;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.uiElementService.dismissLoading();
  }

  ordersErr(err) {
    console.log('productsErr', err);
    this.isLoading = false;
    this.uiElementService.dismissLoading();
  }

  orderDetails(order: Order) {
    // if(!order.is_guest){
    this.order = order;
    // console.log('order',this.order)
    $('#receipt_model').modal('show');
    // }
  }

  printReceipt(order: Order) {
    this.apiService.getReceiptFormat(order.id).subscribe(
      (res) => {
        var mywindow = window.open('', 'PRINT', 'height=700,width=700');
        mywindow.document.write(res);
        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
      },
      (err) => {
        console.log('getReceiptFormat: ', err);
        this.translate
          .get('something_wrong')
          .subscribe((value) => this.uiElementService.showErrorToastr(value));
      }
    );
    //    this.router.navigate(['/print_recipt/' + order.id])

    //mywindow.close();

    //  window.open(window.location.host + '/'+window.location.hash.split('/')[0]+'/print_recipt/' + order.id, '_blank',
    //  'left=0,top=0,width=' +
    //  screen.availWidth +
    //  ',height=' +
    //  screen.availHeight);
    // if (this.config.printerConfig.enabled) {
    //   let savedNetworkConfig = Helper.getPrinterNetworkConfig();
    //   if (savedNetworkConfig) {
    //     if (savedNetworkConfig.name && savedNetworkConfig.name.trim().length) {
    //       this.printerServide.getPrinters().subscribe(res => {
    //         console.log("getPrinters: ", res);
    //         if (res.indexOf(savedNetworkConfig.name.trim()) == -1) {
    //           this.translate.get("unable_find_printer").subscribe(value => this.uiElementService.showErrorToastr(value.replace("<name>", savedNetworkConfig.name.trim())));
    //         } else {
    //           this.printWith({ name: savedNetworkConfig.name.trim(), host: null, port: null }, order);
    //         }
    //       }, err => {
    //         console.log("getPrinters: ", err);
    //         this.translate.get("unable_load_printers").subscribe(value => this.uiElementService.showErrorToastr(value));
    //       });
    //     } else if (savedNetworkConfig.host && savedNetworkConfig.host.trim().length && savedNetworkConfig.port && savedNetworkConfig.port.trim().length) {
    //       this.printWith({ name: null, host: savedNetworkConfig.host.trim(), port: savedNetworkConfig.port.trim() }, order);
    //     } else {
    //       this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
    //     }
    //   } else {
    //     this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
    //   }
    //   //this.printerServide.printData(savedNetworkConfig ? savedNetworkConfig : this.config.printerConfig.network, [Helper.getReceiptFormat(order)]).subscribe(res => console.log("printData: ", res), err => console.log("printData: ", err));
    // }
  }

  private printWith(networkConfig, order) {
    if (this.config.printerConfig.format == PrinterFormat.ZPL) {
      this.printerServide
        .printData(networkConfig, [Helper.getZplReceiptFormat(order)])
        .subscribe(
          (res) => console.log('printData: ', res),
          (err) => {
            console.log('printData: ', err);
            this.translate
              .get('unable_print')
              .subscribe((value) =>
                this.uiElementService.showErrorToastr(value)
              );
          }
        );
    } else {
      this.apiService.getReceiptFormat(order.id).subscribe(
        (res) => {
          window.open(window.location.host + '/print_recipt/' + order.id);
          // this.printerServide.printData(networkConfig, [res]).subscribe(res => console.log("printData: ", res), err => {
          //   console.log("printData: ", err);
          //   this.translate.get("unable_print").subscribe(value => this.uiElementService.showErrorToastr(value));
          // });
        },
        (err) => {
          console.log('getReceiptFormat: ', err);
          this.translate
            .get('something_wrong')
            .subscribe((value) => this.uiElementService.showErrorToastr(value));
        }
      );
    }
  }

  // closeCustomerChat() {
  //   $("#content").removeClass("active1");
  //   $("#caht-with-customer").removeClass("active1");
  //   this.viewType = false;
  // }

  openCustomerChat() {
    if (this.viewTypeA) this.tracking();
    $('#content').toggleClass('active1');
    $('#caht-with-customer').toggleClass('active1');
    this.viewType = !this.viewType;
    if (this.viewType) this.navChatCustomer();
    if (!this.viewType) {
      this.chatRef.child(this.chatChild).off('child_added');
      console.log('off');
    }
  }

  tracking() {
    if (this.viewType) this.openCustomerChat();
    if (this.viewTypeB) this.openDeliveryChat();
    $('#content').toggleClass('active2');
    $('#caht-with-delivery-partner').toggleClass('active2');
    $('#content').removeClass('active1');
    this.viewTypeA = !this.viewTypeA;
    if (this.viewTypeA) this.loadTrackingMap();
  }

  openDeliveryChat() {
    $('.chat-widget-body').toggleClass('active');
    $('#chat-widget-body').toggleClass('active');
    this.viewTypeB = !this.viewTypeB;
    if (this.viewTypeB) this.navChatDelivery();
    if (!this.viewTypeB) {
      this.chatRef.child(this.chatChild).off('child_added');
      console.log('off');
    }
  }

  navChatCustomer() {
    this.chatChild = '';
    this.newMessageText = '';
    // var reference = firebase.database().ref();
    // this.chatRef = reference;
    // this.inboxRef = reference;
    this.messages = new Array<Message>();
    this.chatObj = new Chat();

    let chat = new Chat();
    chat.chatId = this.order.user_id + Constants.ROLE_USER;
    chat.chatImage = this.order.user.image_url;
    chat.chatName = this.order.user.name;
    chat.chatStatus =
      this.translate.instant('order_id') +
      this.order.id +
      ' | ' +
      this.order.created_at;
    chat.myId = this.order.vendor.user_id + Constants.ROLE_VENDOR;
    this.loadChat(chat);
  }

  navChatDelivery() {
    this.chatChild = '';
    this.newMessageText = '';
    // var reference = firebase.database().ref();
    // this.chatRef = reference;
    // this.inboxRef = reference;
    this.messages = new Array<Message>();
    this.chatObj = new Chat();

    let chat = new Chat();
    chat.chatId =
      this.order.delivery.delivery.user.id + Constants.ROLE_DELIVERY;
    chat.chatImage = this.order.delivery.delivery.user.image_url;
    chat.chatName = this.order.delivery.delivery.user.name;
    chat.chatStatus = 'delivery_partner';
    chat.myId = this.order.vendor.user_id + Constants.ROLE_VENDOR;
    this.loadChat(chat);
  }

  // chat_widget_body() {
  //   $(".chat-widget-body").toggleClass("active");
  //   $("#chat-widget-body").toggleClass("active");
  // }

  //start tracking order
  loadTrackingMap() {
    if (!this.initialized) {
      let address = new MyAddress();
      address.latitude = this.order.vendor.latitude;
      address.longitude = this.order.vendor.longitude;
      let mapLoaded = this.maps
        .init(
          this.mapElement.nativeElement,
          this.pleaseConnect.nativeElement,
          address
        )
        .then(() => {
          this.initialized = true;
          this.plotMarkers();
          // this.registerUpdates();
        })
        .catch((err) => {
          console.log('maps.init', err);
        });
      mapLoaded.catch((err) => {
        console.log('mapLoaded', err);
      });
    }
  }
  private plotMarkers() {
    let posMe = new google.maps.LatLng(
      Number(this.order.vendor.latitude),
      Number(this.order.vendor.longitude)
    );
    let markerMe = new google.maps.Marker({
      position: posMe,
      map: this.maps.map,
      //, icon: 'assets/images/marker_map_me.png'
    });
    markerMe.addListener('click', (event) =>
      this.uiElementService.showSuccessToastr(this.order.vendor.name)
    );

    let posCustomer = new google.maps.LatLng(
      Number(this.order.address.latitude),
      Number(this.order.address.longitude)
    );
    let markerCustomer = createHTMLMapMarker({
      latlng: posCustomer,
      map: this.maps.map,
      html:
        '<div id="doctor_map"><img src="' +
        this.order.user.image_url +
        '"></div>',
    });
    markerCustomer.addListener('click', (event) =>
      this.uiElementService.showSuccessToastr(this.order.user.name)
    );

    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({
      map: this.maps.map,
      polylineOptions: {
        strokeColor: '#279411',
        strokeOpacity: 1.0,
        strokeWeight: 5,
      },
      markerOptions: {
        opacity: 0,
        clickable: false,
        position: markerCustomer,
      },
    });
    let dirReq: any = {
      origin: posMe,
      destination: posCustomer,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    directionsService.route(dirReq, function (result, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(result);
      }
    });

    if (
      this.order.delivery &&
      this.order.delivery.delivery.latitude &&
      this.order.delivery.delivery.longitude
    ) {
      this.posDeliveryGuy = new google.maps.LatLng(
        Number(this.order.delivery.delivery.latitude),
        Number(this.order.delivery.delivery.longitude)
      );
      this.markerDeliveryGuy = createHTMLMapMarker({
        latlng: this.posDeliveryGuy,
        map: this.maps.map,
        html:
          '<div id="doctor_map"><img src="' +
          this.order.delivery.delivery.user.image_url +
          '"></div>',
      });
      this.markerDeliveryGuy.addListener('click', (event) =>
        this.uiElementService.showSuccessToastr(
          this.order.delivery.delivery.user.name
        )
      );
    }
  }

  // private registerUpdates() {
  //   if (this.order.delivery && this.order.delivery.delivery.latitude && this.order.delivery.delivery.longitude) {
  //     //location updates
  //     this.updatesLocation();
  //     //chat updates
  //     this.updatesChat();
  //   }
  // }

  // private updatesLocation() {
  //   const component = this;
  //   firebase.database().ref("deliveries").child(String(this.order.delivery.delivery.id)).child("location").on('child_changed', function (data) {
  //     var fireLocation = data.val() as { latitude: string, longitude: string };
  //     if (fireLocation.latitude != null && fireLocation.longitude != null) component.onNewLocation(new google.maps.LatLng(Number(fireLocation.latitude), Number(fireLocation.longitude)));
  //   });
  // }
  // private onNewLocation(newPos: google.maps.LatLng) {
  //   if (!this.posDeliveryGuy || !newPos.equals(this.posDeliveryGuy)) {

  //     if (this.posDeliveryGuy != null) {
  //       this.i = 0;
  //       this.lastToPos[0] = this.posDeliveryGuy.lat();
  //       this.lastToPos[1] = this.posDeliveryGuy.lng();
  //       this.deltaLat = (newPos.lat() - this.lastToPos[0]) / this.numDeltas;
  //       this.deltaLng = (newPos.lng() - this.lastToPos[1]) / this.numDeltas;
  //     }

  //     if (this.markerDeliveryGuy == null) {
  //       this.markerDeliveryGuy = createHTMLMapMarker({
  //         latlng: this.posDeliveryGuy,
  //         map: this.maps.map,
  //         html: '<div id="doctor_map"><img src="' + this.order.delivery.delivery.user.image_url + '"></div>'
  //       });
  //       this.markerDeliveryGuy.addListener('click', (event) => this.uiElementService.showSuccessToastr(this.order.delivery.delivery.user.name));
  //     } else {
  //       //this.markerDeliveryGuy.setPosition(this.posDeliveryGuy);
  //       this.moveMarker();
  //     }
  //     this.maps.map.panTo(this.posDeliveryGuy);

  //   }
  // }

  // private moveMarker() {
  //   this.lastToPos[0] = this.lastToPos[0] + this.deltaLat;
  //   this.lastToPos[1] = this.lastToPos[1] + this.deltaLng;
  //   let newToPos = new google.maps.LatLng(Number(this.lastToPos[0]), Number(this.lastToPos[1]));
  //   this.markerDeliveryGuy.setPosition(newToPos);
  //   this.posDeliveryGuy = newToPos;
  //   if (this.i != this.numDeltas) {
  //     this.i++;
  //     // setTimeout(() => this.moveMarker(), this.delay);
  //   }
  //   //  else {
  //   //   this.requestDirection(this.lastTo);
  //   // }
  // }

  // end tracking order

  //start chat selction.
  loadChat(chat) {
    this.chatObj = chat;
    this.chatChild = Helper.getChatChild(
      this.chatObj.myId,
      this.chatObj.chatId
    );

    const component = this;
    this.inboxRef = firebase.database().ref(Constants.REF_INBOX);
    this.chatRef = firebase.database().ref(Constants.REF_CHAT);
    this.chatRef
      .child(this.chatChild)
      .limitToLast(20)
      .on(
        'child_added',
        function (snapshot, prevChildKey) {
          var newMessage = snapshot.val() as Message;
          if (newMessage) {
            newMessage.timeDiff = Helper.formatMillisDateTime(
              Number(newMessage.dateTimeStamp),
              Helper.getLocale()
            );
            component.addMessage(newMessage);
            component.markDelivered();
            component.scrollList();
          }
        },
        function (error) {
          console.error('child_added', error);
        }
      );

    firebase
      .database()
      .ref(Constants.REF_USERS_FCM_IDS)
      .child(this.chatObj.chatId)
      .once('value', function (snap) {
        component.userPlayerId = snap.val();
      });

    this.translate
      .get('just_moment')
      .subscribe((value) => this.uiElementService.showSuccessToastr(value));
  }

  scrollList() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.log('myScrollContainer: ', err);
      }
    }, 200);
  }

  markDelivered() {
    if (this.messages && this.messages.length) {
      if (
        this.messages[this.messages.length - 1].senderId != this.chatObj.myId
      ) {
        this.messages[this.messages.length - 1].delivered = true;
        this.chatRef
          .child(this.chatChild)
          .child(this.messages[this.messages.length - 1].id)
          .child('delivered')
          .set(true);
      }
      // else {
      //   let toNotify;
      //   if (!this.messages[this.messages.length - 1].delivered) {
      //     toNotify = this.messages[this.messages.length - 1];
      //     this.messages[this.messages.length - 1].delivered = true;
      //   }
      //   if (toNotify) {
      //     this.notifyMessages(toNotify);
      //   }
      // }
    }
  }

  addMessage(msg: Message) {
    this.messages = this.messages.concat(msg);
    //this.storage.set(Constants.KEY_MESSAGES + this.chatChild, this.messages);
    // if (this.chatObj && msg) {
    //   let isMeSender = msg.senderId == this.chatObj.myId;
    //   this.chatObj.chatImage = isMeSender ? msg.recipientImage : msg.senderImage;
    //   this.chatObj.chatName = isMeSender ? msg.recipientName : msg.senderName;
    //   this.chatObj.chatStatus = isMeSender ? msg.recipientStatus : msg.senderStatus;
    // }
  }

  send() {
    if (this.newMessageText && this.newMessageText.trim().length) {
      let toSend = new Message();
      toSend.chatId = this.chatChild;
      toSend.body = this.newMessageText;
      toSend.dateTimeStamp = String(new Date().getTime());
      toSend.delivered = false;
      toSend.sent = true;
      toSend.recipientId = this.chatObj.chatId;
      toSend.recipientImage = this.chatObj.chatImage;
      toSend.recipientName = this.chatObj.chatName;
      toSend.recipientStatus = this.chatObj.chatStatus;
      toSend.senderId = this.chatObj.myId;
      toSend.senderName = this.profile.user.name;
      toSend.senderImage =
        this.profile.user.image_url && this.profile.user.image_url.length
          ? this.profile.user.image_url
          : 'assets/images/empty_dp.png';
      toSend.senderStatus = this.profile.user.email;
      toSend.id = this.chatRef.child(this.chatChild).push().key;

      this.newMessageText = '';
      this.chatRef
        .child(this.chatChild)
        .child(toSend.id)
        .set(toSend)
        .then((res) => {
          this.inboxRef
            .child(toSend.recipientId)
            .child(toSend.senderId)
            .set(toSend);
          this.inboxRef
            .child(toSend.senderId)
            .child(toSend.recipientId)
            .set(toSend);
          this.notifyMessages();
        });
    } else {
      this.translate
        .get('type_message')
        .subscribe((value) => this.uiElementService.showErrorToastr(value));
    }
  }

  private notifyMessages() {
    this.apiService
      .postNotification(
        this.chatObj.chatStatus == 'delivery_partner'
          ? Constants.ROLE_DELIVERY
          : Constants.ROLE_USER,
        Number(this.chatObj.chatId)
          ? this.chatObj.chatId
          : this.chatObj.chatId.substring(
              0,
              this.chatObj.chatId.indexOf(
                this.chatObj.chatStatus == 'delivery_partner'
                  ? Constants.ROLE_DELIVERY
                  : Constants.ROLE_USER
              )
            )
      )
      .subscribe(
        (res) => console.log('notiS', res),
        (err) => console.log('notiF', err)
      );
  }
  //end chat selction.

  confirmUpdate(status) {
    let keyTitle =
      status == 'accepted' ? 'confirm_accept_title' : 'confirm_reject_title';
    let keyMessage =
      status == 'accepted'
        ? 'confirm_accept_message'
        : 'confirm_reject_message';
    this.translate
      .get([keyTitle, keyMessage, 'yes', 'no'])
      .subscribe((values) => {
        this.confirmationDialogService
          .confirm(
            values[keyTitle],
            values[keyMessage],
            values['yes'],
            values['no']
          )
          .then((confirmed) => {
            if (confirmed) this.updateOrderStatus(status);
          })
          .catch(() => {
            console.log('err');
          });
      });
  }

  checkAndupdateOrder() {
    this.translate
      .get(['just_moment', 'something_wrong'])
      .subscribe((values) => {
        this.uiElementService.presentLoading(values['just_moment']);
        this.subscriptions.push(
          this.apiService.getOrderById(this.order.id).subscribe(
            (res) => {
              this.order = res;
              this.uiElementService.dismissLoading();
              this.updateOrder();
            },
            (err) => {
              console.log('updateOrder', err);
              this.uiElementService.showErrorToastr(values['something_wrong']);
              this.uiElementService.dismissLoading();
            }
          )
        );
      });
  }

  private updateOrder() {
    let toUpdate = null;
    switch (this.order.status) {
      case 'new':
      case 'pending':
        toUpdate = 'accepted';
        break;
      // case "accepted":
      //   toUpdate = "preparing";
      //   break;
      // case "preparing":
      //   toUpdate = "prepared";
      //   break;
      case 'accepted':
        toUpdate = 'prepared';
        break;
      case 'prepared':
        if (this.order.order_type == 'normal') {
          if (this.order.delivery == null) {
            this.translate
              .get('delivery_na')
              .subscribe((value) =>
                this.uiElementService.showErrorToastr(value)
              );
          } else if (
            this.order.delivery.status == 'new' ||
            this.order.delivery.status == 'pending' ||
            this.order.delivery.status == 'allotted'
          ) {
            this.translate
              .get('delivery_left_na')
              .subscribe((value) =>
                this.uiElementService.showErrorToastr(value)
              );
          } else {
            toUpdate = 'dispatched';
          }
        } else {
          toUpdate = 'complete';
        }
        break;
    }
    if (toUpdate != null) this.updateOrderStatus(toUpdate);
  }
  updateOrderStatus(statusToUpdate) {
    this.translate.get(['updating', 'something_wrong']).subscribe((values) => {
      this.uiElementService.presentLoading(values['updating']);
      let updateRequest: any = { status: statusToUpdate };
      if (statusToUpdate == 'prepared' || statusToUpdate == 'complete') {
        let orderMeta = this.order.meta;
        orderMeta.product_ids_done = [];
        for (let pro of this.order.products)
          orderMeta.product_ids_done.push(pro.id);
        updateRequest.meta = JSON.stringify(orderMeta);
      }
      this.subscriptions.push(
        this.apiService.updateOrder(this.order.id, updateRequest).subscribe(
          (res) => {
            this.order = res;
            this.translate
              .get('order_status_message_' + res.status)
              .subscribe((value) =>
                this.uiElementService.showSuccessToastr(value)
              );

            let productIdsDone = [];
            for (let pro of this.order.products) productIdsDone.push(pro.id);
            if (
              this.myMenuItemsRef &&
              (this.order.status == 'prepared' ||
                this.order.status == 'complete')
            )
              this.myMenuItemsRef.child(String(this.order.id)).set({
                order_id: this.order.id,
                product_ids_done: productIdsDone,
              });
            if (
              this.myTableStatusRef &&
              this.order.status == 'complete' &&
              this.order.meta.table
            )
              this.myTableStatusRef
                .child(String(this.order.meta.table))
                .child('occupied')
                .set(false);

            this.addFireOrder(res);
            this.uiElementService.dismissLoading();
          },
          (err) => {
            console.log('updateOrder', err);
            this.uiElementService.showErrorToastr(values['something_wrong']);
            this.uiElementService.dismissLoading();
          }
        )
      );
    });
  }
  isOrderUpdateable(): boolean {
    let toReturn = true;
    if (this.order != null) {
      if (
        this.order.status == 'cancelled' ||
        this.order.status == 'refund' ||
        this.order.status == 'hold' ||
        this.order.status == 'rejected' ||
        this.order.status == 'failed' ||
        this.order.status == 'dispatched' ||
        this.order.status == 'intransit' ||
        this.order.status == 'complete'
      ) {
        toReturn = false;
      }
    }
    return toReturn;
  }

  addFireOrder(newOrder: Order) {
    let existingIndex = -1;
    if (this.orders.length) {
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].id == newOrder.id) {
          existingIndex = i;
          break;
        }
      }
    }
    this.apiService.setupOrder(newOrder);
    if (existingIndex == -1) {
      this.orders.unshift(newOrder);
    } else {
      this.orders[existingIndex] = newOrder;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BankDetail } from 'src/models/bank-detail.models';
import { BaseListResponse } from 'src/models/base-list.models';
import { Helper } from 'src/models/helper.models';
import { PayoutRequest } from 'src/models/payout-request.models';
import { WalletTransaction } from 'src/models/wallet-transaction.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';
declare var $: any;
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  private infiniteScrollEvent;
  private nextUrl: string;
  isLoading = true;
  transactions = new Array<WalletTransaction>();
  currency_icon: string;
  balance = 0;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;
  payourRequest = new PayoutRequest();

  constructor(private translate: TranslateService, private uiElementService: UiElementsService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.currency_icon = Helper.getSetting("currency_icon");
    this.refreshBalance();
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  refreshBalance() {
    this.subscriptions.push(this.apiService.getBalance().subscribe(res => {
      if (res.balance != this.balance || ((!this.transactions || !this.transactions.length) && res.balance != 0)) {
        this.transactions = [];
        this.isLoading = true;
        this.loadTransactions();
      } else {
        this.isLoading = false;
      }
      this.balance = res.balance;
    }, err => {
      console.log("getBalance", err);
      this.isLoading = false;
    }));
  }

  loadTransactions() {
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.getTransactions(this.pageNo).subscribe(res => this.handleTransactionRes(res), err => this.handleTransactionErr(err)));
    });
  }

  onChangePage(event) {
    this.pageNo = event; this.loadTransactions();
  }

  handleTransactionRes(res: BaseListResponse) {
    this.transactions = res.data;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.isLoading = false;
    this.uiElementService.dismissLoading();
  }

  handleTransactionErr(err) {
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }


  send_to_bank() {
    let bankDetail: BankDetail = Helper.getBankDetail();
    if (bankDetail) {
      this.payourRequest.bank_name = bankDetail.bank_name;
      this.payourRequest.bank_account_name = bankDetail.bank_account_name;
      this.payourRequest.bank_account_number = bankDetail.bank_account_number;
    }
    $('#add_people').modal({ backdrop: 'static', keyboard: false })
  }

  submitSendToBank() {
    if (!this.payourRequest.bank_name || !this.payourRequest.bank_name.length) {
      this.translate.get("err_field_bank_name").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.payourRequest.bank_account_name || !this.payourRequest.bank_account_name.length) {
      this.translate.get("err_field_bank_account_name").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.payourRequest.bank_account_number || !this.payourRequest.bank_account_number.length) {
      this.translate.get("err_field_bank_account_number").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.payourRequest.bank_code || !this.payourRequest.bank_code.length) {
      this.translate.get("err_field_bank_code").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.payourRequest.amount || (this.payourRequest.amount > this.balance || this.payourRequest.amount < 0)) {
      this.translate.get("err_field_amount").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else {
      this.translate.get(["just_moment", "something_wrong"]).subscribe(values => {
        this.uiElementService.presentLoading(values["just_moment"]);
        this.subscriptions.push(this.apiService.walletTransfer(this.payourRequest).subscribe(res => {
          Helper.setBankDetail({
            bank_name: this.payourRequest.bank_name,
            bank_account_name: this.payourRequest.bank_account_name,
            bank_account_number: this.payourRequest.bank_account_number
          });
          this.uiElementService.dismissLoading();
          this.dismissModel();
          this.refreshBalance();
        }, err => {
          console.log("walletTransfer", err);
          this.uiElementService.showErrorToastr(values["something_wrong"]);
          this.uiElementService.dismissLoading();
          this.dismissModel();
        }));
      });
    }
  }

  dismissModel() {
    $('#add_people').modal('hide');
  }
}

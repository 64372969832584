<div id="page_items">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="page_title">
            <div class="row align-items-center mx-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
                    <div class="title_inner d-flex">
                        <h1 class="d-flex align-items-center">{{'wallet_balance' | translate}}
                            <span class="ml-4">{{currency_icon}} {{balance}}</span>
                        </h1>
                        <button type="button" class="btn">
                            <a data-toggle="modal" data-target="#add_people">{{'send_to_bank' | translate}}</a>
                        </button>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
                    <form class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-3 mt-lg-0 pb-3 pb-md-0 ml-auto">
                        <div class="form-group d-flex">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" class="form-control" placeholder="Search">
                        </div>
                    </form>
                </div> -->
            </div>
        </div>

        <!-- Right Sidebar Start -->
        <div class="right_sidebar">
            <!-- Tab Content Start -->
            <div class="tab-content" id="nav-tabContent">

                <!-- Sales Tab Content Start -->
                <div class="order_list_container">
                    <!-- Order List Start -->
                    <div class="order_list">
                        <div class="list_header d-flex">
                            <h2 class="text-center order_num">{{'trans_src' | translate}}</h2>
                            <!-- <h2 class="text-left Name">{{'name' | translate}}</h2> -->
                            <h2 class="text-center Amount">{{'amount' | translate}}</h2>
                            <!-- <h2 class="text-center Items">{{'items' | translate}}</h2> -->
                            <h2 class="text-center Table">{{'type' | translate}}</h2>
                            <h2 class="text-center Date">{{'date_and_time'| translate}}</h2>
                            <!-- <h2 class="text-right Action">{{'action' | translate}}</h2> -->
                        </div>

                        <ul>
                            <li *ngFor="let transaction of transactions  | paginate : { itemsPerPage: pageSize, currentPage: pageNo, totalItems: pageTotal};let i = index" class="d-flex animate__animated animate__fadeInUp wow">
                                <h3 *ngIf="transaction.type=='deposit'" class="text-center order_num">{{ 'order_num' | translate }} {{transaction.meta.source_id}}</h3>
                                <h3 *ngIf="transaction.type!='deposit'" class="text-center order_num">{{ 'trans_id' | translate }} {{transaction.id}}</h3>
                                <!-- <h3 class="text-left Name"><strong>Jimmy Taylor</strong></h3> -->
                                <h3 class="text-center Amount">{{currency_icon}}{{transaction.amount}}</h3>
                                <!-- <h3 class="text-center Items">2</h3> -->
                                <h3 class="text-center Table">{{ ('trans_type_'+transaction.type) | translate }}</h3>
                                <h3 class="text-center Date">{{transaction.created_at}}</h3>
                                <div class="btn_container d-flex mr-0 ml-auto">

                                    <!-- <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-delete"></i></a>
                                    </button>
                                    <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-edit"></i></a>
                                    </button> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Order List End -->
                </div>
                <!-- Tab Footer start -->
                <div *ngIf="pageTotal > pageSize" class="tab_footer">
                    <div class="row no-gutter align-items-center">
                        <div class="col-12 col-md-12 col-lg-4 pb-3">
                            <h2>{{'showing' | translate}} {{pageNo}} {{'to' | translate}} {{pageSize}} {{'of' | translate}} {{pageTotal}} {{'item' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-12 col-lg-8 pb-3">
                            <div class="row align-items-center">
                                <nav class="navigation col-12" aria-label="Page navigation example">
                                    <pagination-controls responsive="true" [previousLabel]="'prev' | translate" [nextLabel]="'next' | translate" (pageChange)="onChangePage($event)">
                                    </pagination-controls>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Footer End -->
                <!-- Sales Tab Content End -->
                <!-- Tab Content End -->
            </div>
        </div>
        <!-- Right Sidebar End -->


        <!-- send to bank  Modal Start  -->
        <div class="modal fade add_category_model add_expenses receipt_model px-0" id="add_people" tabindex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header px-0">
                        <h2 class="col-11 mx-auto">{{'send_to_bank' | translate}} </h2>
                    </div>
                    <div class="modal-body p-0">
                        <form class="pt-2 pt-sm-2 pt-md-4 ">
                            <div class="col-11 mx-auto form_container">
                                <div class="form-group">
                                    <label>{{'bank_name' | translate}}</label>
                                    <input type="text" class="form-control" name="bank_name" placeholder="{{'enter_bank_name' | translate}}" [(ngModel)]="payourRequest.bank_name">
                                </div>
                                <div class="form-group">
                                    <label>{{'account_holder_name' | translate}}</label>
                                    <input type="text" class="form-control" name="bank_account_name" placeholder="{{'enter_account_holder_name' | translate}}" [(ngModel)]="payourRequest.bank_account_name">
                                </div>

                                <div class="form-group">
                                    <label>{{'account_number' | translate}}</label>
                                    <input type="text" class="form-control" name="bank_account_number" placeholder="{{'enter_account_number' | translate}}" [(ngModel)]="payourRequest.bank_account_number">
                                </div>
                                <div class="form-group">
                                    <label>{{'branch_code' | translate}}</label>
                                    <input type="text" class="form-control" name="bank_code" placeholder="{{'enter_branch_code' | translate}}" [(ngModel)]="payourRequest.bank_code">
                                </div>

                                <div class="form-group">
                                    <label>{{'amount_to_transfer' | translate}}</label>
                                    <input type="number" class="form-control" name="amount" placeholder="{{'enter_amount_to_transfer' | translate}}" [(ngModel)]="payourRequest.amount">
                                </div>
                            </div>

                            <div class="modal-footer">
                                <div class="row no-gutters w-100">
                                    <div class="col-6"> <button type="file" class="btn Cencel" (click)="dismissModel()"><a>{{'cencel' | translate}}</a></button></div>
                                    <div class="col-6">
                                        <button type="file" class="btn" (click)="submitSendToBank()">
                                            <a>{{'send_to_bank' | translate}}</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- send to bank Modal End  -->
</div>
<!-- Body Wrapper End -->
import * as moment from 'moment';
import { Category } from './category.models';
import { User } from './user.models';

export class Profile {
    id: number;
    name: string;
    tagline: string;
    user: User;
    user_id: number;
    ratings: number;
    is_verified: number;
    latitude: string;
    longitude: string;
    ratings_count: number;
    favourite_count: number;
    details: string;
    meta: any;
    mediaurls: { images: Array<any> };
    minimum_order: number;
    delivery_fee: number;
    area: string;
    address: string;
    categories: Array<Category>;
    product_categories: Array<Category>;

    image_urls: Array<string>;
    image: string;
    type: string;
    availability: Array<AvailabilityDateTime>;
    extra_delivery_charge: number;
    maximum_delivery_amount: number;
    exceeding_delivery_distance: number;
    exceeding_order_price: number;
    mile_0: number;
    mile_1: number;
    mile_2: number;
    mile_3: number;
    mile_4: number;
    mile_5: number;
    mile_6: number;
    mile_7: number;
    service_fees: number;
    maximum_delivery_distance:number;

    static getDefault(): Profile {
        let toReturn = new Profile();
        toReturn.categories = new Array<Category>();
        toReturn.user = new User();
        toReturn.availability = AvailabilityDateTime.getDefault();
        toReturn.meta = {
            // opening_time: '',
            // closing_time: '',
            green_time: '',
            yellow_time: '',
            red_time: '',
            tables: 0,
            table_booking: false,
            no_of_tables: 0,
            desk_app: false,
            review_app: false,
            emenu_app: false,
            kiosk_app: false,
            kitchecn_app: false,
            auto_accept_order_customer: false,
            auto_accept_order_kiosk: false,
            auto_accept_order_emenu: false,
            auto_accept_order_desk: false
        };
        return toReturn;
    }

    static getUpdateRequest(profile: Profile): ProfileUpateRequest {
        let pur = new ProfileUpateRequest();
        pur.name = profile.name;
        pur.details = profile.details ? profile.details : 'empty';
        pur.image_urls = profile.image_urls;
        pur.address = profile.address;
        pur.latitude = profile.latitude;
        pur.longitude = profile.longitude;
        pur.extra_delivery_charge = profile.extra_delivery_charge;
        pur.maximum_delivery_amount = profile.maximum_delivery_amount;
        pur.exceeding_delivery_distance = profile.exceeding_delivery_distance;
        pur.exceeding_order_price = profile.exceeding_order_price;
        pur.mile_0 = profile.mile_0;
        pur.mile_1 = profile.mile_1;
        pur.mile_2 = profile.mile_2;
        pur.mile_3 = profile.mile_3;
        pur.mile_4 = profile.mile_4;
        pur.mile_5 = profile.mile_5;
        pur.mile_6 = profile.mile_6;
        pur.mile_7 = profile.mile_7;
        pur.maximum_delivery_distance = profile.maximum_delivery_distance;
        pur.service_fees = profile.service_fees;


        pur.availability = new Array();
        for (let avail of profile.availability) if (avail.selected) pur.availability.push(AvailabilityDateTime.getRequest(avail));
        pur.meta = JSON.stringify(profile.meta);
        return pur;
    }
}
export class ProfileUpateRequest {
    name: string;
    details: string;
    address: string;
    latitude: string;
    longitude: string;
    image_urls: Array<string>;
    categories: Array<number>;
    extra_delivery_charge: number;
    exceeding_delivery_distance: number;
    maximum_delivery_amount: number;
    exceeding_order_price: number;

    availability: Array<{ days: string; from: string; to: string; }>;
    meta: string;
    mile_0: number;
    mile_1: number;
    mile_2: number;
    mile_3: number;
    mile_4: number;
    mile_5: number;
    mile_6: number;
    mile_7: number;
    maximum_delivery_distance:number;

    service_fees: number;
}

export class AvailabilityDateTime {
    days: string;
    from: string;
    to: string;
    selected: boolean;
    dateFromISO: string;
    dateToISO: string;

    constructor(days: string) {
        this.days = days;
        this.selected = false;
        this.setTime("07:00", "21:00");
    }

    setTime(timeFrom: string, timeTo: string) {
        // let momentStart = moment();
        // let momentReturn = moment();
        // let time_start_split = timeFrom.split(":");
        // momentStart.set({ hour: Number(time_start_split[0]), minute: Number(time_start_split[1]), second: 0, millisecond: 0 });
        // let time_return_split = timeTo.split(":");
        // momentReturn.set({ hour: Number(time_return_split[0]), minute: Number(time_return_split[1]), second: 0, millisecond: 0 });
        // this.dateFromISO = momentStart.format();
        // this.dateToISO = momentReturn.format();
        let time_start_split = timeFrom.split(":");
        let time_return_split = timeTo.split(":");
        this.dateFromISO = time_start_split[0] + ":" + time_start_split[1];
        this.dateToISO = time_return_split[0] + ":" + time_return_split[1];;
    }

    static getDefault(): Array<AvailabilityDateTime> {
        let toReturn = [
            new AvailabilityDateTime("mon"),
            new AvailabilityDateTime("tue"),
            new AvailabilityDateTime("wed"),
            new AvailabilityDateTime("thu"),
            new AvailabilityDateTime("fri"),
            new AvailabilityDateTime("sat"),
            new AvailabilityDateTime("sun")
        ];
        return toReturn;
    }

    static getRequest(adt: AvailabilityDateTime): { days: string; from: string; to: string; } {
        // let momentFromDate = moment(adt.dateFromISO);
        // let momentToDate = moment(adt.dateToISO);
        // return { days: adt.days, from: momentFromDate.format("HH:mm"), to: momentToDate.format("HH:mm") };
        return { days: adt.days, from: adt.dateFromISO, to: adt.dateToISO };
    }
}
<div id="transactions">
  <!-- Body Wrapper Start -->
  <div class="body_wrapper">
    <div class="page_title">
      <div class="row align-items-center mx-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
          <div class="title_inner d-flex">
            <h1 class="d-flex align-items-center">
              {{ "orders" | translate }}
              <!--
                            <span class="ml-4">
                                4.2 <i class="zmdi zmdi-star"></i>
                            </span>
-->
            </h1>
            <!--                        <button type="button" class="btn"><a data-toggle="modal" data-target="#add_expenses">Book New</a></button>-->
          </div>
        </div>
      </div>
    </div>

    <!-- Right Sidebar Start -->
    <div class="right_sidebar">
      <!-- Tab Content Start -->
      <div class="tab-content" id="nav-tabContent">
        <!-- Sales Tab Content Start -->
        <div class="order_list_container">
          <!-- Order List Start -->
          <div class="order_list">
            <div class="list_header d-flex">
              <h2 class="text-center order_num">
                {{ "order_num" | translate }}
              </h2>
              <h2 class="text-left Name">{{ "name_cust" | translate }}</h2>
              <h2 class="text-center Amount">{{ "amount" | translate }}</h2>
              <h2 class="text-center Items">{{ "items" | translate }}</h2>
              <h2 class="text-center Table">{{ "type" | translate }}</h2>
              <h2 class="text-center Payment">{{ "payment" | translate }}</h2>
              <h2 class="text-center Date">{{ "orderd_on" | translate }}</h2>
              <h2 class="text-right Action">{{ "action" | translate }}</h2>
            </div>
            <ul>
              <div
                class="empty-view animate__animated animate__fadeInUp wow"
                *ngIf="!isLoading && (!orders || !orders.length)"
              >
                <div style="text-align: center">
                  <img src="assets/images/plc_no_item.png" alt="no offers" />
                  <span style="color: #9e9e9e; font-weight: bold">
                    {{ "empty_orders" | translate }}
                  </span>
                </div>
              </div>
              <li
                *ngFor="
                  let order of orders
                    | paginate
                      : {
                          itemsPerPage: pageSize,
                          currentPage: pageNo,
                          totalItems: pageTotal
                        };
                  let i = index
                "
                class="d-flex animate__animated animate__fadeInUp wow"
              >
                <h3 class="text-center order_num">{{ order.id }}</h3>
                <h3 class="text-left Name">
                  <strong>{{
                    order.is_guest ? order.customer_name : order.user.name
                  }}</strong>
                </h3>
                <h3 class="text-center Amount">{{ order.total_toshow }}</h3>
                <h3 class="text-center Items">{{ order.products.length }}</h3>
                <h3 class="text-center Table">
                  {{ order.order_type | translate }}
                </h3>
                <!-- <h3 class="text-center Payment" *ngIf="order.payment.payment_method.slug == 'cod'">{{ (order.order_type == 'normal' ? 'pay_on_delivery' : 'pay_on_pickup') | translate}}</h3>
                                <h3 class="text-center Payment" *ngIf="order.payment.payment_method.slug != 'cod'">{{order.payment?.payment_method?.titl}}</h3> -->
                <h3 class="text-center Payment">
                  {{ order.payment?.payment_method.title }}
                </h3>
                <h3 class="text-center Date">{{ order.created_at }}</h3>
                <div class="btn_container d-flex mr-0 ml-auto">
                  <button type="button" class="btn">
                    <a (click)="orderDetails(order)"
                      ><i class="zmdi zmdi-eye"></i
                    ></a>
                  </button>
                  <!-- <button
                    *ngIf="config.printerConfig.enabled"
                    type="button"
                    class="btn"
                  >
                    <a (click)="printReceipt(order)"
                      ><i class="zmdi zmdi-print"></i
                    ></a>
                  </button> -->
                  <!-- <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-delete"></i></a>
                                    </button>
                                    <button type="button" class="btn">
                                        <a><i class="zmdi zmdi-edit"></i></a>
                                    </button> -->
                </div>
              </li>
            </ul>
          </div>
          <!-- Order List End -->
        </div>

        <!-- Tab Footer start -->
        <div *ngIf="pageTotal > pageSize" class="tab_footer">
          <div class="row no-gutter align-items-center">
            <div class="col-12 col-md-12 col-lg-4 pb-3">
              <h2>
                {{ "showing" | translate }} {{ pageNo }} {{ "to" | translate }}
                {{ pageSize }} {{ "of" | translate }} {{ pageTotal }}
                {{ "item" | translate }}
              </h2>
            </div>
            <div class="col-12 col-md-12 col-lg-8 pb-3">
              <div class="row align-items-center">
                <nav
                  class="navigation col-12"
                  aria-label="Page navigation example"
                >
                  <pagination-controls
                    responsive="true"
                    [previousLabel]="'prev' | translate"
                    [nextLabel]="'next' | translate"
                    (pageChange)="onChangePage($event)"
                  >
                  </pagination-controls>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab Footer End -->

        <!-- Sales Tab Content End -->
        <!-- Tab Content End -->
      </div>
    </div>
    <!-- Right Sidebar End -->
  </div>
  <!-- Body Wrapper End -->

  <!-- Receipt Modal Start  -->
  <div
    class="modal fade receipt_model px-0"
    id="receipt_model"
    tabindex="-1"
    role="dialog"
    aria-labelledby="receipt_modelTitle"
    aria-hidden="true"
  >
    <div id="content" class="modal-dialog modal-dialog-right" role="document">
      <div class="modal-content">
        <div class="row mx-0">
          <!-- Order Info Start  -->
          <div *ngIf="order" class="col-12 col-12 col-md-6 px-0">
            <div class="modal-header">
              <div class="customer_details w-100">
                <h2 class="w-100 d-flex align-items-center pb-2">
                  {{ order.is_guest ? order.customer_name : order.user.name }}
                  <!-- <a *ngIf="!order.is_guest" class="ml-auto d-block">
                    <i
                      class="zmdi zmdi-comment-alt-text"
                      (click)="openCustomerChat()"
                    ></i>
                    <i class="zmdi zmdi-close" (click)="openCustomerChat()"></i>
                  </a> -->
                </h2>
                <h3>
                  {{ "order_num" | translate }} {{ order.id }} |
                  {{ order.created_at }}
                </h3>
              </div>
            </div>

            <div class="modal-body p-0">
              <ul>
                <li class="d-flex list-header">
                  <!-- <h4>Q.</h4> -->
                  <h3>{{ "items" | translate }}</h3>
                  <h5 class="mr-0 ml-auto">{{ "price" | translate }}</h5>
                </li>

                <li *ngFor="let item of order.products" class="w-100">
                  <h4 class="d-flex align-items-center">
                    <span>{{ item.vendor_product.product.title }} </span>
                    <small>x{{ item.quantity }}</small>
                    <strong class="mr-0 ml-auto text-right">{{
                      item.vendor_product.sale_priceToShow
                        ? item.vendor_product.sale_priceToShow
                        : item.vendor_product.priceToShow
                    }}</strong>
                  </h4>
                  <h3
                    *ngFor="let choice of item.addon_choices"
                    class="d-flex addon_choices"
                  >
                    {{ choice.addon_choice.title }}
                    <!-- <span class="mr-0 ml-auto"
                                            *ngFor="let choice of item.addon_choices">{{currency_icon}}{{choice.addon_choice.price}}</span> -->
                    <span class="mr-0 ml-auto"
                      >{{ currency_icon }}{{ choice.addon_choice.price }}</span
                    >
                  </h3>
                </li>
              </ul>

              <div class="amount_details">
                <h3 *ngIf="order.subtotal_toshow" class="d-flex">
                  {{ "sub_total" | translate
                  }}<span class="ml-auto mr-0">{{
                    order.subtotal_toshow
                  }}</span>
                </h3>
                <h3 *ngIf="order.taxes_toshow" class="d-flex">
                  {{ "service_fee" | translate
                  }}<span class="ml-auto mr-0">{{ order.taxes_toshow }}</span>
                </h3>
                <h3 *ngIf="order.discount_toshow" class="d-flex">
                  {{ "discount_price" | translate
                  }}<span class="ml-auto mr-0">{{ order.discount_toshow }}</span>
                </h3>
                <h3 *ngIf="order.delivery_fee_toshow" class="d-flex">
                  {{ "delivery_fee" | translate
                  }}<span class="ml-auto mr-0">{{
                    order.delivery_fee_toshow
                  }}</span>
                </h3>
              </div>
              <div *ngIf="order.total_toshow" class="total_paid">
                <h3 class="d-flex align-items-center">
                  {{ "total_paid_in" | translate }}
                  <!-- <strong>{{order.payment?.payment_method?.titl || 'COD'}}</strong> <span class="ml-auto mr-0">{{order.total_toshow}}</span> -->
                  <strong>{{ order.payment?.payment_method?.titl }}</strong>
                  <span class="ml-auto mr-0">{{ order.total_toshow }}</span>
                </h3>
              </div>

              <div class="delivery_partner show_navigation w-100">
                <div
                  *ngIf="!order.is_guest"
                  class="delivery_partner_inner d-flex w-100"
                >
                  <div class="img_box center_img">
                    <img
                      *ngIf="
                        order.delivery != null &&
                        order.delivery.delivery != null
                      "
                      [src]="order.delivery.delivery.user.image_url"
                      class="crop_img"
                    />
                    <img
                      *ngIf="
                        !(
                          order.delivery != null &&
                          order.delivery.delivery != null
                        )
                      "
                      src="assets/images/empty_dp.png"
                      class="crop_img"
                    />
                    <!-- <img src="assets/images/img_profile.png" class="crop_img"> -->
                  </div>
                  <div class="text_box w-100">
                    <h2
                      *ngIf="
                        order.delivery != null &&
                        order.delivery.delivery != null
                      "
                    >
                      {{ order.delivery.delivery.user.name }}
                    </h2>
                    <h3
                      *ngIf="
                        order.delivery != null &&
                        order.delivery.delivery != null
                      "
                      class="d-flex w-100 align-items-center"
                    >
                      {{ "delivery_partner" | translate }}
                      <a class="ml-auto d-block" (click)="tracking()">
                        <i class="zmdi zmdi-navigation"></i>
                      </a>
                    </h3>

                    <h2
                      *ngIf="
                        !(
                          order.delivery != null &&
                          order.delivery.delivery != null
                        )
                      "
                    >
                      {{ "delivery_partner" | translate }}
                    </h2>
                    <h3
                      *ngIf="
                        !(
                          order.delivery != null &&
                          order.delivery.delivery != null
                        )
                      "
                      class="d-flex w-100 align-items-center"
                    >
                      {{ "not_assigned" | translate }}
                    </h3>
                    <!-- <h2>George Anderson</h2>
                                    <h3 class="d-flex w-100 align-items-center">
                                        Delivery partner assigned
                                        <a class="ml-auto d-block">
                                            <i class="zmdi zmdi-navigation"></i>
                                        </a>
                                    </h3> -->
                  </div>
                </div>

                <div
                  class="btn_box animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                >
                  <div
                    *ngIf="order.status != 'new'"
                    class="row no-gutters w-100"
                  >
                    <div class="col-12">
                      <button
                        type="file"
                        class="btn"
                        (click)="checkAndupdateOrder()"
                        [disabled]="!isOrderUpdateable()"
                      >
                        <a
                          *ngIf="
                            order.status == 'prepared' &&
                            order.order_type != 'normal'
                          "
                        >
                          {{ "order_status_action_mark_complete" | translate }}
                        </a>
                        <a
                          *ngIf="
                            !(
                              order.status == 'prepared' &&
                              order.order_type != 'normal'
                            )
                          "
                        >
                          {{
                            "order_status_action_" + order.status | translate
                          }}
                        </a>
                      </button>
                    </div>
                  </div>
                  <div
                    *ngIf="order.status == 'new'"
                    class="row no-gutters w-100"
                  >
                    <div class="col-6">
                      <button
                        type="file"
                        class="btn Cencel"
                        (click)="confirmUpdate('rejected')"
                      >
                        <a>{{
                          "order_status_action_new_reject" | translate
                        }}</a>
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        type="file"
                        class="btn"
                        (click)="checkAndupdateOrder()"
                      >
                        <a>{{ "order_status_action_new" | translate }}</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Order Info End  -->

          <!--  Caht With Delivery Partner Start  -->
          <div
            id="caht-with-delivery-partner"
            class="col-12 col-12 col-md-6 px-0"
          >
            <div class="chat_option">
              <div class="delivery_partner d-flex align-items-center">
                <div class="img_box center_img">
                  <img
                    *ngIf="
                      order &&
                      order.delivery != null &&
                      order.delivery.delivery != null
                    "
                    [src]="order.delivery.delivery.user.image_url"
                    class="crop_img"
                  />
                  <img
                    *ngIf="
                      !(
                        order &&
                        order.delivery != null &&
                        order.delivery.delivery != null
                      )
                    "
                    src="assets/images/empty_dp.png"
                    class="crop_img"
                  />
                </div>
                <div class="text_box w-100">
                  <h2
                    class="w-100 d-flex align-items-center pb-2"
                    *ngIf="
                      order &&
                      order.delivery != null &&
                      order.delivery.delivery != null
                    "
                  >
                    {{ order.delivery.delivery.user.name }}
                    <a class="ml-auto d-block">
                      <i
                        class="zmdi zmdi-comment-alt-text"
                        (click)="openDeliveryChat()"
                      ></i>
                      <i
                        class="zmdi zmdi-close"
                        (click)="openDeliveryChat()"
                      ></i>
                    </a>
                  </h2>
                </div>
              </div>

              <div class="map center_img">
                <div #map id="map" class="map"></div>
                <div #pleaseConnect id="please-connect"></div>
              </div>

              <div class="chat-widget-body">
                <div class="chat_container" #scrollMe>
                  <div class="row mx-0">
                    <div
                      *ngFor="let msg of messages"
                      [ngClass]="
                        chatObj.myId == msg.senderId
                          ? 'chat_box animate__animated animate__animated animate__zoomIn d-flex send'
                          : 'chat_box animate__animated animate__animated animate__zoomIn d-flex received'
                      "
                    >
                      <div class="chat">
                        <h2>{{ msg.body }}</h2>
                        <p>{{ msg.timeDiff }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <form>
                  <div
                    class="form-group d-flex align-items-center mb-0 animate__animated animate__fadeInUp wow"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="newMessageText"
                      [(ngModel)]="newMessageText"
                      placeholder="{{ 'type_message' | translate }}"
                      (keyup.enter)="send()"
                    />
                    <a
                      class="send_btn end"
                      style="cursor: pointer"
                      (click)="send()"
                    >
                      <i class="zmdi zmdi-mail-send"></i>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Chat With Delivery Partner End  -->

          <!--  Caht With Customer  Start  -->
          <div id="caht-with-customer" class="col-12 col-12 col-md-6 px-0">
            <div class="chat_option caht-with-customer">
              <div class="delivery_partner d-flex align-items-center">
                <div class="text_box w-100">
                  <h2 class="w-100">
                    {{ "message_to" | translate }}
                    <strong>{{ chatObj.chatName }}</strong>

                    <!--
                                        <a class="ml-auto d-block" >
                                            <i class="zmdi zmdi-comment-alt-text"></i>
                                        </a>
-->
                  </h2>
                </div>
              </div>

              <div class="chat-widget-body">
                <div class="chat_container" #scrollMe>
                  <div class="row mx-0">
                    <div
                      *ngFor="let msg of messages"
                      [ngClass]="
                        chatObj.myId == msg.senderId
                          ? 'chat_box animate__animated animate__animated animate__zoomIn d-flex send'
                          : 'chat_box animate__animated animate__animated animate__zoomIn d-flex received'
                      "
                    >
                      <div class="chat">
                        <h2>{{ msg.body }}</h2>
                        <p>{{ msg.timeDiff }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <form>
                  <div
                    class="form-group d-flex align-items-center mb-0 animate__animated animate__fadeInUp"
                  >
                    <input
                      type="text"
                      class="form-control"
                      name="newMessageText"
                      [(ngModel)]="newMessageText"
                      placeholder="{{ 'type_message' | translate }}"
                      (keyup.enter)="send()"
                    />
                    <a
                      class="send_btn end"
                      style="cursor: pointer"
                      (click)="send()"
                    >
                      <i class="zmdi zmdi-mail-send"></i>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- Chat With Customer  End  -->
        </div>
      </div>
    </div>
  </div>
  <!-- Receipt Modal End  -->

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
        <div class="modal-body">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="display: flex; justify-content: center"
          >
            New Order Found!
          </h5>
        </div>
        <div
          class="modal-footer"
          style="display: flex; justify-content: center"
        >
          <button
            style="width: 70px"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="dismiss()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    *ngIf="alertModule"
    class="modal fade px-0"
    id="alert-module"
    tabindex="-1"
    role="dialog"
    aria-labelledby="receipt_modelTitle"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Alert!</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="dismiss()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer">
        <button type="button" class="btn" (click)="dismiss()">OK</button>
      </div>
    </div>
  </div> -->
</div>

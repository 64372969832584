<div id="page_orders_status">
    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
        (scrolled)="onScrollDown()"></div>
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="empty-view animate__animated animate__fadeInUp wow"
            *ngIf="!isLoading && (!orders || !orders.length)">
            <div style="text-align:center">
                <img src="assets/images/plc_no_item.png" alt="no offers" />
                <span style="color:#9E9E9E; font-weight:bold;">
                    {{'empty_orders' | translate}}
                </span>
            </div>
        </div>

        <div class="order_container">
            <div *ngFor="let order of orders"
                [ngClass]="order.colorProgress == 'green_time' ? 'active2':(order.colorProgress == 'yellow_time' ? '':'active1')"
                class="order animate__animated animate__zoomIn wow">
                <div class="order_inner">
                    <div class="order_header d-flex align-items-center">
                        <h2> {{order.order_type | translate}}
                            <span>{{ 'order_num' | translate}} {{order.id}}</span>
                        </h2>
                        <h3 class="ml-auto">{{order.showOrderPalceTime}}</h3>
                    </div>
                    <div *ngFor="let product of order.products"
                        [ngClass]="product.done == true ? 'item order_done' : 'item'">
                        <h3 class="ion-text-start">{{product.quantity}}</h3>
                        <h4>
                            {{product?.vendor_product.product.title}}
                            <span *ngFor="let choice of product.addon_choices">{{choice.addon_choice.title}}</span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Body Wrapper End -->
</div>
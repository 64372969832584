<div id="page_support">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="row mx-0">
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 px-0">
                <div class="page_title">
                    <div class="row align-items-center mx-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                            <div class="d-flex">
                                <h1>{{'support' | translate}}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="banner col-10 col-md-10 col-lg-10 col-xl-7 mx-auto animate__animated animate__zoomIn wow"
                    data-wow-duration=".5s">
                    <img src="assets/images/support.png" class="img-fluid">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-7 px-0">
                <div class="card p-4 px-3 border-0 rounded-0">
                    <div class="col-12 col-sm-12 col-md-11 mx-auto px-0">
                        <h2 class="animate__animated animate__fadeInUp wow">{{'write_us_your_query' | translate}}</h2>
                        <p class="animate__animated animate__fadeInUp wow">
                            {{'wel_get_back_to_you_soon' | translate}}
                        </p>


                        <form>
                            <!-- <div class="form-group  animate__animated animate__fadeInUp wow">
                                <label>{{'phone_number' | translate}}</label>
                                <input type="text" class="form-control" placeholder="" required="" name="mobile" [(ngModel)]="supportRequest.mobile_number">
                            </div> -->

                            <div class="form-group  animate__animated animate__fadeInUp wow">
                                <label>{{'your_message' | translate}}</label>
                                <textarea rows="5" class="form-control py-3" name="message"
                                    [(ngModel)]="supportRequest.message"
                                    placeholder="{{'write_your_message' | translate}}"></textarea>
                            </div>
                        </form>
                        <button type="submit"
                            class="btn rounded-pill py-3 col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4  mt-5 d-block animate__animated animate__fadeInUp wow"
                            data-wow-duration="1s" (click)="submit()">{{'submit' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
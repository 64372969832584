<div id="page_settings">

  <!-- Body Wrapper Start -->
  <div class="body_wrapper container-fluid">
      <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="card p-3 p-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3">{{'settings' | translate}}</h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <div class="row pb-5 animate__animated animate__fadeInUp wow">
                          <div class="col-12 col-sm-5 col-md-3 col-lg-4 col-xl-3 pb-3 pb-sm-0">
                              <!--
                              <div class="img_box center_img">
                                  <i class="zmdi zmdi-image-alt"></i>
                                  <p>Upload Icon</p>
                              </div>
-->
                              <div *ngIf="profile && profile.image_urls && profile.image_urls.length"
                                  class="img_box center_img">
                                  <img [src]="profile.image_urls[0]" class="crop_img">
                              </div>
                              <div *ngIf="!profile || !profile.image_urls || !profile.image_urls.length"
                                  class="img_box center_img">
                                  <img src="assets/images/plc_seller_profile.png" class="crop_img">
                              </div>
                          </div>
                          <div class="col-12 col-sm-7 col-md-4 col-lg-8">
                              <div class="d-flex info pt-2">
                                  <i class="zmdi zmdi-info-outline d-block text-white"></i>
                                  <p [innerHTML]="'settings_text' | translate"></p>
                              </div>
                          </div>
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'name' | translate}}</label>
                          <input type="text" class="form-control" name="name" [(ngModel)]="profile.name">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'phone_number' | translate}}</label>
                          <input type="text" class="form-control" name="mobile_number"
                              [(ngModel)]="profile.user.mobile_number" readonly>
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'email_address' | translate}}</label>
                          <input type="email" class="form-control" name="email" [(ngModel)]="profile.user.email"
                              readonly>
                      </div>

                      <!-- <h3 class="pt-4">{{'bank_details' | translate}}</h3>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'bank_name' | translate}}</label>
                          <input type="text" class="form-control" name="bank_name"
                              placeholder="{{'enter_bank_name' | translate}}" [(ngModel)]="payourRequest.bank_name">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'account_holder_name' | translate}}</label>
                          <input type="text" class="form-control" name="bank_account_name"
                              placeholder="{{'enter_account_holder_name' | translate}}"
                              [(ngModel)]="payourRequest.bank_account_name">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'account_number' | translate}}</label>
                          <input type="text" class="form-control" name="bank_account_number"
                              placeholder="{{'enter_account_number' | translate}}"
                              [(ngModel)]="payourRequest.bank_account_number">
                      </div> -->
                      <h3 class="pt-4">Delivery (Calculations)</h3>

                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'extra_delivery_charge' | translate}}</label>
                          <input type="text" class="form-control" name="extra_delivery_charge"
                              placeholder="{{'extra_delivery_charge' | translate}}" [(ngModel)]="profile.extra_delivery_charge">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'exceeding_delivery_distance' | translate}}</label>
                          <input type="text" class="form-control" name="exceeding_delivery_distance"
                              placeholder="{{'exceeding_delivery_distance' | translate}})" [(ngModel)]="profile.exceeding_delivery_distance">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'max_delivery_amount' | translate}}</label>
                          <input type="text" class="form-control" name="maximum_delivery_amount"
                              placeholder="{{'max_delivery_amount' | translate}}" [(ngModel)]="profile.maximum_delivery_amount">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'maximum_delivery_distance' | translate}}</label>
                          <input type="text" class="form-control" name="maximum_delivery_distance"
                              placeholder="{{'maximum_delivery_distance' | translate}}" [(ngModel)]="profile.maximum_delivery_distance">
                      </div>

                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_0' | translate}}</label>
                          <input type="text" class="form-control" name="mile_0"
                              placeholder="Enter Fixed Amount for Mile 0 to 1(£)" [(ngModel)]="profile.mile_0">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_1' | translate}}</label>
                          <input type="text" class="form-control" name="mile_1"
                              placeholder="Enter Fixed Amount for Mile 1 to 2(£)" [(ngModel)]="profile.mile_1">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_2' | translate}}</label>
                          <input type="text" class="form-control" name="mile_2"
                              placeholder="Enter Fixed Amount for Mile 2 to 3(£)" [(ngModel)]="profile.mile_2">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_3' | translate}}</label>
                          <input type="text" class="form-control" name="mile_3"
                              placeholder="Enter Fixed Amount for Mile 3 to 4(£)" [(ngModel)]="profile.mile_3">
                      </div>

                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_4' | translate}}</label>
                          <input type="text" class="form-control" name="mile_4"
                              placeholder="Enter Fixed Amount for Mile 4 and above(£)" [(ngModel)]="profile.mile_4">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_5' | translate}}</label>
                          <input type="text" class="form-control" name="mile_5"
                              placeholder="{{'mile_5' | translate}}" [(ngModel)]="profile.mile_5">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_6' | translate}}</label>
                          <input type="text" class="form-control" name="mile_6"
                              placeholder="{{'mile_6' | translate}}" [(ngModel)]="profile.mile_6">
                      </div>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'mile_7' | translate}}</label>
                          <input type="text" class="form-control" name="mile_7"
                              placeholder="{{'mile_7' | translate}}" [(ngModel)]="profile.mile_7">
                      </div>
                      <h3 class="pt-4">Service Fees (Calculations)</h3>
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'service_fees' | translate}}</label>
                          <input type="text" class="form-control" name="service_fees"
                              placeholder="{{'service_fees' | translate}}" [(ngModel)]="profile.service_fees">
                              <br>
                              <span>(user amount of items * our defined service fees) / 100;</span>
                      </div>

                  </form>
              </div>
              <div class="card pt-3 pt-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3 mx-xl-4 pb-4">{{'restaurant_location' | translate}}</h3>
                  <div class="map_box">
                      <div class="page_title">
                          <div class="row  mx-0">
                              <form class="search_box col-11 mx-auto px-0">
                                  <div class="form-group d-flex">
                                      <div class="input-group-prepend">
                                          <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                                      </div>
                                      <input type="text" class="form-control" name="query" [(ngModel)]="query"
                                          (keyup)="searchPlace()" placeholder="{{'search_location' | translate}}">
                                      <div class="input-group-prepend">
                                          <div class="input-group-text"><i class="zmdi zmdi-gps-dot"
                                                  (click)="detect()"></i></div>
                                      </div>

                                  </div>
                              </form>
                          </div>
                      </div>
                      <div class="mapouter">
                          <div class="gmap_canvas">
                              <div class="saved_address" *ngIf="places && places.length">
                                  <div *ngFor="let place of places" class="address_box d-flex"
                                      (click)="selectPlace(place)">
                                      <!-- <img src="assets/imgs/ic_home.png"> -->
                                      <div class="text_box">
                                          <!-- <h3>Home</h3> -->
                                          <p>{{place.description}}</p>
                                      </div>
                                  </div>
                              </div>
                              <div style="height: 302px;" #map id="map" class="map">
                                  <!-- <ion-spinner></ion-spinner> -->
                              </div>
                              <div #pleaseConnect id="please-connect">
                                  <!-- <p>{{'loading' | translate}}</p> -->
                              </div>
                              <!-- <agm-map style="height: 302px;" id="gmap_canvas" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                  <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                    ></agm-marker>
                                </agm-map> -->
                              <!-- <iframe width="100%" height="500" id="gmap_canvas"
                                  src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                  frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br> -->
                          </div>
                      </div>
                  </div>
              </div>

              <div class="card restaurant_timings p-3 p-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3">{{'restaurant_timings' | translate}}</h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <!-- <div class="row animate__animated animate__fadeInUp wow">
                          <div class="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                              <div class="form-group">
                                  <label>{{'opening_time' | translate}}</label>
                                  <div class="select_box d-flex">
                                      <ngb-timepicker name="opening_time" [(ngModel)]="opening_time">
                                      </ngb-timepicker>

                                      <input mb-0 class="form-control" (click)="opening_time()" placeholder="{{'select_opening_time' | translate}}" id="opening-time" name="opening_time" [(ngModel)]="profile.meta.opening_time">
                                      <i class="zmdi zmdi-time" style="font-size: 1.3rem; top: 10px;"></i>
                                  </div>
                              </div>
                          </div>

                          <div class="col-12 col-lg-6 col-md-6 col-sm-12 my-0">
                              <div class="form-group">
                                  <label>{{'closing_time' | translate}}</label>
                                  <div class="select_box d-flex">
                                      <ngb-timepicker name="closing_time" [(ngModel)]="closing_time">
                                      </ngb-timepicker>

                                       <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="profile.meta.closing_time" ngbDatepicker #d="ngbDatepicker">
                                      <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                      </div> -->
                      <!-- <input mb-0 class="form-control" (click)="closing_time()" placeholder="{{'select_closing_time' | translate}}" id="closing-time" name="closing_time" [(ngModel)]="profile.meta.closing_time">
                                      <i class="zmdi zmdi-time" style="font-size: 1.3rem; top: 10px;"></i>
                                  </div>
                              </div>
                          </div>
                      </div> -->

                      <div *ngFor="let avail of profile.availability; let i = index"
                          class="row align-items-center animate__animated animate__fadeInUp wow"
                          style="margin-bottom: 20px;">
                          <div class="col-3 col-md-4">
                              <div class="custom-control custom-checkbox d-flex px-0">
                                  <div class="form-check form-group d-flex align-items-center w-100">
                                      <input class="custom-control-input" style="cursor: pointer;" type="checkbox"
                                          id="_{{i}}" name="checkbox_{{i}}" [(ngModel)]="avail.selected">
                                      <label class="custom-control-label w-100" style="cursor: pointer;" for="_{{i}}">
                                          <span class="align-items-center d-flex w-100">{{avail.days |
                                              translate}}</span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-9 col-md-8 px-0">
                              <div class="time_box d-flex align-items-center">
                                  <div class="form-group animate__animated animate__fadeInUp wow">
                                      <input type="time" name="from_{{i}}" [disabled]="!avail.selected"
                                          [(ngModel)]="avail.dateFromISO">
                                  </div>
                                  <h4 class="px-2 px-sm-2 px-lg-3 m-0">to</h4>
                                  <div class="form-group animate__animated animate__fadeInUp wow">
                                      <input type="time" name="to_{{i}}" [disabled]="!avail.selected"
                                          [(ngModel)]="avail.dateToISO">
                                  </div>
                              </div>
                          </div>
                      </div>

                  </form>
              </div>


              <div class="card p-3 p-xl-4  animate__animated animate__fadeInUp wow">
                  <h3 class="px-2 px-sm-2 px-lg-3  animate__animated animate__fadeInUp wow">
                      {{'auto_accept_order' | translate}}
                  </h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <div class="form-group d-flex animate__animated animate__fadeInUp wow">
                          <label>{{'customer_app' | translate}}</label>
                          <div class="toggle_box align-items-center d-flex ml-auto">
                              <label class="switch mb-0">
                                  <input type="checkbox" name="customer_app"
                                      [(ngModel)]="profile.meta.auto_accept_order_customer">
                                  <span class="slider round"></span>
                              </label>
                          </div>
                      </div>

                      <div class="form-group d-flex animate__animated animate__fadeInUp wow">
                          <label>{{'kiosk_app' | translate}}</label>
                          <div class="toggle_box align-items-center d-flex ml-auto">
                              <label class="switch mb-0">
                                  <input type="checkbox" name="kiosk_app"
                                      [(ngModel)]="profile.meta.auto_accept_order_kiosk">
                                  <span class="slider round"></span>
                              </label>
                          </div>
                      </div>

                      <div class="form-group d-flex animate__animated animate__fadeInUp wow">
                          <label>{{'emenu_app' | translate}}</label>
                          <div class="toggle_box align-items-center d-flex ml-auto">
                              <label class="switch mb-0">
                                  <input type="checkbox" name="emenu_app"
                                      [(ngModel)]="profile.meta.auto_accept_order_emenu">
                                  <span class="slider round"></span>
                              </label>
                          </div>
                      </div>
                  </form>
              </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="card order_color_setting p-3 p-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3">{{'order_color_setting' | translate}}</h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <div class="form-group d-flex align-items-center animate__animated animate__fadeInUp wow">
                          <i class="zmdi zmdi-circle" style="color: var(--green);"></i>
                          <label>Green</label>
                          <div class="select_box d-flex w-100">
                              <select mb-0 class="form-control col-12 col-md-12" style="padding-left: 17px;"
                                  name="green_time" [(ngModel)]="profile.meta.green_time">
                                  <option *ngFor="let time of apiService.times" [value]="time.value"
                                      [selected]="time.value == profile.meta.green_time">
                                      {{time.key}}
                                  </option>
                              </select>
                              <i class="zmdi zmdi-time mx-1"></i>
                          </div>
                      </div>

                      <div class="form-group d-flex align-items-center animate__animated animate__fadeInUp wow">
                          <i class="zmdi zmdi-circle" style="color: var(--primary);"></i>
                          <label>Yellow</label>
                          <div class="select_box d-flex w-100">
                              <select mb-0 class="form-control col-12 col-md-12" style="padding-left: 17px;"
                                  name="yellow_time" [(ngModel)]="profile.meta.yellow_time">
                                  <option *ngFor="let time of apiService.times" [value]="time.value"
                                      [selected]="time.value == profile.meta.yellow_time">
                                      {{time.key}}
                                  </option>
                              </select>
                              <i class="zmdi zmdi-time mx-1"></i>
                          </div>
                      </div>

                      <div class="form-group d-flex align-items-center animate__animated animate__fadeInUp wow">
                          <i class="zmdi zmdi-circle" style="color: var(--red);"></i>
                          <label>Red</label>
                          <div class="select_box d-flex w-100">
                              <select mb-0 class="form-control col-12 col-md-12 " style="padding-left: 17px;"
                                  name="red_time" [(ngModel)]="profile.meta.red_time">
                                  <option *ngFor="let time of apiService.times" [value]="time.value"
                                      [selected]="time.value == profile.meta.red_time">
                                      {{time.key}}
                                  </option>
                              </select>
                              <i class="zmdi zmdi-time mx-1"></i>
                          </div>
                      </div>
                  </form>
              </div>


              <div class="card qr_code order_color_setting p-3 p-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3  animate__animated animate__fadeInUp wow">
                      {{'store_category' | translate}}
                  </h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <div class="form-group  animate__animated animate__fadeInUp wow">
                          <ng-select multiple="true" name="store_category" [(ngModel)]="categoriesSelectedStrings"
                              (change)="onCategoriesChange()">
                              <ng-option *ngFor="let cat of categoryStrings" [value]="cat">{{cat}}</ng-option>
                          </ng-select>
                      </div>
                  </form>
              </div>

              <div class="card qr_code order_color_setting p-3 p-xl-4">
                  <h3 class="px-2 px-sm-2 px-lg-3 animate__animated animate__fadeInUp wow"
                      style="padding-bottom: 23px !important;">
                      {{'qr_code' | translate}}
                  </h3>
                  <form class="px-2 px-sm-2 px-lg-3">
                      <div class="form-group mb-0  animate__animated animate__fadeInUp wow">
                          <label style="padding-bottom: 25px;">
                              {{'generate_qr_code_to_connect_to_customer_user_menu' | translate}}
                          </label>
                      </div>
                      <h3 class="download_now d-flex  animate__animated animate__fadeInUp wow" (click)="qrShow()">
                          <i class="zmdi zmdi-download"></i> {{'download_now' | translate}}
                      </h3>

                  </form>
              </div>

              <div *ngIf="config.printerConfig.enabled"
                  class="card p-3 p-xl-4 animate__animated animate__fadeInUp wow">
                  <h3 class="px-2 px-sm-2 px-lg-3">{{'printer_config' | translate}}</h3>
                  <form class=" px-2 px-sm-2 px-lg-3">
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'printer_config_name' | translate}}</label>
                          <input type="text" class="form-control" name="printer_config_name"
                              [value]="networkConfig.name" [(ngModel)]="networkConfig.name">
                      </div>

                      <div class="row">
                          <div class="col-12 col-lg-6">
                              <div class="form-group animate__animated animate__fadeInUp wow">
                                  <label>{{'printer_config_host' | translate}}</label>
                                  <input type="text" class="form-control" name="printer_config_host"
                                      placeholder="127.0.0.1" [value]="networkConfig.host"
                                      [(ngModel)]="networkConfig.host">
                              </div>
                          </div>
                          <div class="col-12 col-lg-6">
                              <div class="form-group animate__animated animate__fadeInUp wow">
                                  <label>{{'printer_config_port' | translate}}</label>
                                  <input type="text" class="form-control" name="printer_config_port"
                                      placeholder="9100" [value]="networkConfig.port"
                                      [(ngModel)]="networkConfig.port">
                              </div>
                          </div>
                      </div>
                  </form>
              </div>

              <div class="card p-3 p-xl-4 animate__animated animate__fadeInUp wow">
                  <h3 class="px-2 px-sm-2 px-lg-3">{{'table_booking_enable' | translate}}</h3>
                  <!-- <div class="toggle_box align-items-center d-flex ml-auto">
                      <label class="switch mb-0"></label>
                      <input type="text" name="table_booking" value="table_booking" id="table_booking" [checked]="profile.meta.table_booking" (click)="onCheckboxClick('table_booking')">
                  </div> -->
                  <form class=" px-2 px-sm-2 px-lg-3">
                      <div class="form-group animate__animated animate__fadeInUp wow">
                          <label>{{'number_of_tables_available' | translate}}</label>
                          <input type="number" class="form-control" name="no_of_tables"
                              placeholder="{{'number_of_tables_available' | translate}}"
                              [value]="profile.meta.no_of_tables" [(ngModel)]="profile.meta.no_of_tables">
                      </div>
                  </form>
              </div>

              <div class="card px-0 animate__animated animate__fadeInUp wow">
                  <div class="card_inner review_app p-3 p-xl-4" style="border-bottom: 1.5px solid var(--bg-color);">
                      <h3 class="px-2 px-sm-2 px-lg-3 d-flex align-items-center">{{'review_app' | translate}}
                          <div class="toggle_box align-items-center d-flex ml-auto">
                              <label class="switch mb-0">
                                  <input type="checkbox" name="review_app" value="review_app" id="review_app"
                                      [checked]="profile.meta.review_app" (click)="onCheckboxClick('review_app')">
                                  <span class="slider round"></span>
                              </label>
                          </div>
                      </h3>

                      <form *ngIf="profile.meta.review_app && feedbackMatter" class="px-2 px-sm-2 px-lg-3">
                          <div *ngFor="let question of feedbackMatter.questions; let i = index"
                              class="form-group animate__animated animate__fadeInUp wow">
                              <label>{{'qestion' | translate}} {{i+1}}</label>
                              <input type="text" class="form-control" [name]="'question_'+i" [id]="'question_'+i"
                                  [value]="question.title" [(ngModel)]="question.title">
                          </div>
                          <!-- <button class="btn btn-outline-primary col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                              type="button">
                              <span>+ {{'add_more' | translate}}</span>
                          </button> -->
                      </form>
                  </div>

                  <div *ngIf="profile.meta.review_app && feedbackMatter"
                      class="card_inner set_options p-3 p-xl-4 pt-0 pt-xl-0">
                      <form class="px-2 px-sm-2 px-lg-3">
                          <div class="form-group animate__animated animate__fadeInUp wow">
                              <label>{{'set_options' | translate}}</label>
                          </div>
                          <div class="row">
                              <div *ngFor="let option of feedbackMatter.options; let i = index"
                                  class="form-group d-flex align-items-center animate__animated animate__fadeInUp wow col-12 col-md-6">
                                  <div class="img_box">
                                      <img *ngIf="option.rank == 1" src="assets/images/loved_it.gif">
                                      <img *ngIf="option.rank == 2" src="assets/images/good.gif">
                                      <img *ngIf="option.rank == 3" src="assets/images/average.gif">
                                      <img *ngIf="option.rank == 4" src="assets/images/not_good.gif">
                                  </div>
                                  <input type="text" class="form-control" [name]="'option_'+i" [id]="'option_'+i"
                                      [value]="option.title" [(ngModel)]="option.title">
                              </div>
                          </div>
                      </form>
                  </div>
              </div>

              <div class="card order_color_setting p-3 p-xl-4  animate__animated animate__fadeInUp wow">
                  <div class="d-flex align-items-center pr-3 px-2 px-sm-2 px-lg-3">
                      <h3 class="p-0">{{'kitchen_app' | translate}}</h3>
                      <div class="toggle_box align-items-center d-flex ml-auto">
                          <label class="switch mb-0">
                              <input type="checkbox" name="kitchecn_app" value="kitchecn_app" id="kitchecn_app"
                                  [checked]="profile.meta.kitchecn_app" (click)="onCheckboxClick('kitchecn_app')">
                              <span class="slider round"></span>
                          </label>
                      </div>
                  </div>
              </div>

              <div class="card order_color_setting p-3 p-xl-4  animate__animated animate__fadeInUp wow">
                  <div class="d-flex align-items-center pr-3 px-2 px-sm-2 px-lg-3">
                      <h3 class="p-0">{{'kiosk_app' | translate}}</h3>
                      <div class="toggle_box align-items-center d-flex ml-auto">
                          <label class="switch mb-0">
                              <input type="checkbox" name="kiosk_app" value="kiosk_app" id="kiosk_app"
                                  [checked]="profile.meta.kiosk_app" (click)="onCheckboxClick('kiosk_app')">
                              <span class="slider round"></span>
                          </label>
                      </div>
                  </div>
              </div>

              <div class="card order_color_setting p-3 p-xl-4  animate__animated animate__fadeInUp wow">
                  <div class="d-flex align-items-center pr-3 px-2 px-sm-2 px-lg-3">
                      <h3 class="p-0">{{'emenu_app' | translate}}</h3>
                      <div class="toggle_box align-items-center d-flex ml-auto">
                          <label class="switch mb-0">
                              <input type="checkbox" name="emenu_app" value="emenu_app" id="emenu_app"
                                  [checked]="profile.meta.emenu_app" (click)="onCheckboxClick('emenu_app')">
                              <span class="slider round"></span>
                          </label>
                      </div>
                  </div>
              </div>

              <!-- <div class="card order_color_setting p-3 p-xl-4  animate__animated animate__fadeInUp wow">
                  <div class="d-flex align-items-center pr-3">
                      <h3 class="p-0 pb-2 pt-1">{{'user_app' | translate}}</h3>
                      <div class="toggle_box align-items-center d-flex ml-auto">
                          <label class="switch mb-0">
                              <input type="checkbox">
                              <span class="slider round"></span>
                          </label>
                      </div>
                  </div>
              </div> -->

              <button type="submit"
                  class="btn rounded-pill py-3 col-12 col-sm-10 col-md-6 col-lg-6 col-xl-4 ml-auto d-block  animate__animated animate__fadeInUp wow"
                  (click)="update()">
                  {{'save' | translate}}
              </button>

          </div>
      </div>
  </div>
</div>

<div class="qr_code_popup" [ngClass]="qrValue ? 'active' : ''">
  <div class="qr_code_popup_inner animate__animated animate__zoomIn wow" data-wow-duration=".3s">
      <div class="popup-close-button" (click)="qrShow()">
          <i class="zmdi zmdi-close"></i>
      </div>
      <div #qr_container id="qr_container" *ngIf="qrValue" class="qr_code_img">
          <qrcode [qrdata]="qrValue" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
          <img src="assets/images/qr_background.png" class="qr_background">
      </div>
      <div id="download">
          <img #canvas>
          <a #downloadLink></a>
      </div>

      <button type="submit" class="btn rounded-pill py-3" (click)="downloadImage()">
          <i class="zmdi zmdi-download"></i>
          {{'save_qr' | translate}}
      </button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { BookingComponent } from './booking/booking.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ItemsComponent } from './items/items.component';
import { LoginComponent } from './login/login.component';
import { OrdersStatusComponent } from './orders-status/orders-status.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PeopleComponent } from './people/people.component';
import { PrintReciptComponent } from './print-recipt/print-recipt.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { AuthGuard } from './services/authguard/auth.guard';
import { SettingComponent } from './setting/setting.component';
import { SupportComponent } from './support/support.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UploadItemComponent } from './upload-item/upload-item.component';
import { VerificationComponent } from './verification/verification.component';
import { WalletComponent } from './wallet/wallet.component';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent, canActivate: [AuthGuard] },
  { path: "verification", component: VerificationComponent, canActivate: [AuthGuard] },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "booking", component: BookingComponent, canActivate: [AuthGuard] },
  { path: "authentication", component: AuthenticationComponent, canActivate: [AuthGuard] },
  { path: "items", component: ItemsComponent, canActivate: [AuthGuard] },
  { path: "orders_status", component: OrdersStatusComponent, canActivate: [AuthGuard] },
  { path: "people", component: PeopleComponent, canActivate: [AuthGuard] },
  { path: "reviews", component: ReviewsComponent, canActivate: [AuthGuard] },
  { path: "setting", component: SettingComponent, canActivate: [AuthGuard] },
  { path: "support", component: SupportComponent, canActivate: [AuthGuard] },
  { path: "terms_conditions", component: TermsConditionsComponent, canActivate: [AuthGuard] },
  { path: "transactions", component: TransactionsComponent, canActivate: [AuthGuard] },
  { path: "upload_item", component: UploadItemComponent, canActivate: [AuthGuard] },
  { path: "duplicate_item/:id", component: UploadItemComponent, canActivate: [AuthGuard] },
  { path: "wallet", component: WalletComponent, canActivate: [AuthGuard] },
  { path: "print_recipt/:id", component: PrintReciptComponent, canActivate: [AuthGuard] },
  {path:'addons',loadChildren:() => import('./addons/addons.module')
  .then(m => m.AddonsModule)},
  { path: "**", component: PageNotFoundComponent,canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Constants } from 'src/models/constants.models';
import { Helper } from 'src/models/helper.models';
import { PaymentMethod } from 'src/models/payment-method.models';
import { AddonChoices, Product } from 'src/models/product.models';
import { Profile } from 'src/models/profile.models';
import { AppComponent } from '../app.component';
import { AppConfig, APP_CONFIG, PrinterFormat } from '../app.config';
import { Cart, CartItem, CartItemAddOn, ECommerceService } from '../services/common/ecommerce.service';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';
import { Order } from 'src/models/order.models';
import { QzPrinterService } from '../services/common/qz-printer.service';


import * as firebase from 'firebase';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  tab: string = "popular";
  FavoriteIcon = false;
  private subscriptions = new Array<Subscription>();
  private myTableStatusRef: firebase.database.Reference;
  private doneAll = false;
  private infiniteScrollEvent;
  private pageNo = 1;
  profile: Profile;
  isLoading = true;
  filterForm = false;
  filterTypeFarmers = true;
  filterTypeTradors = true;
  private type: string = null;
  menuItemsToShow: { menu_items: Array<Product>; };
  menuItems = new Array<{ menu_items: Array<Product>; next_url: string; }>();
  tabIndex = 0;
  cartItems: Cart;
  currency_icon: string;
  private nextUrl: string;
  showMyCart: boolean = false;
  showAddOrder: boolean = true;
  showPayment: boolean = false;
  alwaysCallbackStatus: boolean = false;
  table: string;
  order: Order;
  proToShow: Product;
  addOnsLastUsed: Array<CartItemAddOn>;
  existingCartItems: Array<CartItem>;
  showItem: boolean;

  // paymeny
  paymentMethods = new Array<PaymentMethod>();
  paymentMethoIdSelected = -1;
  customer_number: string;
  customer_name: string;
  order_type: string;
  tables = new Array<{ table: number }>();

  searchText: string;
  selectedCategoryId: number;

  constructor(@Inject(APP_CONFIG) public config: AppConfig, private router: Router, private translate: TranslateService,
    private uiElementService: UiElementsService, private apiService: ApiService, public eComService: ECommerceService,
    public appComponent: AppComponent, private printerServide: QzPrinterService) {
  }

  ngOnInit() {
    this.currency_icon = Helper.getSetting("currency_icon");
    this.profile = Helper.getProfile();
    this.eComService.clearCart();
    this.myTableStatusRef = firebase.database().ref("vendors").child(String(this.profile.id)).child("table_status");

    if (this.profile && this.profile.product_categories && this.profile.product_categories.length) {
      this.translate.get("loading").subscribe(value => {
        this.uiElementService.presentLoading(value);
        this.loadCategories();
      });
    } else {
      this.isLoading = false;
    }
  }

  loadCategories() {
    for (let c of this.profile.product_categories) this.menuItems.push({ menu_items: [], next_url: "" });
    if (this.profile.product_categories && this.profile.product_categories.length) {
      this.pageNo = 1;
      this.loadProducts(this.profile.product_categories[0].id);
    } else {
      this.isLoading = false;
    }

  }

  ionViewDidEnter() {
    let cart_changed = window.localStorage.getItem(Constants.KEY_CART_CHANGED);
    if (cart_changed != null) {
      for (let mi of this.menuItems) for (let item of mi.menu_items) item.quantity = this.eComService.getCartProductQuantity((item.vendor_products && item.vendor_products[0]) ? item.vendor_products[0].id : item.id);
    }
    window.localStorage.removeItem(Constants.KEY_CART_CHANGED);
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  slideLoadProduct(index) {
    this.tabIndex = index;
    this.menuItemsToShow = this.menuItems[this.tabIndex];
    this.nextUrl = this.menuItems[this.tabIndex].next_url;

    if (!this.menuItemsToShow || !this.menuItemsToShow.menu_items || !this.menuItemsToShow.menu_items.length) {
      this.translate.get("loading").subscribe(value => {
        this.uiElementService.presentLoading(value);
        this.pageNo = 1;
        this.loadProducts(this.profile.product_categories[this.tabIndex].id);
      });
    }
  }

  loadProducts(categoryId?: number) {
    if (categoryId) this.selectedCategoryId = categoryId;
    let urlParams = new URLSearchParams();
    urlParams.append("category", String(this.selectedCategoryId));
    urlParams.append("vendor", String(this.profile.id));
    urlParams.append("page", String(this.pageNo));
    // this.subscriptions.push(this.apiService.getProductsWithParams(urlParams).subscribe(res => {this.productsRes(res)}, err => this.productsErr(err)));

    this.subscriptions.push(this.apiService.getProductsWithParams(urlParams).subscribe(res => {
      this.productsRes(res);
    }, error => {
      this.productsErr(error)
    }));
  }

  private productsRes(res) {
    this.nextUrl = res.links.next;
    this.menuItems[this.tabIndex].next_url = res.links.next;
    for (let pro of res.data) {
      this.menuItems[this.tabIndex].menu_items.push(pro);
    }
    this.menuItemsToShow = this.menuItems[this.tabIndex];
    this.isLoading = false;
    this.uiElementService.dismissLoading();
  }

  private productsErr(err) {
    this.isLoading = false;
    this.uiElementService.dismissLoading();
  }

  search() {
    this.uiElementService.presentLoading(this.translate.instant("searching"));
    let urlParams = new URLSearchParams();
    if (this.searchText) urlParams.append("search", this.searchText);
    if (!this.searchText) urlParams.append("category", String(this.profile.product_categories[this.tabIndex].id))
    urlParams.append("vendor", String(this.profile.id));
    urlParams.append("page", String(1));
    this.subscriptions.push(this.apiService.getProductsWithParams(urlParams).subscribe(res => { this.menuItems[this.tabIndex].menu_items = []; this.productsRes(res) }, err => this.productsErr(err)));
    // this.subscriptions.push(this.apiService.getAppointmentList(this.profile.id, this.pageNo, this.searchText).subscribe(res => this.appointmentRes(res), err => this.appointmentErr(err)));
  }

  onScrollDown() {
    // if (this.nextUrl != null) this.subscriptions.push(this.apiService.getURL(this.nextUrl).subscribe(res => {
    //   if (res && res.data && res.data.length) for (let pro of res.data) this.apiService.setupProduct(pro);
    //   this.productsRes(res);
    // }, err => this.productsErr(err)));
    if (this.nextUrl) {
      this.pageNo = this.pageNo + 1;
      this.loadProducts();
    }
  }

  checkShowItem() {
    this.existingCartItems = this.eComService.getCartItemsWithProductId((this.proToShow.vendor_products && this.proToShow.vendor_products[0]) ? this.proToShow.vendor_products[0].id : this.proToShow.id);
    if (this.existingCartItems.length && (this.existingCartItems.length > 1 || this.existingCartItems[0].addOns.length)) {
      let addOns = [];
      for (let ci of this.existingCartItems) addOns = addOns.concat(ci.addOns);
      this.addOnsLastUsed = addOns;
      this.showItem = false;
    } else {
      this.setupShowItem();
    }
  }

  addProCart() {
    if (!this.showPayment && this.proToShow) {
      let ciChoices = [];
      for (let g of this.proToShow.addon_groups) { if (g.addon_choices) { for (let c of g.addon_choices) if (c.isChecked) ciChoices.push(new CartItemAddOn(Number(c.id), c.title, c.price, c.priceToShow)); } }
      this.eComService.addOrIncrementCartItem(this.eComService.genCartItemFromProduct(this.proToShow, ciChoices));
      this.proToShow.quantity += 1;

      this.showMyCart = true;
      this.showAddOrder = false;
      this.showItem = false;
    }
  }

  setupShowItem() {
    for (let group of this.proToShow.addon_groups) {
      group.choiceIdSelected = -1;
      //CHECK FOR MINIMUM SELECTION
      if (group.min_choices > 0) {
        let selectedCount = 0;
        //for (let choice of group.addon_choices) if (choice.isChecked) selectedCount += 1;
        for (let choice of group.addon_choices) choice.isChecked = false;
        if (selectedCount < group.min_choices) {
          let checksLeft = group.min_choices - selectedCount;
          for (let choice of group.addon_choices) if (!choice.isChecked && checksLeft > 0) {
            choice.isChecked = true;
            group.choiceIdSelected = choice.id;
            checksLeft -= 1;
          }
        }
      }
    }
    this.showItem = true;
  }

  actionNew() {
    // for (let g of this.proToShow.addon_groups) { if (g.addon_choices) { for (let c of g.addon_choices) c.isChecked = false; } }
    this.addOnsLastUsed = null;
    this.showItem = true;
  }

  actionOld() {
    this.eComService.addOrIncrementCartItem(this.existingCartItems[this.existingCartItems.length - 1]);
    this.proToShow.quantity = this.eComService.getCartProductQuantity((this.proToShow.vendor_products && this.proToShow.vendor_products[0]) ? this.proToShow.vendor_products[0].id : this.proToShow.id);
    this.addOnsLastUsed = null;
  }

  onChoiceClick(choice: AddonChoices, group?: Array<AddonChoices>) {
    if (group && group.length) group.map(res => res.isChecked = false);
    var checkBox: any = document.getElementById("choice_id_" + choice.id);
    choice.isChecked = checkBox.checked;
    console.log(group)
  }

  toggleFavoriteIcon() {
    this.FavoriteIcon = !this.FavoriteIcon;
  }

  // start cart section
  decrementCartItem(ci) {
    window.localStorage.setItem(Constants.KEY_CART_CHANGED, "changed");
    this.eComService.removeOrDecrementCartItem(ci);
  }

  addOrIncrementCartItem(ci) {
    window.localStorage.setItem(Constants.KEY_CART_CHANGED, "changed");
    this.eComService.addOrIncrementCartItem(ci);
  }

  myCart() {
    this.proToShow = null;
    this.showMyCart = !this.showMyCart;
    this.showAddOrder = !this.showAddOrder;
  }

  cancelCart() {
    this.showMyCart = !this.showMyCart;
    this.showAddOrder = !this.showAddOrder;
    this.eComService.clearCart();
  }

  orderPlace() {
    this.showPayment = !this.showPayment;
    this.showMyCart = !this.showMyCart;
    this.loadPaymentMethod();
    this.getAvailableTables();
  }

  cancelOrder() {
    this.showPayment = !this.showPayment;
    this.showMyCart = !this.showMyCart;
  }

  submitOrder(order: Order) {
    this.showPayment = !this.showPayment;
    // this.showAddOrder = !this.showAddOrder;
    this.order = order;
    this.order.show = true;

    if (this.config.printerConfig.enabled && this.config.printerConfig.enableAutoPrint) this.printReceipt();
  }

  continue() {
    if (this.order) this.order.show = false;
    this.showAddOrder = !this.showAddOrder;
  }

  printReceipt() {
    if (this.config.printerConfig.enabled) {
      let savedNetworkConfig = Helper.getPrinterNetworkConfig();
      if (savedNetworkConfig) {
        if (savedNetworkConfig.name && savedNetworkConfig.name.trim().length) {
          this.printerServide.getPrinters().subscribe(res => {
            console.log("getPrinters: ", res);
            if (res.indexOf(savedNetworkConfig.name.trim()) == -1) {
              this.translate.get("unable_find_printer").subscribe(value => this.uiElementService.showErrorToastr(value.replace("<name>", savedNetworkConfig.name.trim())));
            } else {
              this.printWith({ name: savedNetworkConfig.name.trim(), host: null, port: null }, this.order);
            }
          }, err => {
            console.log("getPrinters: ", err);
            this.translate.get("unable_load_printers").subscribe(value => this.uiElementService.showErrorToastr(value));
          });
        } else if (savedNetworkConfig.host && savedNetworkConfig.host.trim().length && savedNetworkConfig.port && savedNetworkConfig.port.trim().length) {
          this.printWith({ name: null, host: savedNetworkConfig.host.trim(), port: savedNetworkConfig.port.trim() }, this.order);
        } else {
          this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
        }
      } else {
        this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
      }
      //this.printerServide.printData(savedNetworkConfig ? savedNetworkConfig : this.config.printerConfig.network, [Helper.getReceiptFormat(order)]).subscribe(res => console.log("printData: ", res), err => console.log("printData: ", err));
    }
  }

  private printWith(networkConfig, order) {
    if (this.config.printerConfig.format == PrinterFormat.ZPL) {
      this.printerServide.printData(networkConfig, [Helper.getZplReceiptFormat(order)]).subscribe(res => console.log("printData: ", res), err => {
        console.log("printData: ", err);
        this.translate.get("unable_print").subscribe(value => this.uiElementService.showErrorToastr(value));
      });
    } else {
      this.apiService.getReceiptFormat(order.id).subscribe(res => {
        this.printerServide.printData(networkConfig, res).subscribe(res => console.log("printData: ", res), err => {
          console.log("printData: ", err);
          this.translate.get("unable_print").subscribe(value => this.uiElementService.showErrorToastr(value));
        });
      }, err => {
        console.log("getReceiptFormat: ", err);
        this.translate.get("something_wrong").subscribe(value => this.uiElementService.showErrorToastr(value));
      });
    }
  }

  // ngAfterViewInit() {
  //   console.log("ngOnViewInit");
  // }

  loadPaymentMethod() {
    this.paymentMethoIdSelected = -1;
    this.customer_name = "";
    this.customer_number = "";
    this.order_type = "";
    this.table = "";
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.getPaymentMethods().subscribe(res => {
        this.paymentMethods = res;
        this.uiElementService.dismissLoading();
      }, err => {
        console.log("getPaymentMethods", err);
        this.uiElementService.dismissLoading();
        this.cancelOrder();
      }));
    });
  }

  getAvailableTables() {
    const component = this;
    let totalTables = Number(Helper.getProfile().meta.no_of_tables);
    if (!totalTables) totalTables = 0;
    let tables: Array<{ table: number }> = [];
    for (let i = 1; i <= totalTables; i++) tables.push({ table: i });

    this.myTableStatusRef.once("value", (snap) => {
      let tablesMap = snap.val() as any;
      console.log("myTableStatusRef: ", tablesMap);
      if (tablesMap) {
        let keys = Object.keys(tablesMap);
        keys.forEach((key, index) => {
          if (tablesMap[key].occupied) {
            let toRemove = -1;
            for (let i = 0; i < tables.length; i++) {
              if (tables[i].table == Number(key)) {
                toRemove = i;
                break;
              }
            }
            if (toRemove != -1) tables.splice(toRemove, 1);
          }
        });
      }
      component.tables = tables;
    }, (error) => console.log("myTableStatusRef: ", error));
  }

  // onPaymentMethodSelected(event) {
  //   if (event.detail && event.detail.value) {
  //     this.paymentMethoIdSelected = event.detail.value;
  //   }
  // }

  confirmOrder() {
    let selectedPaymentMethod = this.getSelectedPaymentMethod();
    if (selectedPaymentMethod == null) {
      this.translate.get("select_payment_method").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.order_type || !this.order_type.length) {
      this.translate.get("order_type").subscribe(value => this.uiElementService.showErrorToastr(value));
    }
    // else if (!this.customer_name || !this.customer_name.length) {
    //   this.translate.get("customer_name").subscribe(value => this.uiElementService.showErrorToastr(value));
    // } else if (!this.customer_number || !this.customer_number.length) {
    //   this.translate.get("customer_phone_number").subscribe(value => this.uiElementService.showErrorToastr(value));
    // }
    else {
      if (this.order_type == 'dinein' && !this.table) {
        this.translate.get("select_table").subscribe(value => this.uiElementService.showErrorToastr(value));
      } else {
        const component = this;
        this.eComService.setupOrderRequestPaymentMethod(selectedPaymentMethod);
        this.eComService.setupOrderRequestOrder_type(this.order_type.toLocaleUpperCase());
        this.eComService.setupOrderRequestCustomer_name((!this.customer_name || !this.customer_name.length) ? this.config.appName : this.customer_name);
        this.eComService.setupOrderRequestcCustomer_mobile((!this.customer_number || !this.customer_number.length) ? "9080706050" : this.customer_number);
        this.eComService.setupOrderRequestIs_guest(true);
        this.eComService.setupOrderRequestMeta("source", Constants.ROLE_DESK);
        if (this.table) {
          this.eComService.setupOrderRequestMeta("table", this.table);

          this.translate.get(["just_moment", "table_taken"]).subscribe(values => {
            this.uiElementService.presentLoading(values["just_moment"]);
            this.myTableStatusRef.child(String(this.table)).once("value", (snap) => {
              component.uiElementService.dismissLoading();
              if (snap.exists()) {
                let tableStatus = snap.val() as { occupied: boolean; order_id: number; };
                console.log("tableStatus: ", tableStatus);
                if (tableStatus && tableStatus.occupied) {
                  component.eComService.removeOrderRequestMeta("table");
                  component.uiElementService.showErrorToastr(values["table_taken"]);

                  let toRemove = -1;
                  for (let i = 0; i < component.tables.length; i++) {
                    if (component.tables[i].table == Number(component.table)) {
                      toRemove = i;
                      break;
                    }
                  }
                  if (toRemove != -1) component.tables.splice(toRemove, 1);

                  component.table = null;
                } else {
                  component.proceedPlaceOrder();
                }
              } else {
                component.proceedPlaceOrder();
              }
            }, (error) => {
              console.log("myTableStatusRef: ", error);
              component.uiElementService.dismissLoading();
              component.proceedPlaceOrder();
            });
          });
        } else {
          this.proceedPlaceOrder();
        }
      }
    }
  }

  private proceedPlaceOrder() {
    let orderRequest = this.eComService.getOrderRequest();
    this.translate.get(["order_placing", "order_placed", "order_place_err"]).subscribe(values => {
      this.uiElementService.presentLoading(values["order_placing"]);
      this.apiService.createOrder(orderRequest).subscribe(res => {
        // setTimeout(() => this.apiService.updateOrder(res.order.id, { status: "accepted" }).subscribe(res => console.log("updateOrder: ", res), err => console.log("updateOrder: ", err)), 500);

        //SETTINGS TABLE BOOKED.
        if (res.order && res.order.meta && res.order.meta.table) this.myTableStatusRef.child(String(res.order.meta.table)).set({ occupied: true, order_id: res.order.id });

        this.uiElementService.dismissLoading();
        this.eComService.clearCart();
        this.submitOrder(res.order);
      }, err => {
        console.log("createOrder", err);
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr(values["order_place_err"]);
      });
    });
  }

  getSelectedPaymentMethod(): PaymentMethod {
    let toReturn = null;
    for (let pm of this.paymentMethods) if (this.paymentMethoIdSelected == pm.id) { toReturn = pm; break; }
    if (toReturn == null) for (let pm of this.paymentMethods) if (pm.slug == "cod") { toReturn = pm; break; }
    return toReturn;
  }

  carouselOptions = {
    nav: true,
    responsiveClass: true,
    loop: false,
    dots: false,
    autoWidth: true,
    items: 1,
    navText: ["<div class='nav-btn prev-slide'><i class='zmdi zmdi-chevron-left'></i></div>", "<div class='nav-btn next-slide'><i class='zmdi zmdi-chevron-right'></i></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      }
    }
  }

}

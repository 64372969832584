import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    webApplicationId: string;
}

export enum PrinterFormat {
    ZPL, ESC
}

export interface AppConfig {
    appName: string;
    apiBase: string;
    googleApiKey: string;
    availableLanguages: Array<{ code: string; name: string; }>;
    firebaseConfig: FirebaseConfig;
    printerConfig: {
        enabled: boolean;
        enableAutoPrint: boolean;
        format: PrinterFormat;
        network: { host: string; port: string; }
    };
    demoMode: boolean;
    demoLoginCredentials: { country: string, phoneNumber: string; otp: string; };
}

export const BaseAppConfig: AppConfig = {
    appName: "Foodzi Desk",
    //apiBase: "https://stag-backend.foodzi.co.uk/public/",
    apiBase: "https://admin.foodzi.co.uk/public/",
    //googleApiKey: "AIzaSyBP7ft966RNquaxFUk9CTaNHRppPEvVRNc",
    googleApiKey:"AIzaSyByg27eBq3TdVAk_Y_h6IRXnMW8M1dn9L8",
    printerConfig: {
        enabled: true,
        enableAutoPrint: true,
        format: PrinterFormat.ESC,
        network: { host: "127.0.0.1", port: "9100" }
    },
    availableLanguages: [{
        code: 'en',
        name: 'English'
    }, {
        code: 'ar',
        name: 'عربى'
    }, {
        code: 'fr',
        name: 'français'
    }, {
        code: 'es',
        name: 'Española'
    }, {
        code: 'id',
        name: 'bahasa Indonesia'
    }, {
        code: 'pt',
        name: 'português'
    }, {
        code: 'tr',
        name: 'Türk'
    }, {
        code: 'it',
        name: 'Italiana'
    }, {
        code: 'sw',
        name: 'Kiswahili'
    }],
    demoMode: false,
    demoLoginCredentials: { country: "91", phoneNumber: "9876543214", otp: "123456" },
    firebaseConfig: {
        webApplicationId: "221508465473-qtl45llshhv9s4hpkpcasi6hh148t3md.apps.googleusercontent.com",
        apiKey: "AIzaSyBP7ft966RNquaxFUk9CTaNHRppPEvVRNc",
        authDomain: "saleems-66d8b.firebaseapp.com",
        databaseURL: "https://saleems-66d8b-default-rtdb.firebaseio.com",
        projectId: "saleems-66d8b",
        storageBucket: "saleems-66d8b.appspot.com",
        messagingSenderId: "221508465473"
    }
};

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BaseListResponse } from 'src/models/base-list.models';
import { Helper } from 'src/models/helper.models';
import { Order } from 'src/models/order.models';
import { Profile } from 'src/models/profile.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';

@Component({
  selector: 'app-orders-status',
  templateUrl: './orders-status.component.html',
  styleUrls: ['./orders-status.component.css']
})
export class OrdersStatusComponent implements OnInit {
  showOrder: string = "accepted";
  private myOrdersRef: firebase.database.Reference;
  private myMenuItemsRef: firebase.database.Reference;
  private subscriptions = new Array<Subscription>();
  orders = new Array<Order>();
  isLoading = true;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  private profile: Profile;
  // private orderStatus = ['cancelled', 'rejected', 'refund', 'failed', 'complete'];
  startTimmer: any;
  countLength: number;
  private nextUrl: string;

  constructor(private translate: TranslateService, private uiElementService: UiElementsService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.profile = Helper.getProfile();
    this.myOrdersRef = firebase.database().ref("vendors").child(String(this.profile.id)).child("orders");
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.getOrders(this.showOrder);
    });
  }

  ngOnDestroy() {
    this.unRegisterUpdates();
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
    clearInterval(this.startTimmer);
  }

  getOrders(showOrder) {
    this.apiService.getOrders(this.profile.id, this.pageNo, "", showOrder).subscribe(res => {
      this.ordersRes(res);
    }, err => {
      this.ordersErr(err);
    });
  }

  ordersRes(res: BaseListResponse) {
    this.startTimmer = setInterval(() => {
      this.profile = Helper.getProfile();
      this.countLength = 0;
      for (let o of res.data) this.setupColorProgress(o);
      this.registerUpdates();
    }, 1000);
    this.isLoading = false;
    this.orders = this.orders.concat(res.data);
    console.log('orders', this.orders);
    this.nextUrl = res.links.next;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.uiElementService.dismissLoading();
  }

  setupColorProgress(order: Order) {
    var seconds = (moment().toDate().getTime() - order.created_at_milliseconds);
    // console.log(seconds);
    // console.log('cecond',moment().toDate().getTime(),this.apiService.times.find(key => key.key == this.profile.meta.green_time).value);

    if (this.profile && this.profile.meta && this.profile.meta.green_time && this.profile.meta.yellow_time && this.profile.meta.red_time) {
      if (seconds <= Number(this.profile.meta.green_time)) {
        order.colorProgress = "green_time";
      } else if (seconds <= Number(this.profile.meta.yellow_time)) {
        order.colorProgress = "yellow_time";
      } else {
        order.colorProgress = "red_time";
        this.countLength++;
      }
      // if (this.countLength == this.orders.length) clearInterval(this.startTimmer);
      // console.log(order.colorProgress, this.countLength)
    }
    order.showOrderPalceTime = this.msToTime(seconds);
  }

  msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    var hour = (hours < 10) ? "0" + hours : hours;
    var minute = (minutes < 10) ? "0" + minutes : minutes;
    var second = (seconds < 10) ? "0" + seconds : seconds;

    return hour + ":" + minute + ":" + second;
  }

  ordersErr(err) {
    console.log("productsErr", err);
    this.isLoading = false;
    this.uiElementService.dismissLoading();
  }

  onScrollDown() {
    if (this.nextUrl != null) this.subscriptions.push(this.apiService.getURL(this.nextUrl).subscribe(res => {
      if (res && res.data && res.data.length) for (let pro of res.data) this.apiService.setupProduct(pro);
      this.ordersRes(res);
    }, err => this.ordersErr(err)));
  }

  addFireOrder(newOrder: Order) {
    if (newOrder.status != "new" && this.showOrder != "active") return;
    let existingIndex = -1
    if (this.orders.length) {
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].id == newOrder.id) {
          existingIndex = i;
          break;
        }
      }
    }
    this.apiService.setupOrder(newOrder);
    if (existingIndex == -1) {
      this.orders.unshift(newOrder);
    } else {
      this.orders[existingIndex] = newOrder;
    }
  }

  updateStatusOnId(oId: number, oNew: Order) {
    let index = -1;
    for (let i = 0; i < this.orders.length; i++) {
      if (this.orders[i].id == oId) {
        index = i;
        break;
      }
    }
    if (index != -1) {

      this.orders[index].status = oNew.status;
      if (oNew.delivery != null) {
        oNew.delivery.delivery.user.image_url = "assets/images/empty_dp";
        if (!oNew.delivery.delivery.user.mediaurls || !oNew.delivery.delivery.user.mediaurls.images) oNew.delivery.delivery.user.mediaurls = { images: [] };
        for (let imgObj of oNew.delivery.delivery.user.mediaurls.images) if (imgObj["default"]) { oNew.delivery.delivery.user.image_url = imgObj["default"]; break; }
        this.orders[index].delivery = oNew.delivery;
      }

      this.orders.unshift(this.orders.splice(index, 1)[0]);
    }
  }

  // registerUpdates() {
  //   const component = this;
  //   if (this.myOrdersRef != null) {
  //     this.myOrdersRef.on('child_added', function (data) {
  //       var fireOrder = data.val() as { data: Order };
  //       console.log("child_added", fireOrder);
  //       if (fireOrder.data != null) component.addFireOrder(fireOrder.data);
  //     });
  //     this.myOrdersRef.on('child_changed', function (data) {
  //       var fireOrder = data.val() as { data: Order };
  //       console.log("child_changed", fireOrder);
  //       if (fireOrder.data != null) component.updateStatusOnId(fireOrder.data.id, fireOrder.data);
  //     });
  //   }
  // }

  // unRegisterUpdates() {
  //   if (this.myOrdersRef != null) {
  //     this.myOrdersRef.off();
  //   }
  // }

  addUpdateFireMenuItemsRef(menuItemStatus: { order_product_id: number; done: boolean; }) {
    let orderIndex = -1;
    let menuItemIndex = -1;
    if (this.orders && this.orders.length) {
      for (let i = 0; i < this.orders.length; i++) {
        for (let j = 0; j < this.orders[i].products.length; j++) {
          if (this.orders[i].products[j].id == menuItemStatus.order_product_id) {
            orderIndex = i;
            menuItemIndex = j;
            break;
          }
        }
        if (orderIndex != -1 && menuItemIndex != -1) { break; }
      }
    }

    if (orderIndex != -1 && menuItemIndex != -1) {
      this.orders[orderIndex].products[menuItemIndex].done = menuItemStatus.done;
    }
  }

  registerUpdates() {
    const component = this;
    // if (this.myOrdersRef == null) {
    //   this.myOrdersRef = firebase.database().ref("vendors").child(String(this.profile.id)).child("orders");
    //   this.myOrdersRef.on('child_added', function (data) {
    //     var fireOrder = data.val() as { data: Order };
    //     // console.log("child_added", fireOrder);
    //     if (fireOrder.data != null) component.addFireOrder(fireOrder.data);
    //   });
    //   this.myOrdersRef.on('child_changed', function (data) {
    //     var fireOrder = data.val() as { data: Order };
    //     console.log("child_changed", fireOrder);
    //     if (fireOrder.data != null) component.updateStatusOnId(fireOrder.data.id, fireOrder.data);
    //   });
    // }

    if (this.myMenuItemsRef == null) {
      this.myMenuItemsRef = firebase.database().ref("fire_app").child("order_products");
      this.myMenuItemsRef.on('child_added', function (data) {
        var fireMenuItemStatus = data.val() as { order_product_id: number; done: boolean; };
        console.log("child_added", fireMenuItemStatus);
        if (fireMenuItemStatus != null) component.addUpdateFireMenuItemsRef(fireMenuItemStatus);
      });
      this.myMenuItemsRef.on('child_changed', function (data) {
        var fireMenuItemStatus = data.val() as { order_product_id: number; done: boolean; };
        console.log("child_changed", fireMenuItemStatus);
        if (fireMenuItemStatus != null) component.addUpdateFireMenuItemsRef(fireMenuItemStatus);
      });
    }
  }

  unRegisterUpdates() {
    if (this.myOrdersRef != null) {
      this.myOrdersRef.off();
      this.myOrdersRef = null;
    }
    if (this.myMenuItemsRef != null) {
      this.myMenuItemsRef.off();
      this.myMenuItemsRef = null;
    }
  }
}

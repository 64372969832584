<div id="page_items">

  <!-- Body Wrapper Start -->
  <div class="body_wrapper">
    <div class="page_title">
      <div class="row align-items-center mx-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
          <div class="title_inner d-flex">
            <h1 class="d-flex align-items-center">{{'food_items' | translate}}
              <!--                            <span class="ml-4">$987.50</span>-->
            </h1>
            <button type="button" class="btn" (click)="navItemDetail(null)"><a>{{'add_new' |
                                translate}}</a></button>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
          <form
            class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-3 mt-lg-0 pb-3 pb-md-0 ml-auto">
            <div class="form-group d-flex">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
              </div>
              <input type="text" class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText"
                (keyup)="searchProducts()">
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="right_sidebar">

      <!-- Tab Content Start -->
      <div class="tab-content">
        <div class="order_list_container">
          <!-- Order List Start -->
          <div class="order_list">
            <div class="list_header d-flex">
              <!--                            <h2 class="text-center order_num Code">Code</h2>-->
              <h2 class="text-left Name">{{'item_name' | translate}}</h2>
              <h2 class="text-center Amount Category">{{'category' | translate}}</h2>
              <h2 class="text-center Items Options">{{'add_ons' | translate}}</h2>
              <h2 class="text-center Table Price">{{'price' | translate}}</h2>
              <h2 class="text-center Payment Sales ">{{'sales' | translate}}</h2>
              <h2 class="text-right Action">{{'action' | translate}}</h2>
            </div>

            <ul>
              <div class="empty-view animate__animated animate__fadeInUp"
                *ngIf="!isLoading && (!products || !products.length)">
                <div style="text-align:center">
                  <img src="assets/images/plc_no_item.png" alt="no offers" />
                  <span style="color:#9E9E9E; font-weight:bold;">
                    {{'empty_results' | translate}}
                  </span>
                </div>
              </div>
              <li
                *ngFor="let item of products | paginate : { itemsPerPage: pageSize, currentPage: pageNo, totalItems: pageTotal};let i = index"
                class="d-flex animate__animated animate__fadeInUp wow">
                <!--                                <h3 class="text-center order_num Code">18754</h3>-->
                <h3 class="text-left Name"><strong>{{item.title}}</strong></h3>
                <h3 class="text-center Amount Category">{{item.categoriesText}}</h3>
                <h3 class="text-center Items Options">
                  {{item.addon_groups && item.addon_groups.length ? item.addon_groups[0].addon_choices.length : 0}} <i
                    class="zmdi zmdi-storage"></i>
                </h3>
                <h3 class="text-center Table Price">{{item.priceToShow}}</h3>
                <h3 class="text-center Payment Sales">{{item.vendor_products[0].sells_count}}</h3>
                <div class="btn_container d-flex mr-0 ml-auto">
                  <!--
                                    <button type="button" class="btn">
                                        <a href="#"><i class="zmdi zmdi-eye"></i></a>
                                    </button>
                                    <button type="button" class="btn">
                                        <a data-toggle="modal" data-target="#receipt_model"><i class="zmdi zmdi-image"></i></a>
                                    </button>
-->
                  <button type="button" class="btn" (click)="navItemDetail(item)">
                    <a><i class="zmdi zmdi-edit"></i></a>
                  </button>
                  <button type="button" class="btn" (click)="alertDeleteProduct(item.id)">
                    <a><i class="zmdi zmdi-delete"></i></a>
                  </button>
                  <button type="button" class="btn text-success" (click)="duplicateProduct(item)">
                    <a><i class="zmdi zmdi-copy"></i></a>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <!-- Order List End -->
        </div>

        <!-- Tab Footer start -->
        <div *ngIf="pageTotal > pageSize" class="tab_footer">
          <div class="row no-gutter align-items-center">
            <div class="col-12 col-md-12 col-lg-4 pb-3">
              <h2>{{'showing' | translate}} {{pageNo}} {{'to' | translate}} {{pageSize}} {{'of' | translate}}
                {{pageTotal}} {{'item' | translate}}</h2>
            </div>
            <div class="col-12 col-md-12 col-lg-8 pb-3">
              <div class="row align-items-center">
                <nav class="navigation col-12" aria-label="Page navigation example">
                  <pagination-controls responsive="true" [previousLabel]="'prev' | translate"
                    [nextLabel]="'next' | translate" (pageChange)="onChangePage($event)">
                  </pagination-controls>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab Footer End -->

        <!-- Food Items Tab Content End -->
      </div>
      <!-- Tab Content End -->
    </div>
  </div>
  <!-- Body Wrapper End -->

</div>

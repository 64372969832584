import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseListResponse } from 'src/models/base-list.models';
import { Helper } from 'src/models/helper.models';
import { Profile } from 'src/models/profile.models';
import { AppoiBookRequest, AppointeeList } from 'src/models/table-booking.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { ApiService } from '../services/network/api.service';

declare var $: any;
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  // private nextUrl: string;
  isLoading = true;
  appointments = new Array<AppointeeList>();
  profile: Profile;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;
  readAllText: boolean = false;

  appointmentRequest = new AppoiBookRequest()
  minDate: Date;
  maxDate: any;

  constructor(private translate: TranslateService, private uiElementService: UiElementsService, private apiService: ApiService, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.minDate = new Date();
    this.profile = Helper.getProfile();
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadAppointment();
    });
  }

  loadAppointment() {
    this.subscriptions.push(this.apiService.getAppointmentList(this.profile.id, this.pageNo).subscribe(res => this.appointmentRes(res), err => this.appointmentErr(err)));
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
    this.dismissModel();
  }

  onChangePage(event) {
    this.pageNo = event;
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadAppointment();
    });
  }

  search() {
    this.subscriptions.push(this.apiService.getAppointmentList(this.profile.id, this.pageNo, this.searchText).subscribe(res => this.appointmentRes(res), err => this.appointmentErr(err)));
  }

  read() {
    this.readAllText = !this.readAllText;
  }

  appointmentRes(res: BaseListResponse) {
    this.appointments = res.data;
    this.isLoading = false;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.uiElementService.dismissLoading();
  }

  appointmentErr(err) {
    console.log("productsErr", err);
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }

  navBookingTable() {
    this.appointmentRequest = new AppoiBookRequest();
    $('#add_expenses').modal({ backdrop: 'static', keyboard: false })
  }

  dismissModel() {
    $('#add_expenses').modal('hide');
  }
  onSubmitRequest() {
    if (!this.appointmentRequest.date || !this.appointmentRequest.date.length) {
      this.translate.get("select_date_time").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.appointmentRequest.meta.person || !this.appointmentRequest.meta.person.length) {
      this.translate.get("select_person").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.appointmentRequest.customer_name || !this.appointmentRequest.customer_name.length) {
      this.translate.get("customer_name").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.appointmentRequest.customer_mobile || !this.appointmentRequest.customer_mobile.length) {
      this.translate.get("customer_phone_number").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else {
      this.appointmentRequest.time_from = this.appointmentRequest.date.split("T")[1];
      // let bookReq = new AppoiBookRequest();
      let bookReq = {
        vendor_id: Helper.getProfile().id,
        amount: 0,
        customer_mobile: this.appointmentRequest.customer_mobile,
        customer_name: this.appointmentRequest.customer_name,
        date: this.appointmentRequest.date.split("T")[0],
        duplicate_slots_allowed: -1,
        is_guest: true,
        meta: JSON.stringify({ person: this.appointmentRequest.meta.person, note: this.appointmentRequest.meta.note }),
        time_from: this.appointmentRequest.time_from,
        time_to: "00:00"
      }
      this.translate.get(["booking_your_table", "your_table_is_booked", "booking_your_table_err"]).subscribe(values => {
        this.uiElementService.presentLoading(values["booking_your_table"]);
        this.apiService.createAppointment(bookReq).subscribe(res => {
          // this.uiElementService.dismissLoading();
          this.uiElementService.showSuccessToastr(values["your_table_is_booked"]);
          this.dismissModel();
          this.translate.get("loading").subscribe(value => {
            this.uiElementService.presentLoading(value);
            this.loadAppointment();
          });
        }, err => {
          console.log("createOrder", err);
          this.uiElementService.dismissLoading();
          this.uiElementService.showErrorToastr(values["booking_your_table_err"]);
        });
      });
    }
  }

  confirmAccept(ap) {
    this.translate.get(['accept_ap_title', 'accept_ap_message', 'no', 'yes']).subscribe(text => this.confirmationDialogService.confirm(text['accept_ap_title'], text['accept_ap_message'], text['yes'], text['no']).then((confirmed) => { if (confirmed) this.updateAppointmentStatus(ap.id, 'accepted'); }).catch(() => { console.log('err') }));
  }

  confirmReject(ap) {
    this.translate.get(['reject_ap_title', 'reject_ap_message', 'no', 'yes']).subscribe(text => this.confirmationDialogService.confirm(text['reject_ap_title'], text['reject_ap_message'], text['yes'], text['no']).then((confirmed) => { if (confirmed) this.updateAppointmentStatus(ap.id, 'rejected'); }).catch(() => { console.log('err') }));
  }

  private updateAppointmentStatus(apId, statusToUpdate) {
    this.translate.get("just_moment").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.updateAppointment(apId, { status: statusToUpdate }).subscribe(res => {
        this.uiElementService.dismissLoading();
        if (res.status) this.translate.get("ap_updated_" + res.status).subscribe(text => this.uiElementService.showSuccessToastr(text));
        this.updateAppointmentInList(res);
      }, err => {
        console.log("updateAppointment", err);
        this.uiElementService.dismissLoading();
        this.translate.get("something_wrong").subscribe(text => this.uiElementService.showErrorToastr(text));
      }));
    });
  }

  private updateAppointmentInList(ap) {
    let index = -1;
    for (let i = 0; i < this.appointments.length; i++) {
      if (this.appointments[i].id == ap.id) {
        index = i;
        break;
      }
    }
    if (index != -1) {
      this.appointments[index] = ap;
      // if (ap.status == "cancelled" || ap.status == "rejected" || ap.status == "complete") {
      //   this.reFilter();
      // }
    }
  }

}

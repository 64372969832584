<html [dir]="rtlSide">
<!-- Header Start -->
<header id="header" *ngIf="profileMe" class="animate__animated animate__fadeInDown wow" data-wow-duration=".5s">
    <nav class="navbar align-items-center" style="flex-wrap: unset;">
        <div class="nav-item  ml-lg-2 ml-xl-0 logo" style="min-width: fit-content;">
            <a class="navbar-brand nav-link px-0" (click)="dashboard('dashboard')">
                <img src="assets/images/logo.svg" class="img-fluid">
            </a>
        </div>

        <div class="nav-inner ml-5 pl-4 w-100">
            <ul class="navbar-nav d-flex align-items-center w-100">
                <li class="nav-item show-only-large-devices" [ngClass]="menuActive == 'dashboard' ? 'active' : ''">
                    <a class="nav-link" (click)="dashboard('dashboard')">
                        <i class="zmdi zmdi-view-dashboard"></i> {{'dashboard' | translate}}
                    </a>
                </li>
                <li class="nav-item show-only-large-devices" [ngClass]="menuActive == 'home' ? 'active' : ''">
                    <a class="nav-link" (click)="home('home')">
                        <i class="zmdi zmdi-assignment"></i> {{'pos' | translate}}
                    </a>
                </li>
                <li class="nav-item show-only-large-devices" [ngClass]="menuActive == 'transactions' ? 'active' : ''">
                    <a class="nav-link" (click)="transactions('transactions')">
                        <i class="zmdi zmdi-collection-text"></i> {{'orders' | translate}}</a>
                </li>
                <li class="nav-item show-only-large-devices" [ngClass]="menuActive == 'booking' ? 'active' : ''">
                    <a class="nav-link" (click)="booking('booking')">
                        <i class="zmdi zmdi-airline-seat-recline-extra"></i> {{'bookings' | translate}}</a>
                </li>
                <li class="nav-item show-only-large-devices" [ngClass]="menuActive == 'orders_status' ? 'active' : ''">
                    <a class="nav-link" (click)="orders_status('orders_status')">
                        <i class="zmdi zmdi-hourglass-alt"></i> {{'orders_status' | translate}}</a>
                </li>

                <li class="nav-item profile_img ml-auto" type="button" (click)="menu()">
                    <div class="dropdown">
                        <span>
                            <i class="zmdi zmdi-menu"></i>
                            <i class="zmdi zmdi-close"></i>
                        </span>
                        <a *ngIf="profileMe && profileMe.image_urls && profileMe.image_urls.length" class="img_box center_img">
                            <img [src]="profileMe.image_urls[0]" class="crop_img">
                        </a>
                        <a *ngIf="!profileMe || !profileMe.image_urls || !profileMe.image_urls.length" class="img_box center_img">
                            <img src="assets/images/plc_seller_profile.png" class="crop_img">
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <div class="right-side-menu">
        <div class="menu-inner">
            <ul class="align-items-center w-100">
                <li class="show-medium-devices nav-item">
                    <a class="nav-link" (click)="dashboard('')">
                        <i class="zmdi zmdi-view-dashboard"></i> {{'dashboard' | translate}}
                    </a>
                </li>

                <li class="show-medium-devices nav-item">
                    <a class="nav-link" (click)="home('')">
                        <i class="zmdi zmdi-assignment"></i> {{'pos' | translate}}
                    </a>
                </li>
                <li class="show-medium-devices">
                    <a class="nav-link" (click)="transactions('')">
                        <i class="zmdi zmdi-collection-text"></i> {{'orders' | translate}}
                    </a>
                </li>
                <li class="show-medium-devices">
                    <a class="nav-link" (click)="booking('')">
                        <i class="zmdi zmdi-airline-seat-recline-extra"></i> {{'bookings' | translate}}
                    </a>
                </li>
                <li class="show-medium-devices">
                    <a class="nav-link" (click)="orders_status('')">
                        <i class="zmdi zmdi-hourglass-alt"></i> {{'orders_status' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="people('')">
                        <i class="zmdi zmdi-accounts-alt"></i> {{'customers' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="wallet('')">
                        <i class="zmdi zmdi-balance-wallet"></i> {{'wallet' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="items('')">
                        <i class="zmdi zmdi-cutlery"></i> {{'food_items' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="addons('')">
                        <i class="zmdi zmdi-cutlery"></i> {{'Addons'}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="reviews('')">
                        <i class="zmdi zmdi-star"></i> {{'ratings' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="authentication('')">
                        <i class="zmdi zmdi-shield-check"></i> {{'authentication' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="setting('')">
                        <i class="zmdi zmdi-settings"></i> {{'settings' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="change_language('')">
                        <i class="zmdi zmdi-globe"></i> {{'change_language' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="support('')">
                        <i class="zmdi zmdi-email"></i> {{'support' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="terms_conditions('')">
                        <i class="zmdi zmdi-assignment"></i> {{'terms_conditions' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="login()">
                        <i class="zmdi zmdi-open-in-new"></i> {{'logout' | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</header>
<div *ngIf="profileMe" class="header_spacebar"></div>
<!-- Header End -->
<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fbaf03" type="ball-spin-clockwise">
    <p style="color: #fbaf03"> {{uiElementService.loadingText}} </p>
    <!-- <p style="color: #fbaf03">Loading... </p> -->
</ngx-spinner>

</html>

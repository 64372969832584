import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/network/api.service';

@Component({
  selector: 'app-print-recipt',
  templateUrl: './print-recipt.component.html',
  styleUrls: ['./print-recipt.component.css']
})
export class PrintReciptComponent implements OnInit {
  orderId: any;
  reciptHtml: any;
  constructor(private apiService: ApiService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params.id;
    console.log(this.orderId)
    this.getReciptData();
    // setTimeout(() => {
    //   window.print();
    // }, 2000);
  }

  getReciptData() {

    this.apiService.getReceiptFormat(this.orderId).subscribe(res => {
      this.reciptHtml = res;
      setTimeout(() => {
        window.print();
      }, 300);
    }, err => {
      console.log("getReceiptFormat: ", err);
      // this.translate.get("something_wrong").subscribe(value => this.uiElementService.showErrorToastr(value));
    });
  }

}

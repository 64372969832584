import { Component, Inject, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
import { Constants } from 'src/models/constants.models';
import { Helper } from 'src/models/helper.models';
import { Order } from 'src/models/order.models';
import { Profile } from 'src/models/profile.models';
import { AppConfig, APP_CONFIG, PrinterFormat } from './app.config';
import { QzPrinterService } from './services/common/qz-printer.service';
import { UiElementsService } from './services/common/ui-elements.service';
import { ConfirmationDialogService } from './services/confirmation-dialog/confirmation-dialog.service';
import { MyEventsService } from './services/events/my-events.service';
import { ApiService } from './services/network/api.service';
import * as firebase from 'firebase';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'hungerz-counter';
  rtlSide = "left";
  // showHeader: boolean = true;
  profileMe: Profile;
  // menuActive = "dashboard";
  menuActive = "transactions"
  showHeader: boolean = false;

  private myOrdersRef: firebase.database.Reference;
  private subscriptions = new Array<Subscription>();
  private latestOrder: Order;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig, private router: Router, private wowService: NgwWowService, private printerServide: QzPrinterService,
    private translate: TranslateService, private myEventsService: MyEventsService, public uiElementService: UiElementsService,
    private apiService: ApiService, private confirmationDialogService: ConfirmationDialogService) {
    this.initializeApp();
    // console.log(this.router)
    this.wowService.init();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart || event instanceof NavigationEnd) {
    //     console.log(event.url);
    //     if (event.url == "/" || event.url == "/login") this.showHeader = false; else this.showHeader = true;
    //   }
    // });
    this.myEventsService.getLanguageObservable().subscribe(value => {
      this.globalize(value);
      this.apiService.updateUser({ language: value }).subscribe(res => console.log('updateUser'), err => console.log('updateUser', err));
      this.apiService.setupHeaders();
      // this.router.navigate([this.profileMe ? 'dashboard' : 'login']);
      this.router.navigate([this.profileMe ? 'transactions' : 'login']);

    });
    this.myEventsService.getLoginObservable().subscribe(loginRes => {
      if (loginRes == null) {
        this.profileMe = null;
        Helper.setProfile(null);
        // this.router.navigate([this.profileMe ? 'dashboard' : 'login']);
        this.router.navigate([this.profileMe ? 'transactions' : 'login']);

        this.unRegisterUpdates();
      } else if (loginRes && loginRes.token && loginRes.vendor) {
        this.translate.get(["verifying_profile", "something_wrong"]).subscribe(values => {
          this.uiElementService.presentLoading(values["verifying_profile"]);
          Helper.setLoggedInUserResponse(loginRes);
          this.apiService.setupHeaders(loginRes.token);
          this.apiService.getProfile().subscribe(resProfile => {
            if (resProfile && resProfile.user && resProfile.name && resProfile.name.length) {
              this.myEventsService.setProfileData(resProfile);
            } else {
              let navigationExtras: NavigationExtras = { state: { fresh_profile: true } };
              this.router.navigate(['./setting'], navigationExtras);
            }
            this.uiElementService.dismissLoading();
          }, err => {
            console.log("getProfile", err);
            this.apiService.setupHeaders(null);
            this.uiElementService.dismissLoading();
          });
        });
      }
    });
    this.myEventsService.getProfileObservable().subscribe(profile => {
      Helper.setProfile(profile);
      this.profileMe = profile;
      //this.router.navigate([this.profileMe ? 'dashboard' : 'login'])
      this.router.navigate([this.profileMe ? 'transactions' : 'login']);
      this.registerOrderUpdates();
    });
  }

  ngOnDestroy() {
    this.unRegisterUpdates();
    for (let sub of this.subscriptions) sub.unsubscribe();
  }
  // ngAfterViewInit(){
  //   $("#menu").on("click", function () {
  //     $("#header").toggleClass("active");
  //   });
  // }

  initializeApp() {
    this.apiService.setupHeaders();
    firebase.initializeApp({
      apiKey: this.config.firebaseConfig.apiKey,
      authDomain: this.config.firebaseConfig.authDomain,
      databaseURL: this.config.firebaseConfig.databaseURL,
      projectId: this.config.firebaseConfig.projectId,
      storageBucket: this.config.firebaseConfig.storageBucket,
      messagingSenderId: this.config.firebaseConfig.messagingSenderId
    });
    this.refreshSettings();
    this.refreshProfile();
    // this.router.navigate([this.profileMe ? 'dashboard' : 'login']);
    this.router.navigate([this.profileMe ? 'transactions' : 'login']);

    let defaultLang = window.localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
    this.globalize(defaultLang);

  }

  globalize(languagePriority) {
    this.translate.setDefaultLang("en");
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = "rtl";
        break;
      }
      default: {
        this.rtlSide = "ltr";
        break;
      }
    }
  }
  refreshSettings() {
    this.apiService.getSettings().subscribe(res => { Helper.setSettings(res); this.apiService.reloadSetting(); }, err => console.log('getSettings', err));
  }

  refreshProfile() {
    this.profileMe = Helper.getProfile();
    this.apiService.getProfile().subscribe(resProfile => {
      if (resProfile && resProfile.user && resProfile.name && resProfile.name.length) {
        Helper.setProfile(resProfile);
        this.profileMe = resProfile;
      } else { }
      this.registerOrderUpdates();
    }, err => {
      console.log("getProfile", err);
      if (err.status && err.status == 401) {
        this.profileMe = null;
        if (Helper.getProfile()) this.translate.get("profile_loggedout").subscribe(res => this.uiElementService.showErrorToastr(res, "toast-top-right", 3000));
        this.doLogout();
        this.router.navigate(['login']);
      }
    });
  }

  menu() {
    $("#header").toggleClass("active");
    this.showHeader = !this.showHeader;
  }
  dashboard1(event) {
    this.router.navigateByUrl('dashboard');
    this.menuActive = event;
  }
  dashboard(event) {
    this.menuActive = event;
    if (!this.menuActive || this.showHeader) this.menu();
    this.router.navigateByUrl('dashboard');
  }
  home(event) {
    this.menuActive = event;
    if (!this.menuActive || this.showHeader) this.menu();
    this.router.navigateByUrl('home');
  }
  transactions(event) {
    this.menuActive = event;
    if (!this.menuActive || this.showHeader) this.menu();
    this.router.navigateByUrl('transactions');
  }
  booking(event) {
    this.menuActive = event;
    if (!this.menuActive || this.showHeader) this.menu();
    this.router.navigateByUrl('booking');
  }
  orders_status(event) {
    this.menuActive = event;
    if (!this.menuActive || this.showHeader) this.menu();
    this.router.navigateByUrl('orders_status');
  }
  people(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('people');
  }
  wallet(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('wallet');
  }
  items(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('items');
  }
  addons(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('addons');
  }
  reviews(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('reviews');
  }
  authentication(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('authentication');
  }
  setting(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('setting');
  }
  support(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('support');
  }
  terms_conditions(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('terms_conditions');
  }
  login() {
    this.menu();
    this.translate.get(["logout_title", "logout_message", "no", "yes"]).subscribe(values => {
      this.confirmationDialogService.confirm(values["logout_title"], values["logout_message"], values['yes'], values['no']).then((confirmed) => {
        if (confirmed) {
          this.doLogout();
          this.myEventsService.setLoginData(null);
        }
      }).catch(() => { console.log('err') });
    });
  }
  change_language(event) {
    this.menuActive = event;
    this.menu();
    this.router.navigateByUrl('change_language');
  }
  // private doLogout() {
  //   try {
  //     (<any>window).FirebasePlugin.signOutUser(function () {
  //       console.log("User signed out");
  //     }, function (error) {
  //       console.error("Failed to sign out user: " + error);
  //     });
  //   } catch (e) { console.log("fireSignout", e); }

  //   try {
  //     firebase.auth().signOut().then(function () {
  //       console.log('Signed Out');
  //     }, function (error) {
  //       console.error('Sign Out Error', error);
  //     });
  //   } catch (e) { console.log("fireSignout", e); }
  //   Helper.setLoggedInUserResponse(null);
  // }
  private doLogout() {
    try {
      // Clear any user-related data from local storage or session storage
      localStorage.removeItem('userToken'); // Example: Remove user token from local storage
      sessionStorage.clear(); // Example: Clear all session storage data
  
      console.log("User signed out");
    } catch (e) {
      console.error("Failed to sign out user: " + e);
    }
  
    // Optionally, you can perform additional cleanup or API calls here
    
    // Update UI or navigate to logout page if needed
    
    // Clear any user-related data stored in the application
    Helper.setLoggedInUserResponse(null);
  }
  
  private registerOrderUpdates() {
    this.unRegisterUpdates();
    const component = this;
    this.subscriptions.push(this.apiService.getOrders(this.profileMe.id, 1, null, null, 1).subscribe(res => {
      if (res.data && res.data.length) {
        this.latestOrder = res.data[0];
      }
      this.myOrdersRef = firebase.database().ref("vendors").child(String(this.profileMe.id)).child("orders");
      this.myOrdersRef.on('child_added', function (data) {
        var fireOrder = data.val() as { data: Order };
        // console.log("child_added", fireOrder);
        if (fireOrder.data != null) component.addFireOrder(fireOrder.data);
      });
    }, err => { }));
  }

  unRegisterUpdates() {
    if (this.myOrdersRef != null) {
      this.myOrdersRef.off();
      this.myOrdersRef = null;
    }
  }

  addFireOrder(newOrder: Order) {
    if (!(newOrder.status == "new" || newOrder.status == "pending")) return;
    if (!this.latestOrder || this.latestOrder.id < newOrder.id) {
      this.apiService.setupOrder(newOrder);
      this.latestOrder = newOrder;
      // this.translate.get("printing_order_id").subscribe(value => this.uiElementService.showSuccessToastr(`${value} ${this.latestOrder.id}`));
      // this.printReceipt(this.latestOrder);
    }
  }

  printReceipt(order: Order) {
    if (this.config.printerConfig.enabled) {
      let savedNetworkConfig = Helper.getPrinterNetworkConfig();
      if (savedNetworkConfig) {
        if (savedNetworkConfig.name && savedNetworkConfig.name.trim().length) {
          this.printerServide.getPrinters().subscribe(res => {
            console.log("getPrinters: ", res);
            if (res.indexOf(savedNetworkConfig.name.trim()) == -1) {
              this.translate.get("unable_find_printer").subscribe(value => this.uiElementService.showErrorToastr(value.replace("<name>", savedNetworkConfig.name.trim())));
            } else {
              this.printWith({ name: savedNetworkConfig.name.trim(), host: null, port: null }, order);
            }
          }, err => {
            console.log("getPrinters: ", err);
            this.translate.get("unable_load_printers").subscribe(value => this.uiElementService.showErrorToastr(value));
          });
        } else if (savedNetworkConfig.host && savedNetworkConfig.host.trim().length && savedNetworkConfig.port && savedNetworkConfig.port.trim().length) {
          this.printWith({ name: null, host: savedNetworkConfig.host.trim(), port: savedNetworkConfig.port.trim() }, order);
        } else {
          this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
        }
      } else {
        this.translate.get("invalid_printer_config").subscribe(value => this.uiElementService.showErrorToastr(value));
      }
      //this.printerServide.printData(savedNetworkConfig ? savedNetworkConfig : this.config.printerConfig.network, [Helper.getReceiptFormat(order)]).subscribe(res => console.log("printData: ", res), err => console.log("printData: ", err));
    }
  }

  private printWith(networkConfig, order) {
    if (this.config.printerConfig.format == PrinterFormat.ZPL) {
      this.printerServide.printData(networkConfig, [Helper.getZplReceiptFormat(order)]).subscribe(res => console.log("printData: ", res), err => {
        console.log("printData: ", err);
        this.translate.get("unable_print").subscribe(value => this.uiElementService.showErrorToastr(value));
      });
    } else {
      this.apiService.getReceiptFormat(order.id).subscribe(res => {
        this.printerServide.printData(networkConfig, [res]).subscribe(res => console.log("printData: ", res), err => {
          console.log("printData: ", err);
          this.translate.get("unable_print").subscribe(value => this.uiElementService.showErrorToastr(value));
        });
      }, err => {
        console.log("getReceiptFormat: ", err);
        this.translate.get("something_wrong").subscribe(value => this.uiElementService.showErrorToastr(value));
      });
    }
  }

}

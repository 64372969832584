<div id="page_items">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="page_title">
            <div class="row align-items-center mx-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
                    <div class="title_inner d-flex">
                        <h1 class="d-flex align-items-center">{{'customers' | translate}}
                            <!--                            <span class="ml-4">$987.50</span>-->
                        </h1>
                        <!-- <button type="button" class="btn" (click)="addNewCustomer()"><a>{{'add_new' |
                                translate}}</a></button> -->
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
                    <form class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-3 mt-lg-0 pb-3 pb-md-0 ml-auto">
                        <div class="form-group d-flex">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                            </div>
                            <input type="text" class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText" (ngModelChange)="search()">
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
        <!-- Right Sidebar Start -->
        <div class="right_sidebar">
            <!-- Tab Content Start -->
            <div class="tab-content" id="nav-tabContent">

                <!-- Food Items Tab customers Start -->
                <div class="order_list_container">
                    <!-- Order List Start -->
                    <div class="order_list">
                        <div class="list_header d-flex">
                            <h2 class="text-left Name">{{'name' | translate}}</h2>
                            <h2 class="text-left phone">{{'phone_number' | translate}}</h2>
                            <h2 class="text-left email">{{'email_address' | translate}}</h2>
                            <h2 class="text-left created">{{'added_on' | translate}}</h2>
                            <!-- <h2 class="text-right Action people">{{'action' | translate}}</h2> -->
                        </div>

                        <ul>
                            <div class="empty-view animate__animated animate__fadeInUp" *ngIf="!isLoading && (!customers || !customers.length)">
                                <div style="text-align:center;">
                                    <img src="assets/images/empty_dp.png" alt="no offers" />
                                    <span style="color:#9E9E9E; font-weight:bold;">
                                        {{'empty_customers' | translate}}
                                    </span>
                                </div>
                            </div>
                            <li *ngFor="let customer of customers | paginate : { itemsPerPage: pageSize, currentPage: pageNo, totalItems: pageTotal};let i = index" class="d-flex  animate__animated animate__fadeInUp wow">
                                <!--                                <h3 class="text-center order_num Code people">1</h3>-->
                                <h3 class="text-left Name"><strong>{{customer.name}}</strong></h3>
                                <h3 class="text-left phone">{{customer.mobile_number}}</h3>
                                <h3 class="text-left email">{{customer.email}}</h3>
                                <h3 class="text-left text-muted created">{{customer.created_at}}</h3>
                                <div class="btn_container d-flex mr-0 ml-auto">
                                    <!-- <button type="button" class="btn" (click)="alertDeleteCustomer(customer)">
                                        <a><i class="zmdi zmdi-delete"></i></a>
                                    </button> -->
                                    <!-- <button type="button" class="btn" (click)="editCustomer(customer)">
                                        <a><i class="zmdi zmdi-edit"></i></a>
                                    </button> -->
                                </div>
                            </li>

                        </ul>
                    </div>
                    <!-- Order List End -->
                </div>

                <!-- Tab Footer start -->
                <div *ngIf="pageTotal > pageSize" class="tab_footer">
                    <div class="row no-gutter align-items-center">
                        <div class="col-12 col-md-12 col-lg-4 pb-3">
                            <h2>{{'showing' | translate}} {{pageNo}} {{'to' | translate}} {{pageSize}} {{'of' | translate}} {{pageTotal}} {{'item' | translate}}</h2>
                        </div>
                        <div class="col-12 col-md-12 col-lg-8 pb-3">
                            <div class="row align-items-center">
                                <nav class="navigation col-12" aria-label="Page navigation example">
                                    <pagination-controls responsive="true" [previousLabel]="'prev' | translate" [nextLabel]="'next' | translate" (pageChange)="onChangePage($event)">
                                    </pagination-controls>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Footer End -->
            </div>
            <!-- Tab Content End -->
        </div>
        <!-- Right Sidebar End -->
    </div>
    <!-- Body Wrapper End -->


    <!-- Add people  Modal Start  -->
    <div class="modal fade add_category_model add_expenses receipt_model px-0" id="add_people" tabindex="-1" role="dialog" aria-labelledby="receipt_modelTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header px-0">
                    <h2 *ngIf="customerId" class="col-11 mx-auto">{{ 'edit_customer' | translate}} </h2>
                    <h2 *ngIf="!customerId" class="col-11 mx-auto">{{ 'add_customer' | translate}} </h2>
                </div>
                <div class="modal-body p-0">
                    <form class="pt-2 pt-sm-2 pt-md-4 ">
                        <div class="col-11 mx-auto form_container">
                            <div class="form-group animate__animated animate__fadeInUp " data-wow-duration=".5s">
                                <label>{{'full_name' | translate}}</label>
                                <input type="text" class="form-control" name="customer_name" [(ngModel)]="customerRequest.customer_name" placeholder="{{'full_name' | translate}}">
                            </div>
                            <!-- <div class="form-group animate__animated animate__fadeInUp " data-wow-duration="1s">
                                <label>{{'select_country' | translate}}</label>
                                <select name="countryCode" [(ngModel)]="customerRequest.meta.countryCode" (change)="changeHint()">
                                    <option value="" disabled="" selected>{{'select_country' | translate}}</option>
                                    <option *ngFor="let country of countries" [value]="country.callingCodes[0]">
                                        {{country.name}}</option>
                                </select>
                            </div> -->
                            <div class="form-group animate__animated animate__fadeInUp " data-wow-duration="1s">
                                <label>{{'phone_number' | translate}}</label>
                                <input type="number" class="form-control" name="customer_mobile" [(ngModel)]="customerRequest.meta.phoneNumber" placeholder="{{phoneNumberHint}}">
                            </div>
                            <div class="form-group animate__animated animate__fadeInUp " data-wow-duration="1.5s">
                                <label>{{'email_address' | translate}}</label>
                                <input type="email" class="form-control" name="customer_email" [(ngModel)]="customerRequest.customer_email" placeholder="{{'email_address' | translate}}">
                            </div>
                        </div>

                        <div class="modal-footer animate__animated animate__fadeInUp" data-wow-duration="1s">
                            <div class="row no-gutters w-100">
                                <div class="col-6"> <button type="file" class="btn Cencel" (click)="dismissModel()"><a>{{'cencel' | translate}}</a></button></div>
                                <div class="col-6">
                                    <button type="file" class="btn" (click)="onAddCustomerRequest()"><a>{{'add_customer' | translate}}</a></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    <!-- Add people Modal End  -->

</div>

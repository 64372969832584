<div id="page_dashboard">

    <!-- Body Wrapper Start -->
    <div class="body_wrapper pt-0">
        <div class="container-fluid  align-items-start p-0">
            <div class="page_title">
                <div class="row align-items-center mx-0">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
                        <div class="title_inner d-flex">
                            <h1 class="d-flex align-items-center">{{'dashboard' | translate}}
                                <!--                            <span class="ml-4">$987.50</span>-->
                            </h1>
                            <!--                            <button type="button" class="btn"><a href="upload_item.html">Add New</a></button>-->
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 d-flex">
                        <form class="search_box col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 p-0 px-lg-3 mt-0 mt-lg-0 pb-3 pb-md-0 ml-auto">
                            <div class="form-group mt-3 mb-0">
                                <div class="select_box  d-flex">
                                    <select name="duration" [(ngModel)]="duration" (change)="loadInsight()">
                                        <option value="today">{{'today' | translate}}</option>
                                        <option value="week">{{'this_week' | translate}}</option>
                                        <option value="month">{{'this_month' | translate}}</option>
                                        <option value="year">{{'this_year' | translate}}</option>
                                    </select>
                                    <i class="zmdi zmdi-caret-down"></i>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div class="right-dasboard">
                <div class="total_box">
                    <div class="row">
                        <div class="col-12 col-md-4 ">
                            <div class="chart-area py-5 py-sm-5 py-md-5 py-lg-5 px-5 d-flex align-items-center animate__animated animate__zoomIn wow" data-wow-duration=".5s">
                                <img src="assets/images/ic_energy.png" class="img-fluid">
                                <div class="text_box">
                                    <h3>{{'orders' | translate}}</h3>
                                    <h2>{{insightsOrder.orders}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="chart-area py-5 py-sm-5 py-md-5 py-lg-5 px-5  d-flex align-items-center animate__animated animate__zoomIn wow" data-wow-duration=".9s">
                                <img src="assets/images/ic_cutlery.png" class="img-fluid">
                                <div class="text_box">
                                    <!-- <h3>{{'items_sold' | translate}}</h3>
                                            <h2>678</h2> -->
                                    <h3>{{'items_sold' | translate}}</h3>
                                    <h2>{{insightsOrder.items_sold}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="chart-area py-5 py-sm-5 py-md-5  py-lg-5 px-lg-3 px-xl-5 px-5  d-flex align-items-center animate__animated animate__zoomIn wow" data-wow-duration=".7s">
                                <img src="assets/images/ic_Serving.png" class="img-fluid">
                                <div class="text_box">
                                    <h3>{{'revenue' | translate}}</h3>
                                    <h2>{{currency_icon}}{{insightsOrder.revenue}}</h2>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6">
                                    <div class="chart-area py-5 py-sm-5 py-md-5 py-lg-5 px-5  d-flex align-items-center animate__animated animate__zoomIn wow"
                                        data-wow-duration="1s">
                                        <img src="assets/images/ic_energy.png" class="img-fluid">
                                        <div class="text_box">
                                            <h3>Total Orders</h3>
                                            <h2>{{insightsOrder.orders}}</h2>
                                        </div>
                                    </div>
                                </div> -->
                    </div>
                </div>



                <div class="chart-area p-3 p-xl-4 animate__animated animate__zoomIn wow text-white" data-wow-duration=".9s">
                    <div class="row no-gutters align-items-center ">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12">
                            <div class="menus pb-2">
                                <ul class="d-flex list-unstyled text-muted m-0">
                                    <li [ngClass]="showChartType == 'orders'?'active':''" (click)="chartParsed('orders')">
                                        {{'orders' | translate}}
                                    </li>
                                    <li [ngClass]="showChartType == 'sales'?'active':''" (click)="chartParsed('sales')">
                                        {{'items_sold' | translate}}
                                    </li>
                                    <li [ngClass]="showChartType == 'earnings'?'active':''" (click)="chartParsed('earnings')">
                                        {{'earnings' | translate}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showChartType == 'orders'" class="graph_container d-flex">
                        <div class="text_container">
                            <p *ngFor="let pr of priceRangeOrder" class="ion-text-end"><span>{{pr}}</span></p>
                        </div>
                        <div class="graph end">
                            <div *ngFor="let cd of chartDataParsedOrder" class="bar_box" [ngClass]="{'active': cd.percent > 0 && cd.percent <= 100}">
                                <h4>{{cd.date}}</h4>
                                <div class="bar" [style.height]="cd.percent+'%'">
                                    <div class="icon_box">
                                        <i class="zmdi zmdi-circle"></i>
                                    </div>
                                    <h5>{{cd.price}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showChartType == 'sales'" class="graph_container d-flex">
                        <div class="text_container">
                            <p *ngFor="let pr of priceRangeItems" class="ion-text-end"><span>{{pr}}</span></p>
                        </div>
                        <div class="graph end">
                            <div *ngFor="let cd of chartDataParsedItems" class="bar_box" [ngClass]="{'active': cd.percent > 0 && cd.percent <= 100}">
                                <h4>{{cd.date}}</h4>
                                <div class="bar" [style.height]="cd.percent+'%'">
                                    <div class="icon_box">
                                        <ion-icon class="zmdi zmdi-circle"></ion-icon>
                                    </div>
                                    <h5>{{cd.price}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showChartType == 'earnings'" class="graph_container d-flex">
                        <div class="text_container">
                            <p *ngFor="let pr of priceRangeEarning" class="ion-text-end"><span>{{pr}}</span></p>
                        </div>
                        <div class="graph end">
                            <div *ngFor="let cd of chartDataParsedEarning" class="bar_box" [ngClass]="{'active': cd.percent > 0 && cd.percent <= 100}">
                                <h4>{{cd.date}}</h4>
                                <div class="bar" [style.height]="cd.percent+'%'">
                                    <div class="icon_box">
                                        <i class="zmdi zmdi-circle"></i>
                                    </div>
                                    <h5>{{cd.price}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="total_box">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="chart-area top-selling-items p-4 p-xl-5 pb-5">
                                <h4>{{'orders_from' | translate}}</h4>

                                <div *ngFor="let summary of insightsOrderTypes.summary" class="progress-bar-box d-block d-md-flex align-items-center mb-1 animate__animated animate__zoomIn wow">
                                    <div class="img_box pb-3">
                                        <img *ngIf="summary.order_type == 'TAKEAWAY'" src="assets/images/ic_takeaway.png">
                                        <img *ngIf="summary.order_type == 'NORMAL'" src="assets/images/ic_delivery.png">
                                        <img *ngIf="summary.order_type == 'DINEIN'" src="assets/images/ic_dine_in.png">
                                    </div>
                                    <div class="bar-box d-flex w-100 align-items-center">
                                        <span class="h6 m-0">{{ ('order_type_'+summary.order_type.toLowerCase()) |
                                            translate}}</span>
                                        <div class="progress" style="height: 20px;width: 100%">
                                            <div class="progress-bar bg-success" role="progressbar" [style.width]="summary.percent+'%'" [aria-valuenow]="summary.percent" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <span style="min-width: 100px;" class="text-right">{{summary.total}}
                                            <span>({{summary.percent}}%)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-5" style="padding: 0 20px">
                            <div class="row align-items-center chart-area doughnut_chart p-3 p-sm-3 p-md-5 px-lg-3 px-xl-4">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-2 py-0 animate__animated animate__zoomIn wow" data-wow-duration=".5s">
                                    <canvas id="doughnut-chart" width="326" height="326"></canvas>
                                </div>

                                <div class="row pb-0  py-0 col-12 col-sm-12 col-md-6 col-lg-6 pl-md-5  pl-lg-3 pl-xl-5">
                                    <div *ngFor="let category of insightsCategory.summary" class="col-12 animate__animated animate__fadeInUp wow">
                                        <h2 class="d-flex m-0 py-2">
                                            <i class="zmdi zmdi-circle mr-2" [style.color]="category.color"></i> {{category.category_id}} ({{category.percent}}%)
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="total_box">
                    <div class="row">
                        <div *ngIf="productsBestSelling && productsBestSelling.length" class="col-12 col-sm-12 col-md-12 col-lg-5">
                            <div class="chart-area top-selling-items p-4 p-xl-5">
                                <h4>{{'products_best_seller' | translate}}</h4>
                                <div *ngFor="let product of productsBestSelling" class="media w-100 d-flex align-items-start pb-3">
                                    <div class="img_box center_img">
                                        <img [src]="product.image_urls[0]" class="crop_img" alt="">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="pb-1">{{product.title}}</h5>
                                        <h6>{{product.sells_count ? product.sells_count : 0}} {{'sales' | translate}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div *ngIf="feedbackDataParsed" class="chart-area reviews top-selling-items p-4 p-xl-5 pb-5">
                                <div class="row no-gutters">
                                    <div class="col-6 col-md-6" style="padding:0 10px;">
                                        <h4>{{'reviews' | translate}}</h4>
                                    </div>
                                    <div class="col-6 col-md-6" style="padding:0 10px;">
                                        <h4 class="text-right" style="color: var(--text-light); font-size: 1.1rem;">
                                            {{feedbackDataParsed.total}} {{'reviews' | translate}}
                                        </h4>
                                    </div>
                                </div>
                                <div class="reviews-info p-0 col-12 col-lg-11">
                                    <div *ngFor="let question of feedbackDataParsed.data" class="types-of-reviews">
                                        <h3>{{question.feedback_question_title}}</h3>
                                        <div class="row">
                                            <div *ngFor="let option of question.options" class="col-12 col-md-6 col-lg-3 d-flex align-items-center animate__animated animate__fadeInUp wow">
                                                <img *ngIf="option.rank == 1" src="assets/images/loved_it.gif">
                                                <img *ngIf="option.rank == 2" src="assets/images/good.gif">
                                                <img *ngIf="option.rank == 3" src="assets/images/average.gif">
                                                <img *ngIf="option.rank == 4" src="assets/images/not_good.gif">
                                                <h6>{{option.percent}}%</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Constants } from 'src/models/constants.models';
import { Helper } from 'src/models/helper.models';
import { CategoryInsight } from 'src/models/insight-category.models';
import { EarningInsight } from 'src/models/insight-earning.models';
import { OrderTypeInsight } from 'src/models/insight-order-type.models';
import { OrderInsight } from 'src/models/insight-order.models';
import { Product } from 'src/models/product.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { Profile } from 'src/models/profile.models';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  private profileMe: Profile;
  currency_icon: string;
  duration = 'today';
  insightsEarning: EarningInsight;
  priceRangeEarning: Array<number>;
  chartDataParsedEarning: Array<{
    date: string;
    percent: string;
    price: string;
  }>;

  insightsOrder: OrderInsight;
  priceRangeOrder: Array<number>;
  chartDataParsedOrder: Array<{ date: string; percent: string; price: string }>;
  priceRangeItems: Array<number>;
  chartDataParsedItems: Array<{ date: string; percent: string; price: string }>;
  audio = new Audio();

  insightsOrderTypes: OrderTypeInsight;
  insightsCategory: CategoryInsight;
  feedbackDataParsed: {
    total: number;
    data: Array<{
      feedback_question_id: number;
      feedback_question_title: string;
      options: Array<{ rank: number; total: number; percent: string }>;
    }>;
  };

  isLoading: boolean;
  productsBestSelling: Array<Product>;

  private deliveryStats = new Array<{ distance: number; duration: number }>();
  distance_metric = 'km';
  showChartType: string = 'orders';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private uiElementService: UiElementsService,
    private apiService: ApiService,
    private spinnerService: NgxSpinnerService
  ) {
   // this.audio.src = '../../../assets/sound/ring.mp3';
  }

  ngOnInit() {
    this.currency_icon = Helper.getSetting('currency_icon');
    this.profileMe = Helper.getProfile();
    this.setupDefaultInsightsEarning();
    this.setupDefaultInsightsOrder();
    this.setupDefaultInsightsItems();
    this.setupDefaultInsightsOrderTypes();
    this.setupDefaultInsightsCategory();
    this.setupDefaultInsightsFeedback();
    this.loadInsight();
    setInterval(() => {
      if (this.router.url.includes('dashboard')) {
        this.loadInsight();
        this.spinnerService.hide();
      }
    }, 60000);
    this.loadProductsByType();
    //this.loadDeliverySummary();
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  setupDefaultInsightsOrderTypes() {
    this.insightsOrderTypes = new OrderTypeInsight();
  }

  setupDefaultInsightsCategory() {
    this.insightsCategory = new CategoryInsight();
    this.loadCategoryChart();
  }

  setupDefaultInsightsFeedback() {}

  setupDefaultInsightsOrder() {
    let defaultOrderInsight = new OrderInsight();
    if (!this.insightsOrder) this.insightsOrder = defaultOrderInsight;
    this.insightsOrder.chart_data = defaultOrderInsight.chart_data;

    let maxEarning = 100;
    let breaker = maxEarning / 5;
    this.priceRangeOrder = [];
    for (let i = 0; i <= 5; i++)
      this.priceRangeOrder.push(
        Number(
          (i == 0 ? maxEarning : this.priceRangeOrder[i - 1] - breaker).toFixed(
            2
          )
        )
      );
    this.chartDataParsedOrder = [];
    for (let cd of this.insightsOrder.chart_data)
      this.chartDataParsedOrder.push({
        date: '00:00',
        percent: String((Number(cd.total) * 100) / maxEarning),
        price: '0',
      });
  }

  setupDefaultInsightsItems() {
    let defaultOrderInsight = new OrderInsight();
    if (!this.insightsOrder) this.insightsOrder = defaultOrderInsight;
    this.insightsOrder.items_sold_chart = defaultOrderInsight.items_sold_chart;

    let maxItemsSold = 100;
    let breaker = maxItemsSold / 5;
    this.priceRangeItems = [];
    for (let i = 0; i <= 5; i++)
      this.priceRangeItems.push(
        Number(
          (i == 0
            ? maxItemsSold
            : this.priceRangeItems[i - 1] - breaker
          ).toFixed(2)
        )
      );
    this.chartDataParsedItems = [];
    for (let cd of this.insightsOrder.chart_data)
      this.chartDataParsedItems.push({
        date: '00:00',
        percent: String((Number(cd.total) * 100) / maxItemsSold),
        price: '0',
      });
  }

  setupDefaultInsightsEarning() {
    this.insightsEarning = new EarningInsight();
    if (
      this.insightsEarning &&
      this.insightsEarning.earnings_chart_data.length
    ) {
      let maxEarning = 100;
      let breaker = maxEarning / 5;
      this.priceRangeEarning = [];
      for (let i = 0; i <= 5; i++)
        this.priceRangeEarning.push(
          Number(
            (i == 0
              ? maxEarning
              : this.priceRangeEarning[i - 1] - breaker
            ).toFixed(2)
          )
        );
      this.chartDataParsedEarning = [];
      for (let cd of this.insightsEarning.earnings_chart_data)
        this.chartDataParsedEarning.push({
          date: '00:00',
          percent: String((Number(cd.total) * 100) / maxEarning),
          price: '0',
        });
    }
  }

  loadInsight() {
    this.loadInsightEarnings();
    this.loadInsightOrders();
    this.loadInsightOrdersTypes();
    this.loadInsightCategory();
    this.loadInsightFeedback();
  }

  loadInsightEarnings() {
    this.translate.get('loading').subscribe((value) => {
      this.uiElementService.presentLoading(value);
      let insightRequest = this.getInsightRequest();
      this.subscriptions.push(
        this.apiService.getInsightEarning(insightRequest).subscribe(
          (res) => {
            if (!res.total_earnings) res.total_earnings = 0;
            res.total_earnings = Number(Number(res.total_earnings).toFixed(2));
            this.insightsEarning = res;
            if (
              this.insightsEarning &&
              this.insightsEarning.earnings_chart_data.length
            ) {
              let maxEarning = 0;
              for (let cd of this.insightsEarning.earnings_chart_data)
                if (Number(cd.total) > maxEarning)
                  maxEarning = Number(cd.total);
              let breaker = maxEarning / 5;
              this.priceRangeEarning = [];
              for (let i = 0; i <= 5; i++)
                this.priceRangeEarning.push(
                  Number(
                    (i == 0
                      ? maxEarning
                      : this.priceRangeEarning[i - 1] - breaker
                    ).toFixed(2)
                  )
                );
              this.chartDataParsedEarning = [];
              for (let cd of this.insightsEarning.earnings_chart_data)
                this.chartDataParsedEarning.push({
                  date: this.getPeriodText(cd.period, insightRequest),
                  percent: String((Number(cd.total) * 100) / maxEarning),
                  price:
                    this.currency_icon +
                    ' ' +
                    Number(cd.total ? cd.total : 0).toFixed(),
                });
            } else {
              this.setupDefaultInsightsEarning();
            }
            this.uiElementService.dismissLoading();
          },
          (err) => {
            console.log('insights', err);
            this.uiElementService.dismissLoading();
          }
        )
      );
    });
  }

  loadInsightOrdersTypes() {
    let insightRequest = this.getInsightRequest();
    this.subscriptions.push(
      this.apiService
        .getInsightOrderTypes(this.profileMe.id, insightRequest)
        .subscribe(
          (res) => {
            this.insightsOrderTypes = res;
            if (
              !this.insightsOrderTypes.summary ||
              this.insightsOrderTypes.summary.length != 3
            ) {
              this.insightsOrderTypes.summary = OrderTypeInsight.fillRest(
                this.insightsOrderTypes.summary
              );
            }
            let totalOrders = 0;
            for (let summary of this.insightsOrderTypes.summary)
              totalOrders += Number(summary.total);
            if (totalOrders != 0)
              for (let summary of this.insightsOrderTypes.summary)
                summary.percent = Number(
                  (Number(summary.total) / totalOrders) * 100
                ).toFixed(0);
          },
          (err) => console.log('getInsightOrderTypes', err)
        )
    );
  }

  loadInsightCategory() {
    let insightRequest = this.getInsightRequest();
    this.subscriptions.push(
      this.apiService
        .getInsightCategory(this.profileMe.id, insightRequest)
        .subscribe(
          (res: any) => {
            this.insightsCategory = res;
            if (res?.orders && res?.orders > 0) {
              //this.alertModule = true;
              $('#exampleModal').modal('show');

              this.audio.load();
              this.audio.play();

              //this.insightsCategory.summary = new CategoryInsight().summary;
            }
            if (
              !this.insightsCategory.summary ||
              !this.insightsCategory.summary.length
            ) {
              this.insightsCategory.summary = new CategoryInsight().summary;
            }
            let totalCategories = 0;
            let colors = [
              '#EB1E1E',
              '#009946',
              '#F09514',
              '#8E37E7',
              '#F02899',
              '#898989',
              '#33C5FF',
              '#3337F0',
            ];
            let colorIndex = 0;
            for (let summary of this.insightsCategory.summary)
              totalCategories += Number(summary.total);
            for (let summary of this.insightsCategory.summary) {
              summary.percent = Number(
                (Number(summary.total) / totalCategories) * 100
              ).toFixed(0);
              if (colorIndex == colors.length) colorIndex = 0;
              summary.color = colors[colorIndex];
              colorIndex++;
            }
            this.loadCategoryChart();
          },
          (err) => console.log('getInsightCategory', err)
        )
    );
  }

  loadInsightFeedback() {
    let insightRequest = this.getInsightRequest();
    this.subscriptions.push(
      this.apiService
        .getInsightFeedback(this.profileMe.id, insightRequest)
        .subscribe(
          (res) => {
            if (res.summary && res.summary.length) {
              let dataParsed: Array<{
                feedback_question_id: number;
                feedback_question_title: string;
                options: Array<{
                  rank: number;
                  total: number;
                  percent: string;
                }>;
              }> = [];

              for (let summary of res.summary) {
                let existingIndex = -1;
                for (let i = 0; i < dataParsed.length; i++) {
                  if (
                    dataParsed[i].feedback_question_id ==
                    summary.feedback_question_id
                  ) {
                    existingIndex = i;
                    break;
                  }
                }
                if (existingIndex == -1) {
                  dataParsed.push({
                    feedback_question_id: summary.feedback_question_id,
                    feedback_question_title: summary.feedback_question_title,
                    options: [
                      { rank: 1, total: 0, percent: '0' },
                      { rank: 2, total: 0, percent: '0' },
                      { rank: 3, total: 0, percent: '0' },
                      { rank: 4, total: 0, percent: '0' },
                    ],
                  });
                }
                for (let option of dataParsed[
                  existingIndex == -1 ? dataParsed.length - 1 : existingIndex
                ].options) {
                  if (option.rank == summary.rank) {
                    option.total += summary.total;
                    break;
                  }
                }
              }
              let totalReviews = 0;
              for (let data of dataParsed) {
                let questionTotal = 0;
                for (let option of data.options) questionTotal += option.total;
                for (let option of data.options)
                  option.percent = Number(
                    (option.total / questionTotal) * 100
                  ).toFixed(0);
                totalReviews += questionTotal;
              }
              this.feedbackDataParsed = {
                total: totalReviews,
                data: dataParsed,
              };
            }
          },
          (err) => console.log('getInsightCategory', err)
        )
    );
  }

  loadInsightOrders() {
    let insightRequest = this.getInsightRequest();
    this.subscriptions.push(
      this.apiService
        .getInsightOrder(this.profileMe.id, insightRequest)
        .subscribe(
          (res) => {
            this.insightsOrder = res;
            if (this.insightsOrder && this.insightsOrder.chart_data.length) {
              let maxEarning = 0;
              for (let cd of this.insightsOrder.chart_data)
                if (Number(cd.total) > maxEarning)
                  maxEarning = Number(cd.total);
              let breaker = maxEarning / 5;
              this.priceRangeOrder = [];
              for (let i = 0; i <= 5; i++)
                this.priceRangeOrder.push(
                  Number(
                    (i == 0
                      ? maxEarning
                      : this.priceRangeOrder[i - 1] - breaker
                    ).toFixed(2)
                  )
                );
              this.chartDataParsedOrder = [];
              for (let cd of this.insightsOrder.chart_data)
                this.chartDataParsedOrder.push({
                  date: this.getPeriodText(cd.period, insightRequest),
                  percent: String((Number(cd.total) * 100) / maxEarning),
                  price: Number(cd.total ? cd.total : 0).toFixed(),
                });
            } else {
              this.setupDefaultInsightsOrder();
            }

            if (
              this.insightsOrder &&
              this.insightsOrder.items_sold_chart.length
            ) {
              let maxItemsSold = 0;
              for (let cd of this.insightsOrder.items_sold_chart)
                if (Number(cd.total) > maxItemsSold)
                  maxItemsSold = Number(cd.total);
              let breaker = maxItemsSold / 5;
              this.priceRangeItems = [];
              for (let i = 0; i <= 5; i++)
                this.priceRangeItems.push(
                  Number(
                    (i == 0
                      ? maxItemsSold
                      : this.priceRangeItems[i - 1] - breaker
                    ).toFixed(2)
                  )
                );
              this.chartDataParsedItems = [];
              for (let cd of this.insightsOrder.items_sold_chart)
                this.chartDataParsedItems.push({
                  date: this.getPeriodText(cd.period, insightRequest),
                  percent: String((Number(cd.total) * 100) / maxItemsSold),
                  price: Number(cd.total ? cd.total : 0).toFixed(),
                });
            } else {
              this.setupDefaultInsightsItems();
            }
            this.uiElementService.dismissLoading();
          },
          (err) => {
            console.log('insights', err);
            this.uiElementService.dismissLoading();
          }
        )
    );
  }

  chartParsed(event) {
    this.showChartType = event;
  }

  // loadDeliverySummary() {
  //   let profile = this.profileMe;
  //   this.subscriptions.push(this.apiService.getSummary(String(profile.id)).subscribe(res => {
  //     this.profileSummary = res;
  //     Helper.setProfileSummary(this.profileSummary);
  //   }, err => {
  //     console.log("getSummary", err);
  //   }));
  // }

  navTransactions() {
    this.router.navigate(['/wallet']);
  }

  private getInsightRequest() {
    //  'duration' => 'required|in:hours,days,months,years',
    //  'limit' => 'required|numeric'
    let toReturn = { duration: '', limit: 0 };
    switch (this.duration) {
      case 'today':
        toReturn.duration = 'hours';
        toReturn.limit = 24;
        break;
      case 'week':
        toReturn.duration = 'days';
        toReturn.limit = 7;
        break;
      case 'month':
        toReturn.duration = 'months';
        toReturn.limit = 12;
        break;
      case 'year':
        toReturn.duration = 'years';
        toReturn.limit = 12;
        break;
    }
    return toReturn;
  }

  private getPeriodText(
    period: string,
    ir: { duration: string; limit: number }
  ): string {
    let months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ];
    let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    let toReturn = String(period);
    if (toReturn.includes('-')) {
      return moment(toReturn).format('DD/MM');
    } else {
      switch (ir.duration) {
        case 'hours':
          if (toReturn.length == 1) toReturn = '0' + toReturn;
          if (Number(toReturn)) toReturn = toReturn += ':00';
          break;
        case 'months':
          let index = Number(toReturn);
          toReturn = this.translate.instant(
            months[index > 0 ? index - 1 : index]
          );
          break;
      }
    }
    return toReturn;
  }

  loadProductsByType() {
    this.subscriptions.push(
      this.apiService
        .getBestProducts(
          this.profileMe.id,
          Constants.SCOPE_ECOMMERCE,
          'best-seller'
        )
        .subscribe(
          (res) => {
            let resSorted = res.data.sort((p1: Product, p2: Product) => {
              let p1SalesCount =
                p1.sells_count && Number(p1.sells_count)
                  ? Number(p1.sells_count)
                  : 0;
              let p2SalesCount =
                p2.sells_count && Number(p2.sells_count)
                  ? Number(p2.sells_count)
                  : 0;
              return p1SalesCount < p2SalesCount
                ? 1
                : p1SalesCount > p2SalesCount
                ? -1
                : 0;
            });
            let resToShow = [];
            for (
              let i = 0;
              i < (resSorted.length > 6 ? 6 : resSorted.length);
              i++
            ) {
              resToShow.push(resSorted[i]);
            }
            this.productsBestSelling = resToShow;
            this.uiElementService.dismissLoading();
            this.isLoading = false;
          },
          (err) => {
            console.log('getProductsForTypes', err);
            this.uiElementService.dismissLoading();
            this.isLoading = false;
          }
        )
    );
  }

  loadCategoryChart() {
    let colors = [],
      labels = [],
      percents = [];
    for (let summary of this.insightsCategory.summary) {
      colors.push(summary.color);
      labels.push(summary.category_id + ' (' + summary.percent + '%)');
      percents.push(Number(summary.percent));
    }
    new Chart(document.getElementById('doughnut-chart'), {
      type: 'doughnut',
      legend: {
        position: 'bottom',
      },
      data: {
        labels: labels,
        datasets: [
          {
            //                    label: "Population (millions)",
            backgroundColor: colors,
            data: percents,
            borderWidth: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
          position: 'top',
          labels: {
            fontColor: '#000080',
          },
        },
      },
    });
  }
}

import { Vendor } from "./vendor.models";

export class Customer {
    id: number;
    customer_name: string;
    customer_email: string;
    customer_mobile: string;
    meta: any;
    vendor_id: number;
    vendor: Vendor;
    created_at: string;
    updated_at: string;
}
export class CustomerRequest {
    customer_mobile: string;
    customer_name: string;
    customer_email: string;
    meta: any;
    vendor_id: number;
    constructor() {
        this.meta = { countryCode: null, phoneNumber: null };
    }
}
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseListResponse } from 'src/models/base-list.models';
import { Helper } from 'src/models/helper.models';
import { Product } from 'src/models/product.models';
import { Profile } from 'src/models/profile.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { MyEventsService } from '../services/events/my-events.service';
import { ApiService } from '../services/network/api.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  //  segment = 0;
  //  @ViewChild('slides', { static: true }) slider: IonSlides;
  private subscriptions = new Array<Subscription>();
  products = new Array<Product>();
  private profileMe: Profile;
  isLoading = true;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;


  constructor(private router: Router, private translate: TranslateService, private myEvent: MyEventsService, private uiElementService: UiElementsService,
    private apiService: ApiService, private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.profileMe = Helper.getProfile();

    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.products = [];
      this.loadProducts();
    });
    // this.myEvent.getUpdatePoductObservable().subscribe(product => {
    //   this.translate.get("loading").subscribe(value => {
    //     this.uiElementService.presentLoading(value);
    //     this.products = [];
    //     this.loadProducts();
    //   });
    // });
  }


  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  loadProducts() {
    this.isLoading = true;
    this.subscriptions.push(this.apiService.getProducts(this.profileMe.id, this.pageNo).subscribe(res => this.productsRes(res), err => this.productsErr(err)));
  }

  searchProducts() {
    this.subscriptions.push(this.apiService.getProducts(this.profileMe.id, this.pageNo, this.searchText).subscribe(res => this.productsRes(res), err => this.productsErr(err)));
  }

  alertDeleteProduct(productId) {
    this.translate.get(['delete_title', 'delete_message', 'no', 'yes']).subscribe(text => {
      this.confirmationDialogService.confirm(text['delete_title'], text['delete_message'], text['yes'], text['no']).then((confirmed) => { if (confirmed) this.deleteProduct(productId) }).catch(() => { console.log('err') });
    });
  }

  deleteProduct(productId) {
    this.translate.get("deleting").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.deleteProduct(productId).subscribe(res => {
        this.uiElementService.dismissLoading();
        this.uiElementService.showSuccessToastr(this.translate.instant('delete_title'))
      }, err => {
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr(this.translate.instant('something_wrong'))
      }));
    });
  }

  productsRes(res: BaseListResponse) {
    this.products = res.data;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }

  productsErr(err) {
    console.log("productsErr", err);
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }

  onChangePage(event) {
    this.pageNo = event; this.loadProducts();
  }

  navItemDetail(product) {
    let navigationExtras: NavigationExtras = { state: { product: product, vendorId: this.profileMe.id } };
    this.router.navigate(['./upload_item'], navigationExtras);
  }

  duplicateProduct(item) {
    let navigationExtras: NavigationExtras = { state: { product: item, vendorId: this.profileMe.id } }
    this.router.navigate(["/duplicate_item/", item.id],navigationExtras);
  }

}

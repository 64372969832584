import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseListResponse } from 'src/models/base-list.models';
import { Helper } from 'src/models/helper.models';
import { Profile } from 'src/models/profile.models';
import { Rating } from 'src/models/rating.models';
import { Review } from 'src/models/review.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  // private nextUrl: string;
  isLoading = true;
  rating: Rating;
  reviews = new Array<Review>();
  profile: Profile;
  pageNo = 1;
  pageSize: number;
  pageTotal: number;
  searchText: string;
  readAllText: boolean = false;
  avg_ratings: string;

  constructor(private router: Router, private translate: TranslateService, private uiElementService: UiElementsService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.profile = Helper.getProfile();
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadReviewsVendor();
      // this.subscriptions.push(this.apiService.getRatingSummaryProduct(this.vendor.id).subscribe(res => this.rating = res, err => console.log("getRatingSummaryProduct", err)));
    });
  }

  loadReviewsVendor() {
    this.subscriptions.push(this.apiService.getReviewsVendor(this.profile.id, this.pageNo).subscribe(res => this.reviewsRes(res), err => this.reviewsErr(err)));
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  onChangePage(event) {
    this.pageNo = event;
    this.translate.get("loading").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.loadReviewsVendor();
    });
  }

  search() {
    this.subscriptions.push(this.apiService.getReviewsVendor(this.profile.id, this.pageNo, this.searchText).subscribe(res => this.reviewsRes(res), err => this.reviewsErr(err)));
  }

  read() {
    this.readAllText = !this.readAllText;
  }

  reviewsRes(res: BaseListResponse) {
    this.reviews = res.data;
    this.isLoading = false;
    this.pageNo = res.meta.current_page;
    this.pageSize = res.meta.per_page;
    this.pageTotal = res.meta.total;
    this.uiElementService.dismissLoading();
    if (this.reviews && this.reviews.length) {
      this.avg_ratings = Number(this.reviews[0].vendor.ratings).toFixed(2);
    }
  }

  reviewsErr(err) {
    console.log("productsErr", err);
    this.uiElementService.dismissLoading();
    this.isLoading = false;
  }
}

<div id="page_home">
    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <!-- Order Section Start -->
        <div class="order_section">
            <div class="order_item_container">
                <div class="no-order p-4 p-sm-4 p-md-4 p-lg-5" [ngClass]="showAddOrder ? 'active' : ''">
                    <div class="banner_img">
                        <img src="assets/images/img_noorder.png" class="img-fluid">
                    </div>
                    <h2 [innerHTML]="'home_page_text' | translate"></h2>
                    <h3 [innerHTML]="'home_page_text1' | translate"></h3>

                    <!-- <div class="btn_box">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 px-1 px-md-2">
                                <button type="button" class="btn  Add_order" (click)="myCart()"><a>{{'add_order' |
                                        translate}}</a></button>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6  px-1 px-md-2">
                                <button type="button" class="btn order-status"
                                    (click)="appComponent.orders_status('orders_status')"><a>{{'order_status' |
                                        translate}}</a></button>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- Tab Items Start -->
                <div class="item_list" [ngClass]="showMyCart ? 'active': ''">
                    <div class="empty-view animate__animated animate__fadeInUp" *ngIf="!eComService.getCartItems() || !eComService.getCartItems().length" style="position: relative; top: 150px;">
                        <div style="text-align:center">
                            <img src="assets/images/empty_orders.png" />
                            <span style="color:#9E9E9E; font-weight:bold;">
                                {{'empty_cart' | translate}}
                            </span>
                        </div>
                    </div>

                    <div *ngIf="eComService.getCartItems() && eComService.getCartItems().length" class="order_header">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <h2>{{'item' | translate}}</h2>
                            </div>
                            <div class="col-2 text-center">
                                <h2>{{'price' | translate}}</h2>
                            </div>
                            <div class="col-3 text-center">
                                <h2>{{'items_s' | translate}}</h2>
                            </div>
                            <div class="col-3 text-right">
                                <h2>{{'total' | translate}}($)</h2>
                            </div>
                        </div>
                    </div>

                    <!-- Food List Start -->
                    <ul>
                        <li *ngFor="let ci of eComService.getCartItems(); let i = index">
                            <div class="row">
                                <div class="col-4">
                                    <h2>{{ci.title}}</h2>
                                    <h3 class="addOnTitle" *ngFor="let addOn of ci.addOns">{{addOn.title}}</h3>
                                </div>
                                <div class="col-2 text-center">
                                    <h3>{{currency_icon}}{{ci.price}}</h3>
                                    <h4 class="addOnPrice" *ngFor="let addOn of ci.addOns">{{addOn.priceToShow}}</h4>
                                </div>
                                <div class="col-3 text-center">
                                    <h3 class="d-flex align-items-center">
                                        <i (click)="decrementCartItem(ci)" class="zmdi zmdi-minus"></i>
                                        <strong>{{ci.quantity}}</strong>
                                        <i (click)="addOrIncrementCartItem(ci)" class="zmdi zmdi-plus"></i>
                                    </h3>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>{{currency_icon}}{{ci.getTotal(true)}}</h4>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- Food List End -->

                    <div class="order_footer">
                        <div *ngIf="eComService.getCartItems() && eComService.getCartItems().length" class="amount_details">
                            <h2 class="d-flex text-right" *ngFor="let ec of eComService.getExtraCharges()">
                                <span class="text">{{ec.id | translate}} </span>
                                <span class="mr-0 ml-auto">{{currency_icon}}{{eComService.calculateExtraChargePiceToShow(ec,
                                    true)}}</span>
                            </h2>
                            <!-- <h2 class="d-flex text-right">
                                <span class="text">{{'tax' | translate}}</span>
                                <span class="mr-0 ml-auto"> 3.00</span>
                            </h2>
                            <h2 class="d-flex text-right">
                                <span class="text">{{'other_charge' | translate}}</span>
                                <span class="mr-0 ml-auto">0.00</span>
                            </h2> -->
                        </div>
                        <div *ngIf="eComService.getCartItems() && eComService.getCartItems().length" class="amount_payble">
                            <h2 class="d-flex text-right">
                                <span class="text">{{'amount_to_Pay' | translate}}</span>
                                <span class="mr-0 ml-auto">{{currency_icon}}{{eComService.getCartTotal(true)}}</span>
                            </h2>
                        </div>

                        <div class="btn_box">
                            <div class="row no-gutter mx-0">
                                <button type="button" class="btn col-6 Cancel" (click)="cancelCart()">
                                    <a>{{'cancel' | translate}}</a>
                                </button>
                                <button *ngIf="eComService.getCartItems() && eComService.getCartItems().length" type="button" class="btn col-6 place_order" (click)="orderPlace()">
                                    <a>{{'place_order' | translate}}</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Items End -->


                <!-- Tab Items Start -->
                <div class="amount_to_Pay" [ngClass]="showPayment ? 'active' : ''">
                    <form class="px-4 py-3">
                        <h4 class="pt-3 mb-3">{{'amount_to_Pay' | translate}}
                            <strong>{{currency_icon}}{{eComService.getCartTotal(true)}}</strong>
                        </h4>
                        <!-- <div class="form-group mb-4 pb-2">
                            <label>{{'select_payment_method' | translate}}</label>
                            <div class="row no-gutters align-items-center">
                                <div *ngFor="let pm of paymentMethods" class="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div class="custom-control custom-radio" style="width: max-content;">
                                        <input type="radio" id="cash" [value]="pm.id" name="PaymentMethod"
                                            [(ngModel)]="paymentMethoIdSelected" class="custom-control-input">
                                        <label class="custom-control-label" for="cash"><span
                                                class="ml-2">{{pm.title}}</span></label>
                                    </div>
                                </div>
                            </div>
                        </div> -->


                        <div class="form-group mb-4 pb-2">
                            <label>{{'order_type' | translate}}</label>
                            <div class="row no-gutters align-items-center">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div class="custom-control custom-radio">
                                        <input checked type="radio" id="takeaway" name="OrderType" value="takeaway" [(ngModel)]="order_type" class="custom-control-input">
                                        <label class="custom-control-label" for="takeaway">
                                            <span class="ml-2">{{'takeaway' | translate}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="dine-in" name="OrderType" value="dinein" [(ngModel)]="order_type" class="custom-control-input">
                                        <label class="custom-control-label" for="dine-in">
                                            <span class="ml-2">{{'dinein' | translate}}</span>
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="order_type == 'dinein'" class="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div class="select_box w-100 d-flex  md-0">
                                        <select [(ngModel)]="table" name="table">
                                            <option value="" selected disabled>{{'select_table' | translate}}</option>
                                            <option *ngFor="let table of tables" [value]="table.table">
                                                {{'table' | translate}} {{table.table}}
                                            </option>
                                        </select>
                                        <i class="zmdi zmdi-caret-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{'customer_info' | translate}}</label>
                            <input type="text" class="form-control" name="name" [(ngModel)]="customer_name" placeholder="{{'name' | translate}}">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="number" [(ngModel)]="customer_number" placeholder="{{'phone_number' | translate}}">
                        </div>
                    </form>
                    <div class="order_footer bg-white">
                        <div class="btn_box">
                            <div class="row no-gutter mx-0">
                                <button type="button" class="btn col-6 Cancel" (click)="cancelOrder()">
                                    <a>{{'cancel' | translate}}</a>
                                </button>
                                <button type="button" class="btn col-6 place_order" (click)="confirmOrder()">
                                    <a>{{'submit' | translate}}</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Items end -->
                <!-- Order confirmation Start -->
                <div class="no-order order_confirmation p-4 p-sm-4 p-md-4 p-lg-5" [ngClass]="(order && order.show) ? 'active' : ''">
                    <div class="bannner">
                        <div class="banner_img">
                            <img src="assets/images/order_confirmed.png" class="img-fluid">
                        </div>
                        <h2 class="text-center" style="padding-bottom: 30px;"> {{'order_confirmed_with' | translate}}
                        </h2>
                        <h3><img src="assets/images/logo.svg" class="img-fluid"> </h3>
                    </div>
                    <div class="btn_box" style="padding-top: 25px;">
                        <div class="row no-gutter">
                            <div class="col-12 col-sm-12 col-md-12">
                                <button *ngIf="config.printerConfig.enabled && !config.printerConfig.enableAutoPrint" type="button" class="btn" (click)="printReceipt()">
                                    <a> {{'print_receipt' | translate}}</a>
                                </button>
                                <button type="button" class="btn_green" (click)="continue()">
                                    <a> {{'continue' | translate}}</a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Order confirmation End -->
            </div>
        </div>
        <!-- Order Section End -->


        <!-- Food Item Section Start -->
        <div class="item_section mt-4 mt-md-0">
            <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="onScrollDown()"></div>
            <div class="item_section_header">
                <div class="tab_btn_container">
                    <div *ngIf="profile && profile.product_categories && profile.product_categories.length" class="nav nav-tabs">
                        <owl-carousel class="owl-carousel owl-theme" [options]="carouselOptions">
                            <div class="item" *ngFor="let c of profile.product_categories;let i=index" (click)="slideLoadProduct(i)" [ngClass]="tabIndex == i ? 'active' : ''">
                                <div class="tab nav-item">
                                    <a class="nav-link">
                                        <img [src]="c.image">
                                        <h5>{{c.title}}</h5>
                                    </a>
                                </div>
                            </div>
                        </owl-carousel>
                    </div>
                </div>

                <form class="search_box animate__animated animate__zoomIn wow">
                    <div class="form-group d-flex">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="zmdi zmdi-search"></i></div>
                        </div>
                        <input type="search" class="form-control" placeholder="{{'search_items' | translate}}" name="search" [(ngModel)]="searchText" (keyup.enter)="search()">
                        <button type="button" class="btn" (click)="search()"><a>{{'search' | translate}}</a></button>
                    </div>
                </form>
            </div>
            <div class="tab-content">
                <div class="empty-view animate__animated animate__zoomIn wow" *ngIf="!isLoading && (!menuItemsToShow || !menuItemsToShow.menu_items || !menuItemsToShow.menu_items.length)">
                    <div style="text-align:center">
                        <img src="assets/images/plc_item_image.png" alt="no offers" />
                        <span style="color:#9E9E9E; font-weight:bold;">
                            {{'empty_products' | translate}}
                        </span>
                    </div>
                </div>
                <div *ngIf="menuItemsToShow && menuItemsToShow.menu_items && menuItemsToShow.menu_items.length" class="row no-gutters">
                    <div *ngFor="let item of menuItemsToShow.menu_items;let i = index" class="col col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <div class="item animate__animated animate__zoomIn wow">
                            <div (click)="proToShow = item; checkShowItem()" class="item_img center_img">
                                <img [src]="item.image_urls[0]" class="crop_img">
                            </div>
                            <div class="text_box">
                                <h2>{{item.title}}</h2>
                                <h3 class="d-flex">
                                    <img *ngIf="item.meta && item.meta.food_type == 'veg'" src="assets/images/ic_veg.png">
                                    <img *ngIf="item.meta && item.meta.food_type != 'veg'" src="assets/images/ic_nonveg.png">
                                    <span *ngIf="item.sale_priceToShow" class="d-block priceToShow">&nbsp;&nbsp;<s>{{item.priceToShow}}</s></span>
                                    <span class="d-block ml-auto">{{item.sale_priceToShow ? item.sale_priceToShow :
                                        item.priceToShow}}</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <button type="button" class="btn" (click)="ShowItemInfo()">
                    <a>Show Item info</a>
                </button> -->
            </div>
        </div>
        <!-- Food Item Section End -->
    </div>



    <div class="item-info" [ngClass]="showItem ? 'active' : ''">
        <div *ngIf="proToShow" class="item-inner">
            <div class="popup-close-button" (click)="showItem = false;">
                <i class="zmdi zmdi-close"></i>
            </div>

            <div class="item-about">
                <div class="img_box center_img">
                    <img [src]="proToShow.image_urls[0]" class="crop_img">
                </div>
                <div class="text_box d-flex">
                    <h2>{{proToShow.title}}</h2>

                    <h3 class="d-flex align-items-center mr-0 ml-auto">
                        {{proToShow.categoriesText}}
                        <span *ngIf="proToShow.sale_priceToShow" class="d-block priceToShow"><s>{{proToShow.priceToShow}}</s>&nbsp;&nbsp;&nbsp;</span>
                        <span class="d-block ml-auto">{{proToShow.sale_priceToShow ? proToShow.sale_priceToShow : proToShow.priceToShow}}</span>
                    </h3>
                </div>
            </div>
            <form>
                <div *ngFor="let group of proToShow.addon_groups">
                    <h4>{{group.title}}</h4>
                    <div *ngIf="group.min_choices == 1">
                        <div *ngFor="let cho of group.addon_choices" class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" [name]="group.title" [id]="'choice_id_'+cho.id" [value]="cho.id" [(ngModel)]="group.choiceIdSelected" [checked]="cho.isChecked" (click)="onChoiceClick(cho, group.addon_choices)">
                            <label class="custom-control-label w-100" [for]="'choice_id_'+cho.id">
                                <!-- <span class="align-items-center d-flex w-100">{{cho.title}}</span> -->
                                <span class="align-items-center d-flex w-100" style="top: 4px;">{{cho.title}}
                                    <small class="d-block ml-auto">{{cho.priceToShow}}</small>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="group.min_choices != 1">
                        <div *ngFor="let cho of group.addon_choices" class="custom-control custom-checkbox d-flex px-0">
                            <div class="form-check d-flex align-items-center w-100">
                                <input class="custom-control-input" type="checkbox" [name]="group.title" [value]="cho.id" [id]="'choice_id_'+cho.id" [checked]="cho.isChecked" (click)="onChoiceClick(cho)">
                                <label class="custom-control-label w-100" [for]="'choice_id_'+cho.id">
                                    <span class="align-items-center d-flex w-100">{{cho.title}}
                                        <small class="d-block ml-auto">{{cho.priceToShow}}</small>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <button type="button" class="btn" (click)="addProCart()">
                <a>{{'add_to_cart' | translate}}</a>
            </button>
        </div>
    </div>


    <!-- variation-selection-alert Start-->

    <div [ngClass]="addOnsLastUsed && addOnsLastUsed.length ? 'item-info variation-selection active' : 'item-info variation-selection'">
        <!-- <div *ngIf="proToShow" class="item-inner"> -->
        <div *ngIf="proToShow && addOnsLastUsed && addOnsLastUsed.length" class="item-inner">
            <div class="popup-close-button" (click)="addOnsLastUsed = null;">
                <i class="zmdi zmdi-close"></i>
            </div>

            <div class="item-about">
                <div class="img_box center_img">
                    <img [src]="proToShow.image_urls[0]" class="crop_img">
                </div>
                <div class="text_box w-100 d-flex">
                    <h2>{{proToShow.title}}</h2>
                    <h3 class="d-flex align-items-center mr-0 ml-auto">
                        {{proToShow.categoriesText}}
                        <span *ngIf="proToShow.sale_priceToShow" class="d-block priceToShow"><s>{{proToShow.priceToShow}}</s>&nbsp;&nbsp;&nbsp;</span>
                        <span class="d-block ml-auto">{{proToShow.sale_priceToShow ? proToShow.sale_priceToShow :
                            proToShow.priceToShow}}</span>
                    </h3>
                </div>
            </div>
            <form>
                <div>
                    <h4>{{'repeat_last_used_customisation' | translate}}</h4>
                    <div *ngFor="let addOn of addOnsLastUsed" class="custom-control custom-checkbox d-flex px-0">
                        <div class="form-check d-flex align-items-center w-100">
                            <label class="custom-control-label w-100">
                                <span class="align-items-center d-flex w-100">{{addOn.title}}
                                    <small class="d-block ml-auto">{{addOn.priceToShow}}</small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>

            <div class="btn_box d-flex">
                <div class="btn_box_inner ml-auto mr-0  animate__animated animate__fadeInUp">
                    <h6 (click)="actionNew()">{{'i_ll_choose' | translate}}</h6>
                    <h6 (click)="actionOld()" class="repeat_last">{{'repeat_last' | translate}}</h6>
                </div>
            </div>
        </div>
    </div>

    <!-- variation-selection-alert End-->
</div>

<div id="page_terms_conditions">

    <!-- Body Wrapper Start -->
    <div class="body_wrapper">
        <div class="row mx-0">
            <div class="col-12 col-sm-12 col-md-6 col-lg-5 px-0">
                <div class="page_title">
                    <div class="row align-items-center mx-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                            <div class="d-flex">
                                <h1>{{'terms_conditions' | translate}}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="banner col-10 col-md-10 col-lg-10 col-xl-7 mx-auto animate__animated animate__zoomIn wow" data-wow-duration=".5s">
                    <img src="assets/images/tnc.png" class="img-fluid">
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-7 px-0">
                <div class="card p-4 px-3 border-0 rounded-0">
                    <div class="col-12 col-sm-12 col-md-11 mx-auto px-0">
                        <p [innerHTML]="privacy_policy" class="animate__animated animate__fadeInUp"></p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
</div>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { APP_CONFIG, BaseAppConfig } from './app.config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { BookingComponent } from './booking/booking.component';
import { ItemsComponent } from './items/items.component';
import { OrdersStatusComponent } from './orders-status/orders-status.component';
import { PeopleComponent } from './people/people.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SettingComponent } from './setting/setting.component';
import { SupportComponent } from './support/support.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UploadItemComponent } from './upload-item/upload-item.component';
import { WalletComponent } from './wallet/wallet.component';
import { NgwWowModule } from 'ngx-wow';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { VerificationComponent } from './verification/verification.component';
import { ConfirmationDialogComponent } from './services/confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from './services/confirmation-dialog/confirmation-dialog.service';
import { GoogleMapsService } from './services/network/google-maps.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';  
import { OwlModule } from 'ngx-owl-carousel';
import { PrintReciptComponent } from './print-recipt/print-recipt.component';
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker'; 


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    AuthenticationComponent,
    BookingComponent,
    ItemsComponent,
    OrdersStatusComponent,
    PeopleComponent,
    ReviewsComponent,
    SettingComponent,
    SupportComponent,
    TermsConditionsComponent,
    TransactionsComponent,
    UploadItemComponent,
    WalletComponent,
    ChangeLanguageComponent,
    VerificationComponent,
    ConfirmationDialogComponent,
    PageNotFoundComponent,
    PrintReciptComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgwWowModule,
    QRCodeModule, 
    OwlModule,  
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    NgSelectModule,
    NgbModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    ConfirmationDialogService, GoogleMapsService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }

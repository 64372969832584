import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UiElementsService } from '../services/common/ui-elements.service';
import { ApiService } from '../services/network/api.service';
import { AddonChoices, AddonGroup, Product } from 'src/models/product.models';
import { Category } from 'src/models/category.models';
import { FirebaseUploaderService } from '../services/network/firebase-uploader.service';
import { Subscription } from 'rxjs';
import { ProductRequest } from 'src/models/product-request.models';
import { Helper } from 'src/models/helper.models';
import { MyEventsService } from '../services/events/my-events.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';

declare var $: any;
@Component({
  selector: 'app-upload-item',
  templateUrl: './upload-item.component.html',
  styleUrls: ['./upload-item.component.css']
})
export class UploadItemComponent implements OnInit {
  private subscriptions = new Array<Subscription>();
  product: Product;
  productRequest = new ProductRequest();
  pageTitle: string;
  currency_icon: string;
  productIngredient: string;

  categoriesSelectedStrings = new Array<string>();
  categoriesSelectedStringsFormatted: string;
  categoryStrings = new Array<string>();
  private categories: Array<Category>;
  productQuantityStatus = true;
  productTypeStatus = true;
  addOnsEnabled = false;

  addonForm: FormGroup;
  addonGroupStatus: boolean;
  servings_for = "1";
  cooking_time = "1";
  energy = "1";
  exmap: string;
  image: unknown;
  uploadType: number;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  isDuplicate = false;

  constructor(private router: Router, private translate: TranslateService, private confirmationDialogService: ConfirmationDialogService,
    private uiElementService: UiElementsService, private apiService: ApiService,
    private myEvent: MyEventsService, private fireUpService: FirebaseUploaderService,
    private formbuilder: FormBuilder, private route: ActivatedRoute,) {
      if (window.location.href.indexOf("duplicate_item") > -1) {
        this.isDuplicate = true;
      }
    //setTimeout(() => this.productRequest.image_urls = ["https://static.toiimg.com/thumb/72279200.cms?width=680&height=512&imgsize=1077894"], 2000);
    this.currency_icon = Helper.getSetting("currency_icon");
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
      this.product = this.router.getCurrentNavigation().extras.state.product;
      let vendorId = this.router.getCurrentNavigation().extras.state.vendorId;
      if (this.product != null) {
        this.addOnsEnabled = (this.product.addon_groups && this.product.addon_groups.length > 0);
        // if (this.product.addon_groups && this.product.addon_groups.length) {
        //   this.updateAddonGroup();
        // } else {
        //   this.addonGroup();
        // }
        this.productRequest = ProductRequest.fromProduct(this.product, vendorId);
        this.productQuantityStatus = this.productRequest.stock_quantity == -1;
        this.productTypeStatus = this.productRequest.meta && this.productRequest.meta.food_type == "veg";
        if (this.product && this.product.categories && this.product.categories.length) for (let cat of this.product.categories) { this.productRequest.categories.push(cat.id); this.categoriesSelectedStrings.push(cat.title); }
        this.setCategoriesText();
        if (this.productRequest.meta && this.productRequest.meta.ingredients) {
          this.productIngredient = "";
          for (let ingre of this.product.meta.ingredients) if (ingre && typeof ingre == "string" && ingre.trim().length) this.productIngredient += ingre.trim() + ", ";
          if (this.productIngredient.length) this.productIngredient = (this.productIngredient.substring(0, this.productIngredient.lastIndexOf(",")));
        }
      } else {
        //this.addonGroup();
      }
    } else {
      this.router.navigate(['items']);
    }

    let profile = Helper.getProfile();
    this.productRequest.vendor_id = profile.id;

    this.translate.get(this.product == null || this.isDuplicate ? "create_item" : "edit_item").subscribe(value => this.pageTitle = value);

    this.translate.get("just_moment").subscribe(value => {
      this.uiElementService.presentLoading(value);
      this.subscriptions.push(this.apiService.getCategoriesChild(Helper.getProfile().categories).subscribe(res => {
        for (let cNew of res) this.categoryStrings.push(cNew.title);
        this.categories = res;
        this.uiElementService.dismissLoading();
      }, err => {
        console.log("getCategoriesParents", err);
        this.uiElementService.dismissLoading();
        this.router.navigate(['items']);
      }));
    });
  }

  ngOnInit(): void {

  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  // updateAddonGroup() {
  //   this.addonGroup(true);
  //   this.product.addon_groups.map((group, index) => {
  //     const control = <FormArray>this.addonForm.controls['addon_groups']; control.push(this.addonGroupForm(group));
  //     group.addon_choices.map((choice) => {
  //       const controlChoice = <FormArray>this.addonForm.get('addon_groups')['controls'][index].get('choices'); controlChoice.push(this.addonChoicesForm(choice));
  //     })
  //   })
  // }
  // addonGroup(update?: boolean) {
  //   this.addonForm = this.formbuilder.group({ addon_groups: this.formbuilder.array(update ? [] : [this.addonGroupForm()]) });
  // }
  // addonGroupForm(group?: AddonGroup) {
  //   return this.formbuilder.group({
  //     title: [group ? group.title : '', [Validators.required]],
  //     min_choices: [group ? group.min_choices : '0', [Validators.required, Validators.pattern(/^(0|[1-9][0-9]*)$/)]],
  //     max_choices: [group ? group.max_choices : '99', [Validators.required, Validators.pattern(/^(0|[1-9][0-9]*)$/)]],
  //     choices: this.formbuilder.array((group ? [] : [this.addonChoicesForm()]))
  //   });
  // }
  // addonChoicesForm(choice?: AddonChoices) {
  //   return this.formbuilder.group({
  //     title: [choice ? choice.title : '', Validators.required],
  //     price: [choice ? choice.price : '', [Validators.required, Validators.pattern(/^\d+\.?\d*$/)]]
  //   })
  // }
  // addAddonGroup() {
  //   if (this.addonForm.controls.addon_groups['controls'].length < 5) {
  //     const control = <FormArray>this.addonForm.controls['addon_groups'];
  //     control.push(this.addonGroupForm());
  //   }
  // }
  // addAddonChoice(ix) {
  //   if (this.addonForm.controls.addon_groups['controls'][ix]['controls'].choices['controls'].length < 20) {
  //     const control = (<FormArray>this.addonForm.controls['addon_groups']).at(ix).get('choices') as FormArray;
  //     control.push(this.addonChoicesForm());
  //   }
  // }
  // deleteAddonGroupForm(index) {
  //   (<FormArray>this.addonForm.get('addon_groups')).removeAt(index);
  // }
  // deleteAddonChoiceForm(index, control) {
  //   control.removeAt(index)
  // }

  private setCategoriesText() {
    this.categoriesSelectedStringsFormatted = "";
    for (let ct of this.categoriesSelectedStrings) this.categoriesSelectedStringsFormatted += (ct + ", ");
    if (this.categoriesSelectedStringsFormatted.length) this.categoriesSelectedStringsFormatted = this.categoriesSelectedStringsFormatted.substring(0, this.categoriesSelectedStringsFormatted.length - 2);
  }

  onCategoriesChange() {
    this.setCategoriesText();
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  onImageChange(e) {
    if (e.target.files && e.target.files.length && e.target.accept == "image/*") {
      this.uploadType = 1;
      const [file] = e.target.files;
      this.uploadImageVideo(file);
    }
  }

  onVideoChange(e) {
    if (e.target.files && e.target.files.length && e.target.accept == "video/*") {
      this.uploadType = 2;
      const [file] = e.target.files;
      this.uploadImageVideo(file);
    }
  }

  uploadImageVideo(file) {
    this.translate.get(["uploading_image", "uploading_video", "uploading_fail_img", "uploading_fail_video"]).subscribe(values => {
      this.uiElementService.presentLoading(this.uploadType == 1 ? values["uploading_image"] : values["uploading_video"]);
      this.fireUpService.uploadFile(file).then(res => {
        if (this.uploadType == 1) this.productRequest.image_urls.push(String(res));
        if (this.uploadType == 2) this.productRequest.meta.video_url = String(res);
        this.uiElementService.dismissLoading();
      }, err => {
        console.log("resolveUriAndUpload", err);
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr(this.uploadType == 1 ? values["uploading_fail_img"] : values["uploading_fail_video"]);
      });
    });
  }

  confirmRemoveImage(index: number) {
    this.translate.get(["remove_img", "remove_img_desc", "no", "yes"]).subscribe(values => {
      this.confirmationDialogService.confirm(values['remove_img'], values['remove_img_desc'], values['yes'], values['no']).then((confirmed) => {
        if (confirmed) this.productRequest.image_urls.splice(index, 1);
      }).catch((err) => console.log('err: ', err));
    });
  }
  confirmRemoveVideo() {
    this.translate.get(["remove_video", "remove_video_desc", "no", "yes"]).subscribe(values => {
      this.confirmationDialogService.confirm(values['remove_video'], values['remove_video_desc'], values['yes'], values['no']).then((confirmed) => {
        if (confirmed) this.productRequest.meta.video_url = "";
      }).catch((err) => console.log('err: ', err));
    });
  }

  showAddonGroup() {
    this.addOnsEnabled = !this.addOnsEnabled;
  }

  saveProduct() {
    if (!this.productRequest.title || !this.productRequest.title.length) {
      this.translate.get("err_field_product_title").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.categoriesSelectedStrings || !this.categoriesSelectedStrings.length) {
      this.translate.get("err_field_product_categories").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.productRequest.detail || !this.productRequest.detail.length) {
      this.translate.get("err_field_product_detail").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.productRequest.meta.food_type || !this.productRequest.meta.food_type.length) {
      this.translate.get("err_field_product_food_type").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.productRequest.price || Number(this.productRequest.price) < 0) {
      this.translate.get("err_field_product_price").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else {
      // if (this.addOnsEnabled) {
      //   let min_max_choice_value: boolean = true;
      //   this.addonForm.controls.addon_groups['controls'].map((res) => {
      //     if (!res.controls.title.valid) {
      //       this.translate.get("err_field_product_addon_group_title").subscribe(value => this.uiElementService.showErrorToastr(value));
      //     } else if (!res.controls.min_choices.valid) {
      //       this.translate.get("err_field_minimum_choices").subscribe(value => this.uiElementService.showErrorToastr(value));
      //     } else if (!res.controls.max_choices.valid) {
      //       this.translate.get("err_field_maximum_choices").subscribe(value => this.uiElementService.showErrorToastr(value));
      //     } else if (Number(res.value.max_choices) < Number(res.value.min_choices)) {
      //       min_max_choice_value = false;
      //       this.translate.get("err_field_maximum_choices_cannot").subscribe(value => this.uiElementService.showErrorToastr(value));
      //     } else {
      //       res.controls.choices['controls'].map((element) => {
      //         if (!element.controls.title.valid) {
      //           this.translate.get("err_field_choice_name").subscribe(value => this.uiElementService.showErrorToastr(value));
      //         } else if (!element.controls.price.valid) {
      //           this.translate.get("err_field_choice_price").subscribe(value => this.uiElementService.showErrorToastr(value));
      //         }
      //       });
      //     }
      //   });
      //   this.addonGroupStatus = this.addonForm.controls.addon_groups.status == "VALID" && min_max_choice_value;
      // } else {
        this.addonGroupStatus = true;
      //}
      if (this.addonGroupStatus) {
        this.productRequest.categories = [];
        for (let cat of this.categories) if (this.categoriesSelectedStrings.includes(cat.title)) this.productRequest.categories.push(cat.id);

        // let sendAddonGroupReq = [];
        // this.addonForm.value.addon_groups.map(group => {
        //   let choice = []; group.choices.map(res => choice.push({ title_translations: [{ language: Helper.getLanguageDefault(), text: res.title }], price: Number(res.price) }));
        //   sendAddonGroupReq.push({ title_translations: [{ language: Helper.getLanguageDefault(), text: group.title }], min_choices: Number(group.min_choices), max_choices: Number(group.max_choices), choices: choice });
        // });
        // console.log(sendAddonGroupReq);
        if (this.productIngredient) {
          let ingredients = this.productIngredient.split(",");
          this.productRequest.meta.ingredients = [];
          for (let ingre of ingredients) this.productRequest.meta.ingredients.push(ingre.trim());
        }
        this.productRequest.stock_quantity = this.productQuantityStatus ? -1 : 0;
        this.productRequest.meta.food_type = this.productTypeStatus ? "veg" : "non_veg";

        let pur = {
          title: this.productRequest.title,
          detail: this.productRequest.detail,
          price: this.productRequest.price,
          sale_price: this.productRequest.sale_price <= 0 ? null : this.productRequest.sale_price,
          vendor_id: this.productRequest.vendor_id,
          categories: this.productRequest.categories,
          image_urls: this.productRequest.image_urls,
          stock_quantity: this.productRequest.stock_quantity,
          addon_groups:  [],
          meta: JSON.stringify(this.productRequest.meta),
          sort_order:this.productRequest.sort_order,
          images:[],
          title_translations:'',
          detail_translations:''
        }
        this.translate.get(["saving"]).subscribe(values => {
          this.uiElementService.presentLoading(values["saving"]);
          this.subscriptions.push((this.product != null && this.product.id != null && !this.isDuplicate) ? this.apiService.updateProduct(this.product.id, pur).subscribe(res => this.handleRes(res), err => this.handleErr(err)) : this.apiService.createProduct(pur).subscribe(res => this.handleRes(res), err => this.handleErr(err)));
        });
      }
    }
  }

  handleRes(res) {
    // window.localStorage.setItem("refreshlist", "true");
    // this.myEvent.setUpdatePoductData('true');
    this.router.navigate(['items']);
    this.uiElementService.dismissLoading();
  }

  handleErr(err) {
    console.log("handleErr", err);
    this.translate.get("something_wrong").subscribe(value => this.uiElementService.showErrorToastr(value));
    this.uiElementService.dismissLoading();
    this.router.navigate(['items']);
  }


}
